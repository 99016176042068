import React  from 'react';
import Popup from 'reactjs-popup';
import './antd.css';
import 'reactjs-popup/dist/index.css';
import './PropertyPickerPopup.css';
import PartLang from './PartLang.json';
import { Tree } from 'antd';

class PropertyPickerPopup extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			partID: 0,
			partName: "",
			propertyListJsonObj: null,
		};

		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.serverGetPropertyList = this.serverGetPropertyList.bind(this);
		this.popUpClosed = this.popUpClosed.bind(this);
		this.onSelectProperty = this.onSelectProperty.bind(this);
		this.renderOneProperty = this.renderOneProperty.bind(this);
		this.renderPropertyList = this.renderPropertyList.bind(this);
		this.renderPopup = this.renderPopup.bind(this);
	}
	
	componentDidMount() {
		var partID = this.props.propertyPickerPartID;
		if (partID > 0) {
			this.setState({partID: partID});
			this.serverGetPartData(partID);
			this.serverGetPropertyList(partID);
		}
	}

	componentDidUpdate() {

		var partID1 = this.state.partID;
		var partID2 = this.props.propertyPickerPartID;
		if (partID1 === partID2) {
			return;
		}

		this.setState({partID: partID2});
		this.serverGetPartData(partID2);
		this.serverGetPropertyList(partID2);
	}
	
	async serverGetPartData(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		var partName = jsonObj.partName;
		
		this.setState({
			partName: partName,
		});
	}
		
	async serverGetPropertyList(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertylist?partid=" + partID;

		const res = await fetch(url);
		const propertyListJsonObj = await res.json();

		this.setState({
			propertyListJsonObj: propertyListJsonObj,
		});
	}
	
	popUpClosed() {
		this.props.setShowPropertyPickerPopupYN(false);
	}
	
	onSelectProperty(propertyID, propertyName) {
		this.props.setPropertyIDAndName(propertyID, propertyName);
		this.props.setShowPropertyPickerPopupYN(false);
	}

	renderOneProperty(itemObj) {
		
		var propertyID = itemObj.partPropertyID;
		var propertyName = itemObj.propertyName;
		var key = propertyID;
		
		return (
			<div 
				className="PropertyPickerPopupPropertyName"
				key={key} 
				onClick={() => this.onSelectProperty(propertyID, propertyName)}
			>
				{propertyName}
			</div>
		);		
	}
	
	renderPropertyList() {

		var propertyListJsonObj = this.state.propertyListJsonObj;
		if (propertyListJsonObj === null) {
			return;
		}

		var i;
		var jsxCode;
		var jsxArray = [];
		var itemObj;

		itemObj = {};
		itemObj.partPropertyID = 0;
		itemObj.propertyName = "Count";
		jsxCode = this.renderOneProperty(itemObj);
		jsxArray.push(jsxCode);

		for(i=0; i<propertyListJsonObj.length; i++) {
			itemObj = propertyListJsonObj[i];
			jsxCode = this.renderOneProperty(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	renderPopup() {
		
		return (
			<Popup
				className="property-picker-popup"
				open={true}
				closeOnDocumentClick={true}
				onClose={this.popUpClosed}
			>
				<div className="PropertyPickerPopupTopContainer">
					<div className="PropertyPickerPopupHeaderContainer">
						<div className="PropertyPickerPopupHeaderText">Select property</div>
					</div>
					<div style={{height: "20px"}}></div>
					<div>{this.renderPropertyList()}</div>
					
					<div style={{height: "50px"}}></div>
				</div>
			</Popup>
		);
	}

	render() {
		
		var renderPopupYN = this.props.renderPopupYN;
		
		if (!renderPopupYN) {
			return null;
		}
		
		return (
			<div className={this.props.className}>
				{this.renderPopup()}
			</div>
		);
	}
}

export default PropertyPickerPopup;

