import React  from 'react';
import './App.css';
import './Task.css';
import { useNavigate } from "react-router-dom";
import TaskLang from './TaskLang.json';

class TaskMainToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(tab) {
		this.props.setSelectedTab(tab);
	}
	
	renderLink(linkTitle, linkValue, renderYN) {
		
		if (!renderYN) {
			return null;
		}

		var selectedTab = this.props.selectedTab;
		var classNameString = "TaskMainToolbarLink";
		
		if (selectedTab === linkValue) {
			classNameString = "TaskMainToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);		
	}
	
	render() {
		
		var taskName = this.props.taskName;
		var productionAppUserType = this.props.productionAppUserType;
		var executorUserID = this.props.executorUserID;
		var createdUserID = this.props.createdUserID;
		var userID = this.props.userID;		
		var showEditYN = false;
		
		if (productionAppUserType >= 100) {
			showEditYN = true;
		}
		
		if (userID === executorUserID) {
			showEditYN = true;
		}
		
		if (userID === createdUserID) {
			showEditYN = true;
		}
		
		var infoString = this.props.getLang(TaskLang, "info");
		var editString = this.props.getLang(TaskLang, "edit");

		return (
			<div className="TaskMainToolbarContainer1">
				<div className="TaskMainToolbarLeft">{this.props.getLang(TaskLang, "task")}: {taskName}</div>
				<div className="TaskMainToolbarRight">
					<div className="TaskMainToolbarContainer2">
						{this.renderLink(infoString, "info", true)}
						{showEditYN && (<div className="TaskMainToolbarText">|</div>)}
						{this.renderLink(editString, "edit", showEditYN)}
					</div>
				</div>
			</div>
		);
	}
}

/*
						{this.renderLink("New comment", "newcomment")} <div className="TaskMainToolbarText">|</div> 
*/

export default function TaskMainToolbarFunction(props) {
	const navigate = useNavigate();
	return <TaskMainToolbar {...props} navigate={navigate} />;
}

