import React  from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './TaskInfoEnterNotes.css';
import TaskLang from './TaskLang.json';

class TaskInfoEnterNotes extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			notes: "",
		};

		this.serverUpdateTaskNotes = this.serverUpdateTaskNotes.bind(this);
		this.popUpClosed = this.popUpClosed.bind(this);
		this.onNotesChanged = this.onNotesChanged.bind(this);
		this.onSaveButton = this.onSaveButton.bind(this);
		this.renderPopup = this.renderPopup.bind(this);
	}
	
	componentDidMount() {
		var notes = this.props.notes;
		this.setState({notes: notes});
	}

	async serverUpdateTaskNotes(taskID, notes) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatetasknotes?taskid=" + taskID + "&notes=" + encodeURIComponent(notes);
		
		const res = await fetch(url);
		await res.text();

		this.props.serverGetTaskData(taskID);
	}

	popUpClosed() {
		this.props.setShowTaskInfoEnterNotesYN(false);
	}
	
	onNotesChanged(value) {
		this.setState({notes: value});
	}
	
	onSaveButton() {

		var taskID = this.props.taskID;
		var notes = this.state.notes;
		
		this.serverUpdateTaskNotes(taskID, notes);
		this.props.setShowTaskInfoEnterNotesYN(false);
	}
	
	renderPopup() {
		
		var taskName = this.props.taskName;
		var notes = this.props.notes;

		return (
			<Popup
				className="enter-notes-popup"
				open={true}
				closeOnDocumentClick={true}
				onClose={this.popUpClosed}
			>
				<div className="TaskInfoEnterNotesTopContainer">
					<div className="TaskInfoEnterNotesHeaderContainer">
						<div className="TaskInfoEnterNotesHeaderText">{taskName}</div>
					</div>
					<div style={{height: "20px"}}></div>
					<div className="TaskInfoEnterNotesContainer">
						<div className="TaskInfoEnterNotesText">{this.props.getLang(TaskLang, "notes")}:</div>
						<textarea
							className="TaskInfoEnterNotesInput" 
							id="notes" 
							defaultValue={notes}
							rows={15}
							autoFocus={true}
							onChange={(event) => this.onNotesChanged(event.target.value)}
						/>
					</div>
					
					<div style={{height: "30px"}}></div>
					<div className="TaskInfoEnterNotesContainerButtons">
						<button className="ViewSaveButton" onClick={this.onSaveButton}>{this.props.getLang(TaskLang, "save")}</button>
					</div>
					<div style={{height: "30px"}}></div>
				</div>
			</Popup>
		);
	}

	render() {
		
		var renderPopupYN = this.props.renderPopupYN;
		
		if (!renderPopupYN) {
			return null;
		}
		
		return (
			<div className={this.props.className}>
				{this.renderPopup()}
			</div>
		);
	}
}

export default TaskInfoEnterNotes;

