import React  from 'react';
import './antd.css';
import './App.css';
import './Part.css';
import PartLang from './PartLang.json';
import { Tree } from 'antd';

class PartLeft extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			searchText: "",
			partListJsonObj: null,
		}


		this.serverSearchParts = this.serverSearchParts.bind(this);
		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.onSelectPart = this.onSelectPart.bind(this);
		this.onSearchChanged = this.onSearchChanged.bind(this);
		this.onItemPressed = this.onItemPressed.bind(this);
		this.renderItem = this.renderItem.bind(this);
		this.renderSearchResults = this.renderSearchResults.bind(this);		
	}
	
	async serverSearchParts(projectID, searchString) {
		
		if (searchString === "") {
			this.setState({
				partListJsonObj: null,
			});
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/searchparts?projectid=" + projectID + "&search=" + encodeURI(searchString);
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			partListJsonObj: jsonObj,
		});
	}
	
	async serverGetPartData(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		var ownerUserID = jsonObj.ownerUserID;

		this.props.setOwnerUserID(ownerUserID);
	}

	onSelectPart(partIDArray, info) {
		var length = partIDArray.length;
		if (length !== 1) {
			return;
		}
		var partID = partIDArray[0];
		var partName = info.node.title;
		this.props.setSelectedPartIDAndName(partID, partName);
		this.props.setPartRightPage("properties");
		this.serverGetPartData(partID);
	}

	onSearchChanged(value) {
		this.setState({searchText: value});
		var projectID = this.props.selectedProjectID;
		this.serverSearchParts(projectID, value);
	}
	
	onItemPressed(partID, partName) {
		this.props.setSelectedPartIDAndName(partID, partName);
		this.setState({searchText: ""});
	}

	renderItem(itemObj) {

		var partID = itemObj.partID;
		var partName = itemObj.partName;
		
		return (
			<div
				key={partID}
				className="PartLeftSearchItem"
				onClick={() => this.onItemPressed(partID, partName)}
			>
				{partName}
			</div>
		);
	}

	renderSearchResults(searchText) {

		if (searchText === "") {
			return null;
		}

		var partListJsonObj = this.state.partListJsonObj;
		if (partListJsonObj === null) {
			return null;
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<partListJsonObj.length; i++) {
			itemObj = partListJsonObj[i];
			jsxCode = this.renderItem(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (<div>{jsxArray}</div>);
	}

	render() {

		var partTreeData = this.props.partTreeData;
		var selectedPartID = this.props.selectedPartID;
		var defaultSelectedKeys = [];
		
		if (selectedPartID !== 0) {
			defaultSelectedKeys.push(selectedPartID);
		}

		var searchText = this.state.searchText;
		var displayTreeYN = true;
		if (searchText !== "") {
			displayTreeYN = false;
		}
		
		var searchPlaceholder = this.props.getLang(PartLang, "search") + "...";
		
		return (
			<div className="PartLeftContainer">
				<div style={{height: "10px"}}></div>
				{displayTreeYN && (<Tree
					defaultExpandedKeys={defaultSelectedKeys}
					defaultSelectedKeys={defaultSelectedKeys}
					onSelect={this.onSelectPart}
					treeData={partTreeData}
				/>)}
				<input
					className="PartLeftSearchInput" 
					id="search" 
					placeholder={searchPlaceholder}
					value={searchText}
					onChange={(event) => this.onSearchChanged(event.target.value)}
				/>
				{this.renderSearchResults(searchText)}
			</div>
		);
	}
}

export default PartLeft;

