import React  from 'react';
import './App.css';
import './Part.css';
import PartLang from './PartLang.json';

class PartRightSelectOwner extends React.Component {

	constructor() {

		super();

		this.state = {
			partID: 0,
			partName: "",
			ownerUserID: 0,
			ownerFullName: "",
			jsonObj: null,
			dataLoadedYN: false,
		};

		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.serverGetUserList = this.serverGetUserList.bind(this);
		this.serverUpdatePartOwnerUserID = this.serverUpdatePartOwnerUserID.bind(this);
		this.serverInsertLogRecord = this.serverInsertLogRecord.bind(this);
		this.handleClickSelectOwner = this.handleClickSelectOwner.bind(this);		
		this.renderOneUser = this.renderOneUser.bind(this);
		this.renderAllUsers = this.renderAllUsers.bind(this);
	}
	
	componentDidMount() {
		var selectedPartID = this.props.selectedPartID;
		this.serverGetPartData(selectedPartID);
		this.serverGetUserList();
	}
	
	async serverGetPartData(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var partName = jsonObj.partName;
		var ownerUserID = jsonObj.ownerUserID;

		this.setState({
			partName: partName,
			ownerUserID: ownerUserID,
		});
	}
		
	async serverGetUserList() {

		var companyID = this.props.companyID;
		var index = 0;
		var count = 1000;
		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserlist?companyid=" + companyID;
		url = url + "&sortby=email&sortasc=1";
		url = url + "&index=" + index + "&count=" + count;

		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			jsonObj: jsonObj,
			dataLoadedYN: true,
		});
	}

	async serverUpdatePartOwnerUserID(partID, ownerUserID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartowneruserid?partid=" + partID + "&owneruserid=" + ownerUserID;

		const res = await fetch(url);
		const text = await res.text();
	}
		
	async serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, projectID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertlogrecord?userid=" + userID;
		url = url + "&email=" + encodeURIComponent(email);
		url = url + "&fullname=" + encodeURIComponent(fullName);
		url = url + "&partid=" + partID;
		url = url + "&partname=" + encodeURIComponent(partName);
		url = url + "&propertyid=" + propertyID;
		url = url + "&propertyname=" + encodeURIComponent(propertyName);
		url = url + "&valueold=" + encodeURIComponent(valueOld);
		url = url + "&valuenew=" + encodeURIComponent(valueNew);
		url = url + "&projectid=" + projectID;

		const res = await fetch(url);
		await res.text();
	}
		
	handleClickSelectOwner(ownerUserID, ownerFullName) {

		var selectedPartID = this.props.selectedPartID;
		this.serverUpdatePartOwnerUserID(selectedPartID, ownerUserID);

		var userID = this.props.userID;
		var email = this.props.email;
		var firstName = this.props.firstName;
		var lastName = this.props.lastName;
		var partID = this.props.selectedPartID;
		var partName = this.props.selectedPartName;
		var propertyID = 0;
		var propertyName = "Owner";
		var valueOld = "";
		var valueNew = ownerFullName;
		var projectID = this.props.selectedProjectID;

		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		this.serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, projectID);
		
		this.props.setPartRightPage("properties");
		this.props.showToast("Part owner selected");
	}
		
	renderOneUser(userID, email, firstName, lastName) {

		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();

		return (
			<div key={userID}>
				<div className="ListRow">
					<div className="PartRightSelectOwnerListEmailLink" onClick={() => this.handleClickSelectOwner(userID, fullName)}>{email}</div>
					<div className="PartRightSelectOwnerListNameLink" onClick={() => this.handleClickSelectOwner(userID, fullName)}>{fullName}</div>
				</div>
			</div>
		);
	}

	renderAllUsers() {

		var jsonObj = this.state.jsonObj;

		if (jsonObj === null) {
			return null;
		}

		return (
			<div className="ListContainer2">
				{jsonObj.map((user) => (
					<div key={user.userID}>{this.renderOneUser(user.userID, user.email, user.firstName, user.lastName)}</div>
				))}
				<p>&nbsp;</p>
			</div>
		);
	}

	render() {

		var selectedPartID = this.props.selectedPartID;
		var partName = this.state.partName;

		if (!this.state.dataLoadedYN) {
			return null;
		}

		if (selectedPartID <= 0) {
			return null;
		}
		
		return (
			<div>
				<div className="PartRightName">{partName} - {this.props.getLang(PartLang, "selectPartOwner")}</div>
				<div style={{height: "10px"}}></div>
				<div className="PartRightSelectOwnerContainer1">
					{this.renderAllUsers()}
					<div style={{height: "50px"}}></div>
				</div>
			</div>
		);
	}
}

export default PartRightSelectOwner;

