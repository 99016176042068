import React  from 'react';
import './App.css';
import Tree from 'react-d3-tree';
import FancyTreePopup from "./FancyTreePopup.js"

class FancyTree extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			showPopupYN: false,
			selectedPartID: 0,
		}

		this.closePopup = this.closePopup.bind(this);
		this.onNodeMouseOver = this.onNodeMouseOver.bind(this);
	}

	onNodeMouseOver(nodeId, event) {
		var selectedPartID = nodeId.data.partID;
		this.setState({
			showPopupYN: true,
			selectedPartID: selectedPartID,
		});
    }

	closePopup() {
		this.setState({
			showPopupYN: false,
		});
	}
	
	render() {
		
		var partTreeDataFancy = this.props.partTreeDataFancy;
		return (
			<div id="treeWrapper" style={{ width: '100vw', height: '100vh' }}>
				{this.state.showPopupYN && (
					<FancyTreePopup
						baseURLAPI={this.props.baseURLAPI}
						selectedPartID={this.state.selectedPartID}
						setSelectedPartIDAndName={this.props.setSelectedPartIDAndName}	
						closePopup={this.closePopup}
					/>
				)}
				<Tree 
					data={partTreeDataFancy}
					onNodeMouseOver={this.onNodeMouseOver}
				/>
			</div>
		);
	}
}

export default FancyTree;

/*
			<div id="treeWrapper" style={{ width: '1000px', height: '1000px' }}>
*/
