import React  from 'react';
import './App.css';
import './Part.css';
import PartLang from './PartLang.json';

import { useNavigate } from "react-router-dom";

class PartNew extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			partName: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onPartNameChanged = this.onPartNameChanged.bind(this);
		this.serverInsertPart = this.serverInsertPart.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onPartNameChanged(value) {
		this.setState({partName: value});
	}
	
	async serverInsertPart(partName) {

		var baseURLAPI = this.props.baseURLAPI;
		var selectedPartID = this.props.selectedPartID;
		var selectedProjectID = this.props.selectedProjectID;
		var userID = this.props.userID;
		var url = baseURLAPI + "/insertpartrecord?parentid=" + selectedPartID;
		url = url + "&partname=" + encodeURIComponent(partName);
		url = url + "&projectid=" + selectedProjectID;
		url = url + "&owneruserid=" + userID;
		
		const res = await fetch(url);
		await res.text();
	}
		
	onClickSaveButton() {
		
		var partName = this.state.partName;
		if (partName === "") {
			this.props.showToast("Part name cannot be empty");
			return;
		}

		var selectedProjectID = this.props.selectedProjectID;
		this.serverInsertPart(partName);
		this.props.serverGetPartsTreeData(selectedProjectID);
		this.props.showToast(this.props.getLang(PartLang, "newPartCreated"));
		const navigate = this.props.navigate;
  		navigate("/parts");
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/parts");
	}
	
	render() {		

		var selectedPartName = this.props.selectedPartName;

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(PartLang, "newPart")}</div>
				<div className="ViewTopText">{this.props.getLang(PartLang, "newPartInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(PartLang, "partInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<p></p>
						<fieldset className="PartNewFieldset">
							<legend className="PartNewParentLegend">{this.props.getLang(PartLang, "parentPart")}</legend> 
							<input
								className="PartNewParentInput" 
								id="partName" 
								type="text" 
								value={selectedPartName}
								disabled={true}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>
						<fieldset className="PartNewFieldset">
							<legend className="PartNewNameLegend">{this.props.getLang(PartLang, "partName")}</legend> 
							<input
								className="PartNewNameInput" 
								id="partName" 
								type="text" 
								autoFocus={true}
								onChange={(event) => this.onPartNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(PartLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(PartLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function PartNewFunction(props) {
	const navigate = useNavigate();
	return <PartNew {...props} navigate={navigate} />;
}

