import React  from 'react';
import './App.css';
import './PropertyTrigger.css';
import PartLang from './PartLang.json';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { BsTrash } from "react-icons/bs"

import PropertyTriggerToolbar from './PropertyTriggerToolbar.js';
import PartPickerPopup from './PartPickerPopup.js';
import PropertyPickerPopup from './PropertyPickerPopup.js';
  
import { useNavigate } from "react-router-dom";

class PropertyTrigger extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			partName: "",
			propertyName: "",
			triggerJSONObj: null,
			selectedItemObj: null,
			showPartPickerPopupYN: false,
			partPickerPartID: 0,
			showPropertyPickerPopupYN: false,
			propertyPickerPartID: 0,
		};

		this.convertBufferToString = this.convertBufferToString.bind(this);
		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.serverGetPartPropertyData = this.serverGetPartPropertyData.bind(this);
		this.serverUpdatePartTriggerJSONCount = this.serverUpdatePartTriggerJSONCount.bind(this);
		this.serverUpdatePartPropertyTriggerJSON = this.serverUpdatePartPropertyTriggerJSON.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.handleSelectBlock = this.handleSelectBlock.bind(this);
		this.createNewBlock = this.createNewBlock.bind(this);
		this.deleteBlock = this.deleteBlock.bind(this);
		this.handleDeleteBlock = this.handleDeleteBlock.bind(this);
		this.handleSelectPart = this.handleSelectPart.bind(this);
		this.handleSelectProperty = this.handleSelectProperty.bind(this);
		this.setShowPartPickerPopupYN = this.setShowPartPickerPopupYN.bind(this);
		this.setPartIDAndName = this.setPartIDAndName.bind(this);
		this.setShowPropertyPickerPopupYN = this.setShowPropertyPickerPopupYN.bind(this);
		this.setPropertyIDAndName = this.setPropertyIDAndName.bind(this);
		this.onMultiplierChanged = this.onMultiplierChanged.bind(this);		
		this.renderOneLeftBlock = this.renderOneLeftBlock.bind(this);
		this.renderLeft = this.renderLeft.bind(this);
		this.renderRight = this.renderRight.bind(this);
	}
	
	componentDidMount() {
		var partID = this.props.selectedPartID;
		var partPropertyID = this.props.selectedPartPropertyID;
		this.serverGetPartData(partID, partPropertyID);
		if (partPropertyID !== 0) {
			this.serverGetPartPropertyData(partPropertyID);
		}
		
		// fake triggerJSONObj
		/*{
			var triggerJSONObj = [];
			var itemObj;

			itemObj = {};
			itemObj.partID = 76;
			itemObj.partName = "Torba kolumba i ostalo";
			itemObj.propertyID = 0;
			itemObj.propertyName = "Count";
			itemObj.multiplier = 1;
			triggerJSONObj.push(itemObj);

			itemObj = {};
			itemObj.partID = 0;
			itemObj.partName = "Kutija za punjac";
			itemObj.propertyID = 0;
			itemObj.propertyName = "Count";
			itemObj.multiplier = 2;
			triggerJSONObj.push(itemObj);

			this.setState({
				triggerJSONObj: triggerJSONObj,
			});
		}*/
	}
	
	convertBufferToString(buffer) {
		
		var i;
		var ch;
		var chCode;
		var length = buffer.length;
		var outputString = "";
		
		for(i=0; i<length; i++) {
			chCode = buffer[i];
			if (chCode === 10) {
				ch = "\\n";
			} else {
				ch = String.fromCharCode(chCode);
			}
			outputString = outputString + ch;
		}

		return outputString;
	}
	
	async serverGetPartData(partID, propertyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var partName = jsonObj.partName;

		this.setState({
			partName: partName,
		});

		if (propertyID !== 0) {
			return;
		}

		var triggerJSONCount = jsonObj.triggerJSONCount;		
		var triggerJSONObj = null;
		var jsonText = "";
		if (triggerJSONCount === null) {
			triggerJSONObj = [];
		} else {
			jsonText = this.convertBufferToString(triggerJSONCount.data);
			triggerJSONObj = JSON.parse(jsonText);
		}
		
		if (triggerJSONObj.length > 0) {
			var itemObj = triggerJSONObj[0];
			this.setState({selectedItemObj: itemObj});
		}

		var countText = this.props.getLang(PartLang, "count");

		this.setState({
			propertyName: countText,
			triggerJSONObj: triggerJSONObj,
		});
	}
		
	async serverGetPartPropertyData(partPropertyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertydata?partpropertyid=" + partPropertyID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		
		var propertyName = jsonObj.propertyName;
		var triggerJSON = jsonObj.triggerJSON;

		var triggerJSONObj = null;
		var jsonText = "";
		if (triggerJSON === null) {
			triggerJSONObj = [];
		} else {
			jsonText = this.convertBufferToString(triggerJSON.data);
			triggerJSONObj = JSON.parse(jsonText);
		}
		
		if (triggerJSONObj.length > 0) {
			var itemObj = triggerJSONObj[0];
			this.setState({selectedItemObj: itemObj});
		}
		
		this.setState({
			propertyName: propertyName,
			triggerJSONObj: triggerJSONObj,
		});
	}
		
	async serverUpdatePartTriggerJSONCount(partID, triggerJSONCount) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updateparttriggerjsoncount?partid=" + partID;
		
		var formData = new FormData();
		formData.append("triggerjsoncount", triggerJSONCount);

		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});		

		await res.text();

		const navigate = this.props.navigate;
  		navigate("/setup/properties");
	}
		
	async serverUpdatePartPropertyTriggerJSON(partPropertyID, triggerJSON) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartpropertytriggerjson?partpropertyid=" + partPropertyID;
		
		var formData = new FormData();
		formData.append("triggerjson", triggerJSON);

		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});		

		await res.text();

		const navigate = this.props.navigate;
  		navigate("/setup/properties");
	}
		
	onClickSaveButton() {

		var partID = this.props.selectedPartID;
		var partPropertyID = this.props.selectedPartPropertyID;
		var triggerJSONText;

		var triggerJSONObj = this.state.triggerJSONObj;
		if (triggerJSONObj === null) {
			triggerJSONText = null;
		} else {
			triggerJSONText = JSON.stringify(triggerJSONObj);
		}

		if (partPropertyID === 0) {
			this.serverUpdatePartTriggerJSONCount(partID, triggerJSONText);
		} else {
			this.serverUpdatePartPropertyTriggerJSON(partPropertyID, triggerJSONText);
		}

		var partName = this.state.partName;
		var propertyName = this.state.propertyName;
		if (propertyName === "Count") {
			propertyName = this.props.getLang(PartLang, "count");
		}

		var toastMessage = this.props.getLang(PartLang, "propertyTriggerSaved") + ": \"" + partName + " / " + propertyName + "\"";
		this.props.showToast(toastMessage);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/setup/properties");
	}
	
	handleSelectBlock(itemObj) {
		this.setState({selectedItemObj: itemObj});
	}
	
	createNewBlock() {

		var triggerJSONObj = this.state.triggerJSONObj;

		var partID = this.props.selectedPartID;
		var partName = this.state.partName;
		var itemObj = {};
		itemObj.partID = partID;
		itemObj.partName = partName;
		itemObj.propertyID = 0;
		itemObj.propertyName = "Count";
		itemObj.multiplier = 1;
		triggerJSONObj.push(itemObj);
		
		this.setState({
			triggerJSONObj: triggerJSONObj,
			selectedItemObj: itemObj,
		});
	}
	
	deleteBlock() {

		var selectedItemObj = this.state.selectedItemObj;
		var triggerJSONObj = this.state.triggerJSONObj;
		var triggerJSONObj2 = [];

		var i;
		var itemObj;
		for(i=0; i<triggerJSONObj.length; i++) {
			itemObj = triggerJSONObj[i];
			if (itemObj !== selectedItemObj) {
				triggerJSONObj2.push(itemObj);
			}
		}
		
		this.setState({
			triggerJSONObj: triggerJSONObj2,
			selectedItemObj: null,
		});
	}
	
	handleDeleteBlock() {
		
		var selectedItemObj = this.state.selectedItemObj;
		if (selectedItemObj === null) {
			return;
		}
		
		var partName = selectedItemObj.partName;
		var propertyName = selectedItemObj.propertyName;

		var blockTitle = partName + "/" + propertyName;

		confirmAlert({
			title: this.props.getLang(PartLang, "deleteTriggerBlock"),
			message: this.props.getLang(PartLang, "deleteTriggerBlockAreYouSure") + " \"" + blockTitle + "\" ?",
			buttons: [
				{
					label: this.props.getLang(PartLang, "yes"),
					onClick: () => this.deleteBlock()
				},
				{
					label: this.props.getLang(PartLang, "no"),
				}
			]
		});
	}
	
	handleSelectPart(partID) {
		this.setState({
			partPickerPartID: partID,
			showPartPickerPopupYN: true,
		});
	}
	
	handleSelectProperty(partID) {
		this.setState({
			propertyPickerPartID: partID,
			showPropertyPickerPopupYN: true,
		});
	}
	
	setShowPartPickerPopupYN(showPartPickerPopupYN) {
		this.setState({showPartPickerPopupYN: showPartPickerPopupYN});
	}
	
	setShowPropertyPickerPopupYN(showPropertyPickerPopupYN) {
		this.setState({showPropertyPickerPopupYN: showPropertyPickerPopupYN});
	}
	
	setPartIDAndName(partID, partName) {

		var selectedItemObj = this.state.selectedItemObj;
		var triggerJSONObj = this.state.triggerJSONObj;
		var triggerJSONObj2 = [];

		var i;
		var itemObj;
		for(i=0; i<triggerJSONObj.length; i++) {
			itemObj = triggerJSONObj[i];
			if (itemObj === selectedItemObj) {
				itemObj.partID = partID;
				itemObj.partName = partName;
				itemObj.propertyID = 0;
				itemObj.propertyName = "Count";
			}
			triggerJSONObj2.push(itemObj);
		}
		
		this.setState({
			triggerJSONObj: triggerJSONObj2,
		});
	}

	setPropertyIDAndName(propertyID, propertyName) {

		var selectedItemObj = this.state.selectedItemObj;
		var triggerJSONObj = this.state.triggerJSONObj;
		var triggerJSONObj2 = [];

		var i;
		var itemObj;
		for(i=0; i<triggerJSONObj.length; i++) {
			itemObj = triggerJSONObj[i];
			if (itemObj === selectedItemObj) {
				itemObj.propertyID = propertyID;
				itemObj.propertyName = propertyName;
			}
			triggerJSONObj2.push(itemObj);
		}
		
		this.setState({
			triggerJSONObj: triggerJSONObj2,
		});
	}

	onMultiplierChanged(multiplier) {
		
		var selectedItemObj = this.state.selectedItemObj;
		var triggerJSONObj = this.state.triggerJSONObj;
		var triggerJSONObj2 = [];

		var i;
		var itemObj;
		for(i=0; i<triggerJSONObj.length; i++) {
			itemObj = triggerJSONObj[i];
			if (itemObj === selectedItemObj) {
				itemObj.multiplier = multiplier;
			}
			triggerJSONObj2.push(itemObj);
		}
		
		this.setState({
			triggerJSONObj: triggerJSONObj2,
		});
	}

	renderOneLeftBlock(index, itemObj) {

		var selectedItemObj = this.state.selectedItemObj;
		var partName = itemObj.partName;
		var propertyName = itemObj.propertyName;
		var key = "left" + index;
		
		if (propertyName === "Count") {
			propertyName = this.props.getLang(PartLang, "count");
		}

		var className = "PropertyTriggerLeftName";
		if (itemObj === selectedItemObj) {
			className = "PropertyTriggerLeftNameSelected";
		}
		
		return (
			<div key={key}>
				<div className={className} onClick={() => this.handleSelectBlock(itemObj)}>
					{partName} / {propertyName}
				</div>
			</div>
		);
	}
	
	renderLeft() {

		var triggerJSONObj = this.state.triggerJSONObj;
		if (triggerJSONObj === null) {
			return;
		}

		var i;
		var jsxCode;
		var jsxArray = [];
		var itemObj;
		for(i=0; i<triggerJSONObj.length; i++) {
			itemObj = triggerJSONObj[i];
			jsxCode = this.renderOneLeftBlock(i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	renderRight() {
		
		var itemObj = this.state.selectedItemObj;
		if (itemObj === null) {
			return;
		}
		
		var partID = itemObj.partID;
		var partName = itemObj.partName;
		var propertyName = itemObj.propertyName;
		var multiplier = itemObj.multiplier;
		
		if (propertyName === "Count") {
			propertyName = this.props.getLang(PartLang, "count");
		}

		return (
			<div>
				<div className="PropertyTriggerRightTitleAndIcon">
					<div className="PropertyTriggerRightTitle">{partName} / {propertyName}</div>
					<div 
						className="PropertyTriggerRightDeleteIcon"
						onClick={() => this.handleDeleteBlock()}
						data-tip={this.props.getLang(PartLang, "deleteTriggerBlock")}
					>
						<BsTrash style={{ color: "black", width: "15px", height: "15px" }} />
					</div>
				</div>
				<div className="PropertyTriggerRightContainer">
					<div className="PropertyTriggerRightRow">
						<div className="PropertyTriggerRightRowTitle">{this.props.getLang(PartLang, "part")}:</div>
						<div 
							className="PropertyTriggerRightPartName"
							onClick={() => this.handleSelectPart(partID)}
							data-tip={this.props.getLang(PartLang, "selectPart")}
						>
							{partName}
						</div>
					</div>
					<div className="PropertyTriggerRightRow">
						<div className="PropertyTriggerRightRowTitle">{this.props.getLang(PartLang, "property")}:</div>
						<div 
							className="PropertyTriggerRightPropertyName"
							onClick={() => this.handleSelectProperty(partID)}
							data-tip={this.props.getLang(PartLang, "selectProperty")}
						>
							{propertyName}
						</div>
					</div>
					<div className="PropertyTriggerRightRow">
						<div className="PropertyTriggerRightRowTitle">{this.props.getLang(PartLang, "multiplier")}:</div>
						<input
							className="PropertyTriggerRightMultiplier" 
							id="multiplier" 
							type="number" 
							value={multiplier}
							onChange={(event) => this.onMultiplierChanged(event.target.value)}
						/>						
					</div>
				</div>
			</div>
		);
	}

	render() {

		var partName = this.state.partName;
		var propertyID = this.props.selectedPartPropertyID;
		var propertyName = this.state.propertyName;
		
		if (propertyName === "Count") {
			propertyName = this.props.getLang(PartLang, "count");
		}
		
		return (
			<div>
				<ReactTooltip
					type="info"
					delayShow={500}
				/>
				<PropertyTriggerToolbar
					userID={this.props.userID}
					ownerUserID={this.state.ownerUserID}
					productionAppUserType={this.props.productionAppUserType}
					partName={partName}
					propertyName={propertyName}
					createNewBlock={this.createNewBlock}
					getLang={this.props.getLang}
				/>
				<PartPickerPopup
					renderPopupYN={this.state.showPartPickerPopupYN}
					baseURLAPI={this.props.baseURLAPI}
					userID={this.props.userID}
					fullName={this.props.fullName}
					partPickerPartID={this.state.partPickerPartID}
					partTreeData={this.props.partTreeData}
					setPartIDAndName={this.setPartIDAndName}
					setShowPartPickerPopupYN={this.setShowPartPickerPopupYN}
					getLang={this.props.getLang}
				/>
				<PropertyPickerPopup
					renderPopupYN={this.state.showPropertyPickerPopupYN}
					baseURLAPI={this.props.baseURLAPI}
					userID={this.props.userID}
					fullName={this.props.fullName}
					propertyPickerPartID={this.state.propertyPickerPartID}
					setPropertyIDAndName={this.setPropertyIDAndName}
					setShowPropertyPickerPopupYN={this.setShowPropertyPickerPopupYN}
					getLang={this.props.getLang}
				/>
				<div className="PropertyTriggerContainer">
					<div className="PropertyTriggerLeft">
						<div style={{height: "10px"}}></div>
						{this.renderLeft()}
					</div>
					<div className="PropertyTriggerRight">
						{this.renderRight()}
					</div>
				</div>
				<div style={{height: "100px"}}></div>
				<div className="PropertyTriggerTopContainer">
					<div className="PropertyTriggerContainerButtons">
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(PartLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(PartLang, "save")}</button>
					</div>
				<div style={{height: "100px"}}></div>
				</div>
			</div>
		);
	}
}

export default function PropertyTriggerFunction(props) {
	const navigate = useNavigate();
	return <PropertyTrigger {...props} navigate={navigate} />;
}


