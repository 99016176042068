import React  from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './TaskInfoEditSchedule.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TaskLang from './TaskLang.json';

class TaskInfoEditSchedule extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			taskScheduleID: 0,
			startUnixTimeSec: 0,
			durationSec: 0,
			editedUserFullName: "",
			editedUnixTimeSec: 0,
			dataLoadedYN: false,
			startYear: 0,
			startMonth: 0,
			startDay: 0,
			startHours: 0,
			startMinutes: 0,
			durationHours: 0,
			durationMinutes: 0,
		};

		this.serverGetTaskScheduleData = this.serverGetTaskScheduleData.bind(this);
		this.serverUpdateTaskSchedule = this.serverUpdateTaskSchedule.bind(this);
		this.popUpClosed = this.popUpClosed.bind(this);
		this.onNotesChanged = this.onNotesChanged.bind(this);
		this.onSaveButton = this.onSaveButton.bind(this);
		this.onCancelButton = this.onCancelButton.bind(this);
		this.renderPopup = this.renderPopup.bind(this);
		this.datePickerSetDate = this.datePickerSetDate.bind(this);
		this.getDateObjFromUnixTimeSec = this.getDateObjFromUnixTimeSec.bind(this);
		this.onStartHoursChanged = this.onStartHoursChanged.bind(this);
		this.onStartMinutesChanged = this.onStartMinutesChanged.bind(this);
		this.onDurationHoursChanged = this.onDurationHoursChanged.bind(this);
		this.onDurationMinutesChanged = this.onDurationMinutesChanged.bind(this);		
	}
	
	componentDidMount() {
		var taskScheduleID = this.props.taskScheduleID;
		this.setState({taskScheduleID: taskScheduleID});
		this.serverGetTaskScheduleData(taskScheduleID);
	}

	componentDidUpdate() {
		
		var renderPopupYN = this.props.renderPopupYN;
		if (!renderPopupYN) {
			return;
		}

		var taskScheduleID1 = this.state.taskScheduleID;
		var taskScheduleID2 = this.props.taskScheduleID;
		
		if (taskScheduleID1 === taskScheduleID2) {
			return;
		}
		
		this.setState({taskScheduleID: taskScheduleID2});
		this.serverGetTaskScheduleData(taskScheduleID2);
	}

	async serverGetTaskScheduleData(taskScheduleID) {
		
		if (taskScheduleID <= 0) {
			return;
		}
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/gettaskscheduledata?taskscheduleid=" + taskScheduleID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var startUnixTimeSec = jsonObj.startUnixTimeSec;
		var durationSec = jsonObj.durationSec;
		var editedUserFullName = jsonObj.editedUserFullName;
		var editedUnixTimeSec = jsonObj.editedUnixTimeSec;
		
		var dateObj1 = this.getDateObjFromUnixTimeSec(startUnixTimeSec);
		
		var startYear = dateObj1.getFullYear();
		var startMonth = dateObj1.getMonth() + 1;
		var startDay = dateObj1.getDate();
		var startHours = dateObj1.getHours();
		var startMinutes = dateObj1.getMinutes();
		
		var durationHours = Math.floor(durationSec / 3600);
		var durationMinutes = Math.floor((durationSec - 3600*durationHours) / 60);

		this.setState({
			startUnixTimeSec: startUnixTimeSec,
			durationSec: durationSec,
			editedUserFullName: editedUserFullName,
			editedUnixTimeSec: editedUnixTimeSec,
			startYear: startYear,
			startMonth: startMonth,
			startDay: startDay,
			startHours: startHours,
			startMinutes: startMinutes,
			durationHours: durationHours,
			durationMinutes: durationMinutes,
			dataLoadedYN: true,
		});
	}
	
	async serverUpdateTaskSchedule(taskScheduleID, startUnixTimeSec, durationSec, editedUserID, editedUserFullName) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatetaskscheduledata?taskscheduleid=" + taskScheduleID;
		url = url + "&startunixtimesec=" + startUnixTimeSec;
		url = url + "&durationsec=" + durationSec;
		url = url + "&editeduserid=" + editedUserID;
		url = url + "&editeduserfullname=" + encodeURIComponent(editedUserFullName);

		const res = await fetch(url);
		await res.text();

		var taskID = this.props.taskID;
		this.props.serverCalcTaskDurationSec(taskID);
		this.props.serverGetTaskScheduleList(taskID);
	}

	popUpClosed() {
		this.setState({
			dataLoadedYN: false,
			taskScheduleID: 0,
		});
		this.props.setShowTaskInfoEditScheduleYN(false);
	}
	
	onNotesChanged(value) {
		this.setState({notes: value});
	}
	
	onSaveButton() {

		var taskScheduleID = this.state.taskScheduleID;
		var startYear = this.state.startYear;
		var startMonth = this.state.startMonth;
		var startDay = this.state.startDay;
		var startHours = this.state.startHours;
		var startMinutes = this.state.startMinutes;
		var durationHours = this.state.durationHours;
		var durationMinutes = this.state.durationMinutes;
		
		if ((startYear < 2000) || (startYear > 3000)) {
			this.props.showToast("Invalid year");
			return;
		}
		
		if ((startMonth < 1) || (startMonth > 12)) {
			this.props.showToast("Invalid month");
			return;
		}
		
		if ((startDay < 1) || (startDay > 31)) {
			this.props.showToast("Invalid day");
			return;
		}
		
		if ((startHours < 0) || (startHours > 23)) {
			this.props.showToast("Invalid start hour");
			return;
		}
		
		if ((startMinutes < 0) || (startMinutes > 59)) {
			this.props.showToast("Invalid start minute");
			return;
		}
		
		if ((durationHours < 0) || (durationHours > 23)) {
			this.props.showToast("Invalid duration hour");
			return;
		}
		
		if ((durationMinutes < 0) || (durationMinutes > 59)) {
			this.props.showToast("Invalid duration minute");
			return;
		}
		
		var dateObj = new Date();
		dateObj.setFullYear(startYear, startMonth-1, startDay);
		dateObj.setHours(startHours);
		dateObj.setMinutes(startMinutes);
		dateObj.setSeconds(0);
		dateObj.setMilliseconds(0);
		
		var startUnixTimeSec = Math.floor(dateObj.getTime() / 1000);
		var durationSec = durationHours * 3600 + durationMinutes * 60;
		var editedUserID = this.props.userID;
		var editedUserFullName = this.props.fullName;
		
		this.serverUpdateTaskSchedule(taskScheduleID, startUnixTimeSec, durationSec, editedUserID, editedUserFullName);
		this.popUpClosed();
	}
	
	onCancelButton() {
		this.popUpClosed();
	}

	datePickerSetDate(dateObj) {

		var startYear = dateObj.getFullYear();
		var startMonth = dateObj.getMonth() + 1;
		var startDay = dateObj.getDate();
		var startHours = this.state.startHours;
		var startMinutes = this.state.startMinutes;

		var dateObj2 = new Date();
		dateObj2.setFullYear(startYear, startMonth-1, startDay);
		dateObj2.setHours(startHours);
		dateObj2.setMinutes(startMinutes);
		dateObj2.setSeconds(0);
		dateObj2.setMilliseconds(0);
		
		var startUnixTimeSec = Math.floor(dateObj2.getTime() / 1000);
		this.setState({
			startYear: startYear,
			startMonth: startMonth,
			startDay: startDay,
			startUnixTimeSec: startUnixTimeSec,
		});
	}
	
	getDateObjFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);		
		return dateObj;
	}

	onStartHoursChanged(value) {
		var startHours = parseInt(value);
		this.setState({startHours: startHours});
	}
	
	onStartMinutesChanged(value) {
		var startMinutes = parseInt(value);
		this.setState({startMinutes: startMinutes});
	}
	
	onDurationHoursChanged(value) {
		var durationHours = parseInt(value);
		this.setState({durationHours: durationHours});
	}
	
	onDurationMinutesChanged(value) {
		var durationMinutes = parseInt(value);
		this.setState({durationMinutes: durationMinutes});
	}

	renderPopup() {
		
		var taskName = this.props.taskName;
		var startUnixTimeSec = this.state.startUnixTimeSec;
		var durationSec = this.state.durationSec;
		
		if (taskName.length > 40) {
			taskName = taskName.substring(0, 40) + "...";
		}

		var dateObj1 = this.getDateObjFromUnixTimeSec(startUnixTimeSec);
		
		var startHours = dateObj1.getHours();
		var startMinutes = dateObj1.getMinutes();
		
		var durationHours = Math.floor(durationSec / 3600);
		var durationMinutes = Math.floor((durationSec - 3600*durationHours) / 60);

		return (
			<Popup
				className="edit-schedule-popup"
				open={true}
				closeOnDocumentClick={true}
				onClose={this.popUpClosed}
			>
				<div className="TaskInfoEditScheduleTopContainer">
					<div className="TaskInfoEditScheduleHeaderContainer">
						<div className="TaskInfoEditScheduleHeaderText">{taskName} - {this.props.getLang(TaskLang, "editSchedule")}</div>
					</div>
					<div style={{height: "20px"}}></div>

					<div className="TaskInfoEditScheduleContainer">
						<div className="TaskInfoEditScheduleText">{this.props.getLang(TaskLang, "startDate")}:</div>
						<DatePicker 
							className="TaskInfoEditScheduleDatePicker" 
							selected={dateObj1}
							startOpen={false}
							onChange={(date) => this.datePickerSetDate(date)} 
							dateFormat="dd.MM.yyyy."
							//open={false}
						/>
					</div>
					<div style={{height: "20px"}}></div>
					
					<div className="TaskInfoEditScheduleContainer">
						<div className="TaskInfoEditScheduleText">{this.props.getLang(TaskLang, "startTime")}:</div>
						<div className="TaskInfoEditScheduleRight">
							<input
								className="TaskInfoEditScheduleInput" 
								id="startHours" 
								defaultValue={startHours}
								autoFocus={true}
								onChange={(event) => this.onStartHoursChanged(event.target.value)}
							/>
							<div className="TaskInfoEditScheduleDot">:</div>
							<input
								className="TaskInfoEditScheduleInput" 
								id="startMinutes" 
								defaultValue={startMinutes}
								onChange={(event) => this.onStartMinutesChanged(event.target.value)}
							/>
						</div>
					</div>
					<div style={{height: "20px"}}></div>

					<div className="TaskInfoEditScheduleContainer">
						<div className="TaskInfoEditScheduleText">{this.props.getLang(TaskLang, "duration")}:</div>
						<div className="TaskInfoEditScheduleRight">
							<input
								className="TaskInfoEditScheduleInput" 
								id="durationHours" 
								defaultValue={durationHours}
								onChange={(event) => this.onDurationHoursChanged(event.target.value)}
							/>
							<div className="TaskInfoEditScheduleDot">:</div>
							<input
								className="TaskInfoEditScheduleInput" 
								id="durationMinutes" 
								defaultValue={durationMinutes}
								onChange={(event) => this.onDurationMinutesChanged(event.target.value)}
							/>
						</div>
					</div>
					<div style={{height: "20px"}}></div>

					<div style={{height: "30px"}}></div>
					<div className="TaskInfoEditScheduleContainerButtons">
						<button className="ViewCancelButton" onClick={this.onCancelButton}>{this.props.getLang(TaskLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onSaveButton}>{this.props.getLang(TaskLang, "save")}</button>
					</div>
					<div style={{height: "30px"}}></div>
				</div>
			</Popup>
		);
	}

	render() {
		
		var renderPopupYN = this.props.renderPopupYN;
		
		if (!renderPopupYN) {
			return null;
		}
		
		if (!this.state.dataLoadedYN) {
			return null;
		}
		
		return (
			<div className={this.props.className}>
				{this.renderPopup()}
			</div>
		);
	}
}

export default TaskInfoEditSchedule;

