import React  from 'react';
import './UserPicker.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class UserPicker extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			companyID: 0,
			userID: 0,
			userName: null,
			userListJsonObj: null,
			renderPopupYN: false,
		};

		this.serverGetUserData = this.serverGetUserData.bind(this);
		this.serverGetUserList = this.serverGetUserList.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleSelectUser = this.handleSelectUser.bind(this);
		this.renderOneUser = this.renderOneUser.bind(this);
		this.renderAllUsers = this.renderAllUsers.bind(this);
		this.popUpClosed = this.popUpClosed.bind(this);
		this.renderPopup = this.renderPopup.bind(this);
	}
	
	componentDidMount() {
		var companyID = this.props.companyID;
		var userID = this.props.userID;
		var userName = this.props.userName;

		if (userName === null) {
			userName = "n/a";
		}
		if (userName === "") {
			userName = "n/a";
		}
		this.setState({
			companyID: companyID,
			userID: userID,
			userName: userName,
		});
	}

	componentDidUpdate() {
		var userID1 = this.props.userID;
		var userID2 = this.state.userID;
		
		if (userID1 === userID2) {
			return;
		}

		this.setState({
			userID: userID1,
			//userName: this.props.userName;
		});
		this.serverGetUserData(userID1);
	}
	
	async serverGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdata?userid=" + userID;
		
		try {
			const res = await fetch(url);
			const jsonObj = await res.json();
			
			var email = jsonObj.email;
			var firstName = jsonObj.firstName;
			var lastName = jsonObj.lastName;
			
			var fullName = firstName + " " + lastName;
			fullName = fullName.trim();
			
			var userName = fullName;
			if (userName === "") {
				userName = email;
			}

			this.setState({
				userName: userName,
			});
		} catch {
			this.setState({
				userName: "n/a",
			});
		}
	}
	
	async serverGetUserList(companyID) {

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserlist?companyid=" + companyID;
		url = url + "&sortby=email&sortasc=1&index=0&count=1000";
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			userListJsonObj: jsonObj,
		});
	}
	
	handleClick() {
		if (this.state.userListJsonObj === null) {
			this.serverGetUserList(this.state.companyID);
		}
		this.setState({renderPopupYN: true});
	}
	
	popUpClosed() {
		this.setState({renderPopupYN: false});
	}
	
	handleSelectUser(userID, userName) {
		this.props.onChange(userID, userName);
		this.setState({renderPopupYN: false});
	}
	
	renderOneUser(userID, email, firstName, lastName) {
		
		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		
		var userName = fullName;
		if (userName === "") {
			userName = email;
		}
		
		return (
			<tr key={userID}>
				<td
					className="UserPickerEmail"
					onClick={() => this.handleSelectUser(userID, userName)}
				>
					{email}
				</td>
				<td
					className="UserPickerName"
					onClick={() => this.handleSelectUser(userID, userName)}
				>
					{fullName}
				</td>
			</tr>
		);
	}

	renderAllUsers() {
		
		var userListJsonObj = this.state.userListJsonObj;
		
		if (userListJsonObj === null) {
			return null;
		}
		
		var i;
		var itemObj;
		var userID;
		var email;
		var firstName;
		var lastName;
		var jsxArray = [];
		
		for(i=0; i<userListJsonObj.length; i++) {
			itemObj = userListJsonObj[i];
			userID = itemObj.userID;
			email = itemObj.email;
			firstName = itemObj.firstName;
			lastName = itemObj.lastName;
			jsxArray.push(this.renderOneUser(userID, email, firstName, lastName));
		}		

		return (
			<table>
				<tbody>
					<tr>
						<td className="UserPickerEmailHeader">Email</td>
						<td className="UserPickerNameHeader">Name</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	renderPopup(renderPopupYN) {
		
		return (
			<Popup
				open={renderPopupYN}
				//modal={true}
				closeOnDocumentClick={true}
				//position="right center"
				onClose={this.popUpClosed}
			>
				<div>{this.renderAllUsers()}</div>
			</Popup>
		);
	}

	render() {
		
		var userName = this.state.userName;
		
		if (userName === null) {
			return null;
		}
		
		var renderPopupYN = this.state.renderPopupYN;
		
		return (
			<div className={this.props.className} onClick={this.handleClick}>
				{userName}
				{this.renderPopup(renderPopupYN)}
			</div>
		);
	}
}

export default UserPicker;

