import React  from 'react';
import './App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TaskLang from './TaskLang.json';

import UserPicker from './UserPicker.js';

import { useNavigate } from "react-router-dom";

class TaskEdit extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			taskID: 0,
			taskName: "",
			notes: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onTaskNameChanged = this.onTaskNameChanged.bind(this);
		this.onNotesChanged = this.onNotesChanged.bind(this);
		this.serverSetTaskData = this.serverSetTaskData.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		this.setState({
			taskID: this.props.taskID,
			taskName: this.props.taskName,
			notes: this.props.notes,
		});
	}

	componentDidUpdate(prevProps) {

		var taskID1 = this.state.taskID;
		var taskID2 = this.props.taskID;
		
		if (taskID1 === taskID2) {
			return;
		}

		this.setState({
			taskID: this.props.taskID,
			taskName: this.props.taskName,
			notes: this.props.notes,
		});
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onTaskNameChanged(value) {
		var taskName = value.trim();
		this.setState({taskName: taskName});
	}
	
	onNotesChanged(value) {
		this.setState({notes: value});
	}
	
	async serverSetTaskData(taskID, taskName, notes) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatetaskdata?taskid=" + taskID;
		url = url + "&taskname=" + taskName;
		url = url + "&notes=" + encodeURIComponent(notes);
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(TaskLang, "taskDataSaved"));
		this.props.setSelectedTab("info")	;
		this.props.serverGetTaskData(taskID);	
	}
		
	onClickSaveButton() {
		
		var taskName = this.state.taskName;
		if (taskName === "") {
			taskName = 0;
		}
		if (taskName === 0) {
			this.props.showToast(this.props.getLang(TaskLang, "taskNameEmpty"));
			return;
		}

		var taskID = this.state.taskID;
		var notes = this.state.notes;

		this.serverSetTaskData(taskID, taskName, notes);
	}
	
	onClickCancelButton() {
		this.props.setSelectedTab("info");
	}
	
	render() {		
	
		if (this.state.taskID === 0) {
			return null;
		}

		return (
		<div>
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(TaskLang, "editTaskInfo")}</div>
				<div className="ViewTopText">{this.props.getLang(TaskLang, "editTaskInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(TaskLang, "taskInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(TaskLang, "taskID")}</legend> 
							<input
								className="ViewItemInput" 
								id="taskID" 
								type="text" 
								defaultValue={this.state.taskID}
								disabled={true}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(TaskLang, "taskName")}</legend> 
							<input
								className="ViewItemInput" 
								id="taskName" 
								defaultValue={this.state.taskName}
								onChange={(event) => this.onTaskNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
								autoFocus={true}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(TaskLang, "notes")}</legend> 
							<textarea
								className="ViewItemInput" 
								id="notes" 
								defaultValue={this.state.notes}
								rows={15}
								onChange={(event) => this.onNotesChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(TaskLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(TaskLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		</div>
		);
	}
}

export default function TaskEditFunction(props) {
	const navigate = useNavigate();
	return <TaskEdit {...props} navigate={navigate} />;
}

