import React  from 'react';
import './App.css';
import './ReportTaskDaily.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from 'react-tooltip';
import { FaPause } from "react-icons/fa"
import { MdFiberNew, MdDone } from "react-icons/md"
import { BsClockHistory } from "react-icons/bs"
import ReportTaskDailyLang from './ReportTaskDailyLang.json';

import { useNavigate } from "react-router-dom";

class ReportTaskDaily extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			companyID: 0,
			dateYear: 0,
			dateMonth: 0,
			dateDay: 0,
			taskAndScheduleListJsonObj: null,
		};
		
		this.serverGetTaskListForOneDay = this.serverGetTaskListForOneDay.bind(this);
		this.handleSelectTask = this.handleSelectTask.bind(this);
		this.getTwoDigits = this.getTwoDigits.bind(this);
		this.getDateStringFromYMD = this.getDateStringFromYMD.bind(this);
		this.getDateObjFromYMD = this.getDateObjFromYMD.bind(this);
		this.prevDate = this.prevDate.bind(this);
		this.nextDate = this.nextDate.bind(this);
		this.datePickerSetDate = this.datePickerSetDate.bind(this);
		this.renderOneTask = this.renderOneTask.bind(this);
		this.renderAllTasksForOneUser = this.renderAllTasksForOneUser.bind(this);
		this.renderOneUser = this.renderOneUser.bind(this);
		this.renderAllUsers = this.renderAllUsers.bind(this);
		this.calcTotalSecFromTaskScheduleList = this.calcTotalSecFromTaskScheduleList.bind(this);
	}

	componentDidMount() {

		var directLinkYN = this.props.directLinkYN;
		var companyID = 0;
		var dateYear = 0;
		var dateMonth = 0;
		var dateDay = 0;

		if (directLinkYN) {
			var searchParams = document.location.search;
			var queryParams = new URLSearchParams(searchParams)
			var date = queryParams.get("date")
			var key = queryParams.get("key")
			if (key !== "021378638") {
				return;
			}
			if (date.length !== 10) {
				return;
			}
			dateYear = parseInt(date.substring(0, 4));
			dateMonth = parseInt(date.substring(5, 7));
			dateDay = parseInt(date.substring(8, 10));
			//console.log(dateYear + " " + dateMonth + " " + dateDay);
			companyID = parseInt(queryParams.get("companyid"));
			//console.log("companyID = " + companyID);
			if (companyID === "") {
				return;
			}
			this.props.serverGetUserList(companyID);
		} else {
			var currentDateTime = new Date();
			dateYear = currentDateTime.getFullYear();
			dateMonth = currentDateTime.getMonth() + 1;
			dateDay = currentDateTime.getDate();
			companyID = this.props.companyID;
		}

		this.setState({
			companyID: companyID,
			dateYear: dateYear,
			dateMonth: dateMonth,
			dateDay: dateDay,
		});

		this.serverGetTaskListForOneDay(companyID, dateYear, dateMonth, dateDay);
	}
	
	async serverGetTaskListForOneDay(companyID, dateYear, dateMonth, dateDay) {

		var baseURLAPI = this.props.baseURLAPI;
//		var url = baseURLAPI + "/gettasklistforoneday?companyid=" + companyID;
		var url = baseURLAPI + "/gettaskandschedulelistforoneday?companyid=" + companyID;
		url = url + "&year=" + dateYear;
		url = url + "&month=" + dateMonth;
		url = url + "&day=" + dateDay;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			taskAndScheduleListJsonObj: jsonObj,
		});
	}
		
	handleSelectTask(taskID) {

		var directLinkYN = this.props.directLinkYN;
		if (directLinkYN) {
			return;
		}
		
		this.props.setSelectedTaskID(taskID);
  		this.props.navigate("/task");
	}

	getTwoDigits(number1) {
		var number2;
		number2 = number1;
		if (number1 < 10) {
			number2 = "0" + number1;
		}
		return number2;
	}

	getDateStringFromYMD(year, month, day) {
		var dateString;
		dateString = this.getTwoDigits(day) + "." + this.getTwoDigits(month) + "." + year + ".";
		return dateString;
	}
	
	getDateObjFromYMD(year, month, day) {

		var dateObj = new Date();
		dateObj.setFullYear(year, month-1, day);
		dateObj.setHours(0);
		dateObj.setMinutes(0);
		dateObj.setSeconds(0);
		dateObj.setMilliseconds(0);

		return dateObj;
	}
	
	prevDate() {

		var dateYear = this.state.dateYear;
		var dateMonth = this.state.dateMonth;
		var dateDay = this.state.dateDay;
		
		var dateObj = new Date();
		dateObj.setFullYear(dateYear, dateMonth-1, dateDay);
		dateObj.setHours(0);
		dateObj.setMinutes(0);
		dateObj.setSeconds(0);
		dateObj.setMilliseconds(0);
		
		var unixTimeSec = Math.floor(dateObj.getTime() / 1000);
		var unixTimeSec2 = unixTimeSec - 24*3600;
		
		var dateObj2 = new Date(unixTimeSec2 * 1000);

		var dateYear2 = dateObj2.getFullYear();
		var dateMonth2 = dateObj2.getMonth() + 1;
		var dateDay2 = dateObj2.getDate();

		this.setState({
			dateYear: dateYear2,
			dateMonth: dateMonth2,
			dateDay: dateDay2,
		});

		this.serverGetTaskListForOneDay(this.state.companyID, dateYear2, dateMonth2, dateDay2);
	}
	
	nextDate() {

		var dateYear = this.state.dateYear;
		var dateMonth = this.state.dateMonth;
		var dateDay = this.state.dateDay;
		
		var dateObj = new Date();
		dateObj.setFullYear(dateYear, dateMonth-1, dateDay);
		dateObj.setHours(0);
		dateObj.setMinutes(0);
		dateObj.setSeconds(0);
		dateObj.setMilliseconds(0);
		
		var unixTimeSec = Math.floor(dateObj.getTime() / 1000);
		var unixTimeSec2 = unixTimeSec + 24*3600;
		
		var dateObj2 = new Date(unixTimeSec2 * 1000);

		var dateYear2 = dateObj2.getFullYear();
		var dateMonth2 = dateObj2.getMonth() + 1;
		var dateDay2 = dateObj2.getDate();

		this.setState({
			dateYear: dateYear2,
			dateMonth: dateMonth2,
			dateDay: dateDay2,
		});

		this.serverGetTaskListForOneDay(this.state.companyID, dateYear2, dateMonth2, dateDay2);
	}
		
	datePickerSetDate(dateObj) {

		var dateYear = dateObj.getFullYear();
		var dateMonth = dateObj.getMonth() + 1;
		var dateDay = dateObj.getDate();

		this.setState({
			dateYear: dateYear,
			dateMonth: dateMonth,
			dateDay: dateDay,
		});

		this.serverGetTaskListForOneDay(this.state.companyID, dateYear, dateMonth, dateDay);
	}
	
	renderOneTask(taskJsonObj, prevTaskID) {
		
		var taskID = taskJsonObj.taskID;
		if (taskID === prevTaskID) {
			return null;
		}

		var key = taskJsonObj.taskID;
		var taskName = taskJsonObj.taskName;		
		var status = taskJsonObj.status;
		var notes = taskJsonObj.notes;
		var partID = taskJsonObj.partID;
		var partName = taskJsonObj.partName;
		var partPropertyName = taskJsonObj.partPropertyName;
		var partPropertyIntDiff = taskJsonObj.partPropertyIntDiff;		
		var startedUnixTimeSec = taskJsonObj.taskStartedUnixTimeSec;
		var durationSec = taskJsonObj.taskDurationSec;
		
		var notesText = notes.trim();
		if (notesText.length > 50) {
			notesText = notes.substring(0, 50) + "...";
		}
		
		var partString = "";
		if (partID > 0) {
			partString = partName + ", " + partPropertyName + " (" + partPropertyIntDiff + ")";
		}

		if (notesText !== "") {
			if (partString === "") {
				partString = notesText;
			} else {
				partString = partString + ", " + notesText;
			}
		}

		var startedTimeString = this.props.getTimeStringFromUnixTimeSec(startedUnixTimeSec);
		var durationString = this.props.getTimeStringFromSeconds(durationSec);
		var startedDurationString = startedTimeString + ", " + durationString;
		
		var showNewIconYN = false;
		var showPlayIconYN = false;
		var showPausedIconYN = false;
		var showFinishedIconYN = false;
		
		if (status === "new") {
			showNewIconYN = true;
		}		
		if (status === "started") {
			showPlayIconYN = true;
		}
		if (status === "paused") {
			showPausedIconYN = true;
		}
		if (status === "finished") {
			showFinishedIconYN = true;
		}

		var directLinkYN = this.props.directLinkYN;
		var className = "";
		if (directLinkYN) {
			className = "";
		} else {
			className = "ReportTaskDailyTaskLink";
		}

		return (
			<tr key={key}
				className={className}
				onClick={() => this.handleSelectTask(taskID)}			
			>
				<td className="ReportTaskDailyTaskNameItem">{taskName}</td>
				<td className="ReportTaskDailyStatusItem">
					{showNewIconYN && (
						<MdFiberNew style={{ color: "blue", width: "20px", height: "20px" }} />
					)}
					{showPausedIconYN && (
						<FaPause style={{ color: "grey", width: "20px", height: "20px" }} />
					)}
					{showPlayIconYN && (
						<BsClockHistory style={{ color: "orange", width: "20px", height: "20px" }} />
					)}
					{showFinishedIconYN && (
						<MdDone style={{ color: "green", width: "20px", height: "20px" }} />
					)}
				</td>
				<td className="ReportTaskDailyPartItem">{partString}</td>
				<td className="ReportTaskDailyDurationItem">{startedDurationString}</td>
			</tr>
		);
	}

	renderAllTasksForOneUser(userID, taskAndScheduleListForOneUserJsonObj) {

		var i;
		var itemObj;
		var prevTaskID = 0;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<taskAndScheduleListForOneUserJsonObj.length; i++) {
			itemObj = taskAndScheduleListForOneUserJsonObj[i];
			jsxCode = this.renderOneTask(itemObj, prevTaskID);
			prevTaskID = itemObj.taskID;
			jsxArray.push(jsxCode);
		}
		
		return (
			<table>
				<tbody>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	calcTotalSecFromTaskScheduleList(taskAndScheduleListForOneUserJsonObj) {

		var i;
		var j;
		var itemObj;
		var status;
		var startUnixTimeSec;
		var currentUnixTimeSec;
		var durationSec;
		var dateObj;
		var hours;
		var minutes;
		var seconds;
		var startPosition;
		var secondArray = new Array(24*3600).fill(false);
		var totalSec = 0;
		
		dateObj = new Date();
		currentUnixTimeSec = Math.floor(dateObj.getTime() / 1000);

		for(i=0; i<taskAndScheduleListForOneUserJsonObj.length; i++) {
			
			itemObj = taskAndScheduleListForOneUserJsonObj[i];
			status = itemObj.status;
			startUnixTimeSec = itemObj.startUnixTimeSec;
			durationSec = itemObj.durationSec;
			dateObj = new Date(startUnixTimeSec * 1000);
			hours = dateObj.getHours();
			minutes = dateObj.getMinutes();
			seconds = dateObj.getSeconds();
			startPosition = 3600*hours + 60*minutes + seconds;
			
			if ((status === "started") && (durationSec === null)) {
				durationSec = currentUnixTimeSec - startUnixTimeSec;
			}

			for(j=startPosition; j<startPosition+durationSec; j++) {
				secondArray[j] = true;
			}
		}

		var segmentArray = [];
		var segmentObj;
		var startSec = -1;
		var endSec = -1;
		
		for(i=0; i<24*3600; i++) {
			if (secondArray[i]) {
				totalSec = totalSec + 1;
				if (startSec === -1) {
					startSec = i;
				}
			} else {
				if (startSec >= 0) {
					endSec = i;
					segmentObj = {};
					segmentObj.startSec = startSec;
					segmentObj.durationSec = endSec - startSec;
					segmentArray.push(segmentObj);
					startSec = -1;
					endSec = -1;
				}
			}
		}
		
		var explainString = "";
		var oneLine = "";
		var hour;
		var minute;
		var startString = "";
		var durationSecString = "";
		
		for(i=0; i<segmentArray.length; i++) {
			segmentObj = segmentArray[i];
			startSec = segmentObj.startSec;
			hour = Math.floor(startSec / 3600);
			minute = Math.floor((startSec - 3600*hour) / 60);
			durationSec = segmentObj.durationSec;
			startString = this.getTwoDigits(hour) + ":" + this.getTwoDigits(minute);
			durationSecString = this.props.getTimeStringFromSeconds(durationSec);
			
			oneLine = startString + " " + durationSecString;
			explainString = explainString + oneLine + "<br/>";
		}
		
		var resultObj = {};
		resultObj.totalSec = totalSec;
		resultObj.explainString = explainString;

		return resultObj;
	}

	renderOneUser(user) {

		var userID = parseInt(user.userID);
		var firstName = user.firstName;
		var lastName = user.lastName;
		var email = user.email;
		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		if (fullName === "") {
			fullName = email;
		}
		
		var i;
		var itemObj;
		var userID2;
		var durationSec;
		var totalSec = 0;
		var taskAndScheduleListJsonObj = this.state.taskAndScheduleListJsonObj;
		var taskAndScheduleListForOneUserJsonObj = [];
		
		for(i=0; i<taskAndScheduleListJsonObj.length; i++) {
			itemObj = taskAndScheduleListJsonObj[i];
			userID2 = parseInt(itemObj.userID);
			if (userID === userID2) {
				taskAndScheduleListForOneUserJsonObj.push(itemObj);
				durationSec = itemObj.durationSec;
				totalSec = totalSec + durationSec;
			}
		}
		
		if (taskAndScheduleListForOneUserJsonObj.length === 0) {
			return null;
		}

		// temp - Nediljko - 20; Niksa - 3
		if (userID !== 3) {
			//return null;
		}

		var resultObj = this.calcTotalSecFromTaskScheduleList(taskAndScheduleListForOneUserJsonObj);
		totalSec = resultObj.totalSec;
		var explainString = resultObj.explainString;
		
		var totalSecString = this.props.getTimeStringFromSeconds(totalSec);
		var totalSecDataTip = explainString;

		return (
			<div>
				<div className="ReportTaskDailyFullName">{fullName}</div>
				{this.renderAllTasksForOneUser(userID, taskAndScheduleListForOneUserJsonObj)}
				<table>
					<tbody>
						<tr>
							<td className="ReportTaskDailyTaskNameItem" data-tip={totalSecDataTip}>{this.props.getLang(ReportTaskDailyLang, "total")}:</td>
							<td className="ReportTaskDailyStatusItem"></td>
							<td className="ReportTaskDailyPartItem"></td>
							<td className="ReportTaskDailyDurationItem" data-tip={totalSecDataTip}><b><i>{totalSecString}</i></b></td>
						</tr>
					</tbody>
				</table>
				<div style={{height: "20px"}}/>
			</div>
		);
	}

	renderAllUsers() {

		var taskAndScheduleListJsonObj = this.state.taskAndScheduleListJsonObj;
		if (taskAndScheduleListJsonObj.length === 0) {
			return (
				<div>No tasks found</div>
			);
		}

		var userListJsonObj = this.props.userListJsonObj;
		
		return (
			<div className="ReportTaskDailyContainer">
				<table>
					<tbody>
						<tr>
							<td className="ReportTaskDailyTaskNameHeader"></td>
							<td className="ReportTaskDailyStatusHeader">{this.props.getLang(ReportTaskDailyLang, "status")}</td>
							<td className="ReportTaskDailyPartHeader">{this.props.getLang(ReportTaskDailyLang, "partNotes")}</td>
							<td className="ReportTaskDailyDurationHeader">{this.props.getLang(ReportTaskDailyLang, "startedDuration")}</td>
						</tr>
					</tbody>
				</table>
				<div style={{height: "5px"}}/>
				{userListJsonObj.map((user) => (
					<div key={user.userID}>{this.renderOneUser(user)}</div>
				))}
			</div>
		);
	}

	render() {
		
		var userListJsonObj = this.props.userListJsonObj;
		if (userListJsonObj === null) {
			return null;
		}

		var taskAndScheduleListJsonObj = this.state.taskAndScheduleListJsonObj;
		if (taskAndScheduleListJsonObj === null) {
			return null;
		}
		
		var dateObj = this.getDateObjFromYMD(this.state.dateYear, this.state.dateMonth, this.state.dateDay);
		
		return (
			<div>
				<ReactTooltip
					type="info"
					multiline={true}
					delayShow={200}
				/>
				<div className="ReportTaskDailyTopContainer">
					<div className="ReportTaskDailyTopTopTitle">{this.props.getLang(ReportTaskDailyLang, "tasksDailyReport")}</div>
					<div className="ReportTaskDailyDateContainer">
						<div className="ReportTaskDailyDatePrev" onClick={() => this.prevDate()}>&lt;&lt;</div>
						<DatePicker 
							className="ReportTaskDailyDateString" 
							selected={dateObj} onChange={(date) => this.datePickerSetDate(date)} 
							dateFormat="dd.MM.yyyy."
						/>
						<div className="ReportTaskDailyDateNext" onClick={() => this.nextDate()}>&gt;&gt;</div>
					</div>
				</div>
				<div style={{height: "15px"}}/>
				{this.renderAllUsers()}
			</div>
		);
	}
}

function useGetNavigate() {
	var navigate = useNavigate();
	return navigate;
}

export default function ReportTaskDailyFunction(props) {
	try {
		var navigate = useGetNavigate();
	} catch {
	}
	return <ReportTaskDaily {...props} navigate={navigate} />;
}

