import React  from 'react';
import './App.css';
import './Part.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import PartLang from './PartLang.json';

import PartRightChartCustomTooltip from './PartRightChartCustomTooltip.js';

class PartRightChart extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			partID: 0,
			partName: "",
			countInit: 0,
			count: 0,
			notes: "",
			propertyIDArray: [],
			propertyNameArray: [],
			propertyTypeArray: [],
			propertyValueArray: [],
			propertyValueInitArray: [],
			propertyCount: -1,
			logListJsonObj: null,
			logCount: 0,
			startUnixTime: 0,
			diffDays: 0,
			dataLoadedYN: false,
		};
		
		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.serverGetPartPropertyList = this.serverGetPartPropertyList.bind(this);	
		this.serverGetPartLogList = this.serverGetPartLogList.bind(this);	
		this.getIndexClosestToTime = this.getIndexClosestToTime.bind(this);
		this.getData = this.getData.bind(this);
		this.renderOneLine = this.renderOneLine.bind(this);
		this.renderAllProperties = this.renderAllProperties.bind(this);
	}
	
	componentDidMount() {
		var selectedPartID = this.props.selectedPartID;
		this.setState({partID: selectedPartID});
		this.serverGetPartData(selectedPartID);
		this.serverGetPartPropertyList(selectedPartID);
		this.serverGetPartLogList(selectedPartID);
	}
	
	componentDidUpdate(prevProps) {

		var selectedPartID = this.props.selectedPartID;
		var partID = this.state.partID;
		
		if (partID === selectedPartID) {
			return;
		}
		
		this.setState({
			partID: selectedPartID,
			dataLoadedYN: false,
		});

		this.serverGetPartData(selectedPartID);
		this.serverGetPartPropertyList(selectedPartID);
		this.serverGetPartLogList(selectedPartID);
	}

	async serverGetPartData(partID) {

		if (partID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var partName = jsonObj.partName;
		var count = jsonObj.count;
		var notes = jsonObj.notes;

		this.setState({
			partName: partName,
			countInit: count,
			count: count,
			notes: notes,
		});
	}
		
	async serverGetPartPropertyList(partID) {

		if (partID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertylist?partid=" + partID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var i;
		var itemObj;
		var propertyID;
		var propertyName;
		var propertyType;
		var propertyValue;
		var propertyIDArray = [];
		var propertyNameArray = [];
		var propertyTypeArray = [];
		var propertyValueArray = [];
		var propertyValueInitArray = [];
		var propertyCount = jsonObj.length;
		
		for(i=0; i<propertyCount; i++) {

			itemObj = jsonObj[i];
			propertyID = itemObj.partPropertyID;
			propertyName = itemObj.propertyName;
			propertyType = itemObj.propertyType;
			
			propertyValue = "";
			if (propertyType === "int") {
				propertyValue = itemObj.intValue;
			}
			if (propertyType === "float") {
				propertyValue = itemObj.floatValue;
			}
			if (propertyType === "text") {
				propertyValue = itemObj.textValue;
			}
			
			propertyIDArray.push(propertyID);
			propertyNameArray.push(propertyName);
			propertyTypeArray.push(propertyType);
			propertyValueArray.push(propertyValue);
			propertyValueInitArray.push(propertyValue);
		}

		this.setState({
			propertyIDArray: propertyIDArray,
			propertyNameArray: propertyNameArray,
			propertyTypeArray: propertyTypeArray,
			propertyValueArray: propertyValueArray,
			propertyValueInitArray: propertyValueInitArray,
			propertyCount: propertyCount,
		});
	}
	
	async serverGetPartLogList(partID) {

		if (partID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartloglist?partid=" + partID + "&days=30";
		
		const res = await fetch(url);
		const logListJsonObj = await res.json();

		var itemObj;
		var timestampCreated;
		var startUnixTime = 0;
		var endUnixTime = 0;
		var diffSeconds = 0;
		var diffDays = 0;
		var logCount = logListJsonObj.length;
		var currentDate = new Date();
		var currentUnixTime = 0;
		
		if (logCount > 0) {
			itemObj = logListJsonObj[0];
			timestampCreated = itemObj.timestampCreated;
			startUnixTime = parseInt((new Date(timestampCreated).getTime() / 1000).toFixed(0));
			itemObj = logListJsonObj[logCount-1];
			timestampCreated = itemObj.timestampCreated;
			endUnixTime = parseInt((new Date(timestampCreated).getTime() / 1000).toFixed(0));
			currentUnixTime = parseInt((currentDate.getTime() / 1000).toFixed(0));
			diffSeconds = currentUnixTime - startUnixTime;
			diffDays = Math.floor(diffSeconds / 86400) + 1;
		}

		this.setState({
			logListJsonObj: logListJsonObj,
			logCount: logCount,
			startUnixTime: startUnixTime,
			diffDays: diffDays,
			dataLoadedYN: true,
		});
	}
	
	getIndexClosestToTime(logListJsonObj, logCount, propertyID, propertyName, unixTime1) {

		var i;
		var itemObj;
		var propertyID2;
		var propertyName2;
		var timestampCreated;
		var unixTime;
		var unixTime2;
		var foundYN;
		var index = -1;
		
		unixTime2 = unixTime1 + 86400;

		for(i=0; i<logCount; i++) {

			itemObj = logListJsonObj[i];
			propertyID2 = itemObj.propertyID;
			propertyName2 = itemObj.propertyName;

			foundYN = false;
			if (propertyID === propertyID2) {
				if (propertyID === 0) {
					if (propertyName === propertyName2) {
						foundYN = true;
					}
				} else {
					foundYN = true;
				}
			}

			if (foundYN) {
				timestampCreated = itemObj.timestampCreated;
				unixTime = parseInt((new Date(timestampCreated).getTime() / 1000).toFixed(0));
				if ((unixTime >= unixTime1) && (unixTime <= unixTime2)) {
					return i;
				}
				if (unixTime > unixTime1) {
					return index;
				}
				index = i;
			}
		}
		
		return index;
	}
	
	getData() {

		var i;
		var j;
		var logListJsonObj = this.state.logListJsonObj;
		var logCount = this.state.logCount;
		var startUnixTime = this.state.startUnixTime;
		var diffDays = this.state.diffDays;
		var itemObj;
		var data = [];
		var oneRow;
		var unixTime;
		var countValue;
		var propertyID;
		var propertyName;
		var index;
		
		for(i=0; i<diffDays; i++) {

			oneRow = {};

			// time
			oneRow.time = i;
			unixTime = startUnixTime + i*86400;

			propertyID = 0;
			propertyName = "count";
			index = this.getIndexClosestToTime(logListJsonObj, logCount, propertyID, propertyName, unixTime);
			countValue = 0;
			if (index > -1) {
				itemObj = logListJsonObj[index];
				countValue = itemObj.valueNew;
			}
			oneRow["Count"] = countValue;

			var propertyCount = this.state.propertyCount;
			var propertyID;
			var propertyName;
			var propertyValue;
			
			for(j=0; j<propertyCount; j++) {
				propertyID = this.state.propertyIDArray[j];
				propertyName = this.state.propertyNameArray[j];
				index = this.getIndexClosestToTime(logListJsonObj, logCount, propertyID, propertyName, unixTime);
				propertyValue = 0;
				if (index > -1) {
					itemObj = logListJsonObj[index];
					propertyValue = itemObj.valueNew;
				}
				oneRow[propertyName] = propertyValue;
			}
			
			data.push(oneRow);
		}

		return data;
	}
		
	renderOneLine(variableName, color) {
		return (
			<Line type="monotone" dataKey={variableName} stroke={color}/>
		);
	}
	
	renderAllProperties() {

		var i;
		var propertyCount = this.state.propertyCount;
		var propertyID;
		var propertyName;
		var color;
		var colorArray = [];
		var jsxCode;
		var jsxArray = [];
		
		colorArray.push("green");
		colorArray.push("blue");
		colorArray.push("orange");
		colorArray.push("#fff000");
		colorArray.push("#30ff30");
		colorArray.push("#3090ff");
		colorArray.push("#82ca9d");
		colorArray.push("#ca829d");
		colorArray.push("#829dca");
		colorArray.push("grey");

		for(i=0; i<propertyCount; i++) {
			propertyID = this.state.propertyIDArray[i];
			propertyName = this.state.propertyNameArray[i];
			color = "grey";
			if (i < 10) {
				color = colorArray[i];
			}
			jsxCode = this.renderOneLine(propertyName, color);
			jsxArray.push(jsxCode);
		}

		return jsxArray;
	}

	render() {
		
		if (!this.state.dataLoadedYN) {
			return null;
		}

		if (this.props.selectedPartID <= 0) {
			return null;
		}
		
		var partName = this.state.partName;

		var width1 = window.innerWidth - 400;
		var height1 = window.innerHeight - 200;

		var data = this.getData();
		
		var logCount = this.state.logCount;
		var showChartYN = false;
		if (logCount > 0) {
			showChartYN = true;
		}

		return (
			<div>
				<div className="PartRightName">{partName}</div>

				<LineChart
					width={width1}
					height={height1}
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="time" />
					<Tooltip content={
							<PartRightChartCustomTooltip
								startUnixTime={this.state.startUnixTime}
							/>} 
							cursor={false} 
					/>
					<Legend />
					{this.renderOneLine("Count", "red")}
					{this.renderAllProperties()}
				</LineChart>
			</div>
			);
	}
}

export default PartRightChart;

/*
					<YAxis domain={[0, 100]} allowDataOverflow={true} />
*/