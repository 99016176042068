import React  from 'react';
import './App.css';
import './Part.css';
import { useNavigate } from "react-router-dom";
import PartLang from './PartLang.json';

class PropertyListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(linkTitle, linkValue) {

		var classNameString = "PropertyListToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {
		
		var selectedPartName = this.props.selectedPartName;
		var newPropertyString = this.props.getLang(PartLang, "newProperty");
		
		return (
			<div className="PropertyListToolbarContainer1">
				<div className="PropertyListToolbarLeft">{selectedPartName}</div>
				<div className="PropertyListToolbarCenter">&nbsp;</div>
				<div className="PropertyListToolbarRight">
					<div className="PropertyListToolbarContainer2">
						{this.renderLink(newPropertyString, "/setup/properties/new")}
					</div>
				</div>
			</div>
		);
	}
}

export default function PropertyListToolbarFunction(props) {
	const navigate = useNavigate();
	return <PropertyListToolbar {...props} navigate={navigate} />;
}

