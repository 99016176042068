import React  from 'react';
import './App.css';
import './ReportTaskMonthly.css';
import { FaPause } from "react-icons/fa"
import { MdFiberNew, MdDone } from "react-icons/md"
import { BsClockHistory } from "react-icons/bs"
import UserPicker from './UserPicker.js';
import ReportTaskMonthlyLang from './ReportTaskMonthlyLang.json';

import { useNavigate } from "react-router-dom";

class ReportTaskMonthly extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			userID: 0,
			fullName: "",
			dateYear: 0,
			dateMonth: 0,
			taskListJsonObj: null,
		};
		
		this.serverGetTaskListForOneMonth = this.serverGetTaskListForOneMonth.bind(this);
		this.handleSelectTask = this.handleSelectTask.bind(this);
		this.getTwoDigits = this.getTwoDigits.bind(this);
		this.getDateStringFromYMD = this.getDateStringFromYMD.bind(this);
		this.getDateObjFromYMD = this.getDateObjFromYMD.bind(this);
		this.prevMonth = this.prevMonth.bind(this);
		this.nextMonth = this.nextMonth.bind(this);
		this.renderOneTask = this.renderOneTask.bind(this);
		this.renderAllTasksForOneDay = this.renderAllTasksForOneDay.bind(this);
		this.renderOneUser = this.renderOneUser.bind(this);
		this.onUserIDChanged = this.onUserIDChanged.bind(this);
		this.getDayStringFromDayNumber = this.getDayStringFromDayNumber.bind(this);		
	}

	componentDidMount() {

		var userID = this.props.userID;
		var fullName = this.props.fullName;
		var currentDateTime = new Date();
		var dateYear = currentDateTime.getFullYear();
		var dateMonth = currentDateTime.getMonth() + 1;
		
		this.setState({
			userID: userID,
			fullName: fullName,
			dateYear: dateYear,
			dateMonth: dateMonth,
		});

		this.serverGetTaskListForOneMonth(userID, dateYear, dateMonth);
	}
	
	async serverGetTaskListForOneMonth(userID, dateYear, dateMonth) {

		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/gettasklistforonemonth?userid=" + userID;
		url = url + "&year=" + dateYear;
		url = url + "&month=" + dateMonth;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			taskListJsonObj: jsonObj,
		});
	}
		
	handleSelectTask(taskID) {
		this.props.setSelectedTaskID(taskID);
  		this.props.navigate("/task");
	}

	getTwoDigits(number1) {
		var number2;
		number2 = number1;
		if (number1 < 10) {
			number2 = "0" + number1;
		}
		return number2;
	}
	
	getDayStringFromDayNumber(dayNumber) {
		if (dayNumber < 0) {
			return "";
		}
		if (dayNumber > 6) {
			return "";
		}
		var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		var dayString = dayNames[dayNumber];
		return dayString;
	}

	getDateStringFromYMD(year, month, day) {
		var dateString;
		dateString = this.getTwoDigits(day) + "." + this.getTwoDigits(month) + "." + year + ".";
		return dateString;
	}
	
	getDateObjFromYMD(year, month, day) {

		var dateObj = new Date();
		dateObj.setFullYear(year, month-1, day);
		dateObj.setHours(0);
		dateObj.setMinutes(0);
		dateObj.setSeconds(0);
		dateObj.setMilliseconds(0);

		return dateObj;
	}
	
	prevMonth() {

		var dateYear = this.state.dateYear;
		var dateMonth = this.state.dateMonth;
		
		var dateYear2 = dateYear;
		var dateMonth2 = dateMonth - 1;
		if (dateMonth2 === 0) {
			dateMonth2 = 12;
			dateYear2 = dateYear - 1;
		}

		this.setState({
			dateYear: dateYear2,
			dateMonth: dateMonth2,
		});

		this.serverGetTaskListForOneMonth(this.state.userID, dateYear2, dateMonth2);
	}
	
	nextMonth() {

		var dateYear = this.state.dateYear;
		var dateMonth = this.state.dateMonth;
		
		var dateYear2 = dateYear;
		var dateMonth2 = dateMonth + 1;
		if (dateMonth2 === 13) {
			dateMonth2 = 1;
			dateYear2 = dateYear + 1;
		}

		this.setState({
			dateYear: dateYear2,
			dateMonth: dateMonth2,
		});

		this.serverGetTaskListForOneMonth(this.state.userID, dateYear2, dateMonth2);
	}
		
	renderOneTask(taskJsonObj) {
		
		var key = taskJsonObj.taskID;
		var taskID = taskJsonObj.taskID;
		var taskName = taskJsonObj.taskName;		
		var status = taskJsonObj.status;
		var notes = taskJsonObj.notes;
		var partID = taskJsonObj.partID;
		var partName = taskJsonObj.partName;
		var partPropertyName = taskJsonObj.partPropertyName;
		var partPropertyIntDiff = taskJsonObj.partPropertyIntDiff;		
		var startedUnixTimeSec = taskJsonObj.startedUnixTimeSec;
		var durationSec = taskJsonObj.durationSec;
		
		var notesText = notes.trim();
		if (notesText.length > 50) {
			notesText = notes.substring(0, 50) + "...";
		}
		
		var partString = "";
		if (partID > 0) {
			partString = partName + ", " + partPropertyName + " (" + partPropertyIntDiff + ")";
		}

		if (notesText !== "") {
			if (partString === "") {
				partString = notesText;
			} else {
				partString = partString + ", " + notesText;
			}
		}

		var startedDateString = this.props.getDateTimeStringFromUnixTimeSec(startedUnixTimeSec);
		var durationString = this.props.getTimeStringFromSeconds(durationSec);
		var startedDurationString = startedDateString + ", " + durationString;
		
		var showNewIconYN = false;
		var showPlayIconYN = false;
		var showPausedIconYN = false;
		var showFinishedIconYN = false;
		
		if (status === "new") {
			showNewIconYN = true;
		}		
		if (status === "started") {
			showPlayIconYN = true;
		}
		if (status === "paused") {
			showPausedIconYN = true;
		}
		if (status === "finished") {
			showFinishedIconYN = true;
		}

		return (
			<tr key={key}
				className="ReportTaskMonthlyTaskLink"
				onClick={() => this.handleSelectTask(taskID)}			
			>
				<td className="ReportTaskMonthlyTaskNameItem">{taskName}</td>
				<td className="ReportTaskMonthlyStatusItem">
					{showNewIconYN && (
						<MdFiberNew style={{ color: "blue", width: "20px", height: "20px" }} />
					)}
					{showPausedIconYN && (
						<FaPause style={{ color: "grey", width: "20px", height: "20px" }} />
					)}
					{showPlayIconYN && (
						<BsClockHistory style={{ color: "orange", width: "20px", height: "20px" }} />
					)}
					{showFinishedIconYN && (
						<MdDone style={{ color: "green", width: "20px", height: "20px" }} />
					)}
				</td>
				<td className="ReportTaskMonthlyPartItem">{partString}</td>
				<td className="ReportTaskMonthlyDurationItem">{startedDurationString}</td>
			</tr>
		);
	}

	renderAllTasksForOneDay(day) {

		var i;
		var itemObj;
		var taskListJsonObj = this.state.taskListJsonObj;
		var taskListForOneDayJsonObj = [];
		var dateYear = this.state.dateYear;
		var dateMonth = this.state.dateMonth;

		var dateObj = new Date();
		dateObj.setFullYear(dateYear, dateMonth-1, day);
		dateObj.setHours(0);
		dateObj.setMinutes(0);
		dateObj.setSeconds(0);
		dateObj.setMilliseconds(0);

		var startedUnixTimeSec;
		var unixTimeStart = Math.floor(dateObj.getTime() / 1000);
		var unixTimeEnd = unixTimeStart + 24*3600;
		
		for(i=0; i<taskListJsonObj.length; i++) {
			itemObj = taskListJsonObj[i];
			startedUnixTimeSec = itemObj.startedUnixTimeSec;
			if ((startedUnixTimeSec >= unixTimeStart) && (startedUnixTimeSec < unixTimeEnd)) {
				taskListForOneDayJsonObj.push(itemObj);
			}
		}

		if (taskListForOneDayJsonObj.length === 0) {
			return null;
		}

		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<taskListForOneDayJsonObj.length; i++) {
			itemObj = taskListForOneDayJsonObj[i];
			jsxCode = this.renderOneTask(itemObj);
			jsxArray.push(jsxCode);
		}
		
		var dateObj = this.getDateObjFromYMD(dateYear, dateMonth, day);
		var dayNumber = dateObj.getDay();
		var dayString = this.getDayStringFromDayNumber(dayNumber) + " " + this.getDateStringFromYMD(dateYear, dateMonth, day);
		
		return (
			<div key={day}>
				<div className="ReportTaskMonthlyDayString">{dayString}</div>
				<table>
					<tbody>
						{jsxArray}
					</tbody>
				</table>
				<div style={{height: "20px"}}/>
			</div>
		);
	}

	renderOneUser() {
		
		var i;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<31; i++) {
			jsxCode = this.renderAllTasksForOneDay(i);
			if (jsxCode !== null) {
				jsxArray.push(jsxCode);
			}
		}
		
		return (
			<div className="ReportTaskMonthlyContainer">
				{jsxArray}
			</div>
		);
	}
	
	onUserIDChanged(userID, fullName) {
		this.setState({
			userID: userID,
			fullName: fullName,
		});
		this.serverGetTaskListForOneMonth(userID, this.state.dateYear, this.state.dateMonth);
	}

	render() {		

		var taskListJsonObj = this.state.taskListJsonObj;
		if (taskListJsonObj === null) {
			return null;
		}
		
		var dateYear = this.state.dateYear;
		var dateMonth = this.state.dateMonth;
		
		var monthString = dateMonth + "/" + dateYear;
		
		return (
			<div>
				<div className="ReportTaskMonthlyTopContainer">
					<div className="ReportTaskMonthlyTopLeft">{this.props.getLang(ReportTaskMonthlyLang, "tasksMonthlyReport")}</div>
					<div className="ReportTaskMonthlyTopRight">
						<div className="ReportTaskMonthlyDateContainer">
									<UserPicker
										className="ReportTaskMonthlyUser" 
										managementBaseURLAPI={this.props.managementBaseURLAPI}
										companyID={this.props.companyID}
										userID={this.state.userID}
										userName={this.state.fullName}
										onChange={this.onUserIDChanged}
									/>
							<div className="ReportTaskMonthlyDatePrev" onClick={() => this.prevMonth()}>&lt;&lt;</div>
							<div className="ReportTaskMonthlyDateString">{monthString}</div>
							<div className="ReportTaskMonthlyDateNext" onClick={() => this.nextMonth()}>&gt;&gt;</div>
						</div>
					</div>
				</div>
				<div style={{height: "15px"}}/>
				{this.renderOneUser()}
			</div>
		);
	}
}

export default function ReportTaskMonthlyFunction(props) {
	const navigate = useNavigate();
	return <ReportTaskMonthly {...props} navigate={navigate} />;
}
