import React  from 'react';
import './App.css';
import AppLang from './AppLang.json';

import { useNavigate } from "react-router-dom";

class SelectProject extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			jsonObj: null,
		};

		this.serverGetProjectList = this.serverGetProjectList.bind(this);
		this.handleSelectProject = this.handleSelectProject.bind(this);
	}
	
	componentDidMount() {
		this.serverGetProjectList();
	}
	
	async serverGetProjectList() {

		var baseURLAPI = this.props.baseURLAPI;
		var companyID = this.props.companyID;
		var url = baseURLAPI + "/getprojectlist?companyid=" + companyID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			jsonObj: jsonObj,
		});
	}
		
	handleSelectProject(projectID, projectName) {
		this.props.serverGetPartsTreeData(projectID);
		this.props.setSelectedProjectIDAndName(projectID, projectName);
		const navigate = this.props.navigate;
  		navigate("/changelog");
	}

	render() {
		
		const RenderProject = ({ projectID, projectName }) => (
			<div className="ListRow" key={projectID}>
				<div
					className="ListLink"
					onClick={() => this.handleSelectProject(projectID, projectName)}
				>
					{projectName}
				</div>
			</div>
		);

		var jsonObj = this.state.jsonObj;
		
		if (jsonObj === null) {
			return (<div></div>)
		}
		
		return (
			<div>
				<div className="ListContainer1">
					<div className="ListContainer2">
						<div className="ListRow">
							<div className="ListHeader">&nbsp;</div>
						</div>
						<div className="ListRow">
							<div className="ListHeader">{this.props.getLang(AppLang, "selectProject")}</div>
						</div>
						{jsonObj.map((project) => (
							<RenderProject
								key={project.projectID}
								projectID={project.projectID}
								projectName={project.projectName}
							/>
						))}
						<p>&nbsp;</p>
					</div>
				</div>
			</div>
		);
	}
}

export default function SelectProjectFunction(props) {
	const navigate = useNavigate();
	return <SelectProject {...props} navigate={navigate} />;
}

