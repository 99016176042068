import React  from 'react';
import './App.css';
import AppLang from './AppLang.json';

class About extends React.Component {

	constructor(props) {

		super(props);

		this.renderOneLine = this.renderOneLine.bind(this);
	}
	
	renderOneLine(dateString, infoText) {
		
		return (
			<div>
				<div className="AboutDateString">{dateString}</div>
				<div className="AboutInfoText">{infoText}</div>
				<div style={{height: "15px"}}></div>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div style={{height: "20px"}}></div>
				<div className="AboutTitle">{this.props.getLang(AppLang, "about")}</div>
				<div style={{height: "20px"}}></div>
				{this.renderOneLine("Android mob app", "https://storageapi.plurato.com/apk/production.apk")}
				{this.renderOneLine("16.5.2023.", "Dodana sekcija Machines")}
				{this.renderOneLine("21.12.2022.", "Editiranje trigger dogadjaja i min dogadjaja koji salje email")}
				{this.renderOneLine("12.12.2022.", "Prevedena mobilna aplikacija na HRV")}
				{this.renderOneLine("09.12.2022.", "Prevedena web aplikacija na HRV")}
				{this.renderOneLine("10.11.2022.", "Prva verzija mobilne aplikacije")}
				{this.renderOneLine("04.11.2022.", "Dodali tasks / zadatke")}
			</div>
		);
	}
}

export default About;

