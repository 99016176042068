import React  from 'react';
import './App.css';
import './Part.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PartLang from './PartLang.json';

import { useNavigate } from "react-router-dom";

class PartUpdate extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			partName: "",
			propertyCount: 0,
			fileCount: 0,
			subPartCount: 0,
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onPartNameChanged = this.onPartNameChanged.bind(this);
		this.serverUpdatePartName = this.serverUpdatePartName.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.onClickDeleteButton = this.onClickDeleteButton.bind(this);

		this.serverGetPropertyCount = this.serverGetPropertyCount.bind(this);
		this.serverGetFileCount = this.serverGetFileCount.bind(this);
		this.serverGetSubPartCount = this.serverGetSubPartCount.bind(this);
		this.serverDeletePartRecord = this.serverDeletePartRecord.bind(this);
	}

	componentDidMount() {
		var selectedPartID = this.props.selectedPartID;
		this.serverGetPropertyCount(selectedPartID);
		this.serverGetFileCount(selectedPartID);
		this.serverGetSubPartCount(selectedPartID);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onPartNameChanged(value) {
		this.setState({partName: value});
	}
	
	async serverUpdatePartName(partID, partName) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartname?partid=" + partID + "&partname=" + encodeURIComponent(partName);

		const res = await fetch(url);
		await res.text();
	}
		
	onClickSaveButton() {
		
		var partName = this.state.partName;
		if (partName === "") {
			this.props.showToast("Part name cannot be empty");
			return;
		}

		var selectedPartID = this.props.selectedPartID;
		this.serverUpdatePartName(selectedPartID, partName);
		this.props.serverGetPartsTreeData(this.props.selectedProjectID);
		this.props.showToast("Part name saved");
		const navigate = this.props.navigate;
  		navigate("/parts");
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/parts");
	}

	onClickDeleteButton() {

		if (this.state.propertyCount > 0) {
			this.props.showToast(this.props.getLang(PartLang, "partHasProperties"));
			return;
		}

		if (this.state.fileCount > 0) {
			this.props.showToast(this.props.getLang(PartLang, "partHasFiles"));
			return;
		}

		if (this.state.subPartCount > 0) {
			this.props.showToast(this.props.getLang(PartLang, "partHasSubParts"));
			return;
		}

		var partID = this.props.selectedPartID;
		var partName = this.props.selectedPartName;
		
		var messageText = this.props.getLang(PartLang, "deletePartAreYouSure") + " \""+ partName + "\"?"

		confirmAlert({
			title: this.props.getLang(PartLang, "deletePart"),
			message: messageText,
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.serverDeletePartRecord(partID, partName)
				},
				{
					label: 'No',
				}
			]
		});
	}

	async serverGetPropertyCount(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertylist?partid=" + partID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var propertyCount = jsonObj.length;
		this.setState({propertyCount: propertyCount});
	}

	async serverGetFileCount(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartfilelist?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var fileCount = jsonObj.length;
		this.setState({fileCount: fileCount});
	}
	
	async serverGetSubPartCount(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartlist?parentid=" + partID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var subPartCount = jsonObj.length;
		this.setState({subPartCount: subPartCount});
	}

	async serverDeletePartRecord(partID, partName) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletepartrecord?partid=" + partID;

		const res = await fetch(url);
		await res.text();

		this.props.setSelectedPartIDAndName(0, "");	
		this.props.serverGetPartsTreeData(this.props.selectedProjectID);
		this.props.showToast("Part " + partName + " deleted");
		const navigate = this.props.navigate;
  		navigate("/parts");
	}
	
	render() {		

		var selectedPartID = this.props.selectedPartID;
		var selectedPartName = this.props.selectedPartName;

		var productionAppUserType = this.props.productionAppUserType;

		var deletePartYN = false;
		if (productionAppUserType >= 100) {
			deletePartYN = true;
		}

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(PartLang, "updatePart")}</div>
				<div className="ViewTopText">{this.props.getLang(PartLang, "updatePartInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(PartLang, "partInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "10px"}}></div>
						<fieldset className="PartNewFieldset">
							<legend className="PartNewNameLegend">{this.props.getLang(PartLang, "partID")}</legend> 
							<input
								className="PartNewNameInput" 
								id="partID" 
								type="text" 
								defaultValue={selectedPartID}
								disabled={true}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="PartNewFieldset">
							<legend className="PartNewNameLegend">{this.props.getLang(PartLang, "partName")}</legend> 
							<input
								className="PartNewNameInput" 
								id="partName" 
								type="text" 
								autoFocus={true}
								defaultValue={selectedPartName}
								onChange={(event) => this.onPartNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(PartLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(PartLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "30px"}}></div>
				{deletePartYN && (<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(PartLang, "deletePart")}</div>
					<div style={{height: "40px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickDeleteButton}>{this.props.getLang(PartLang, "deletePart")}</button>
					</div>
					<div style={{height: "20px"}}></div>
				</div>)}

				<div style={{height: "50px"}}></div>
			</div>
		);
	}
}

export default function PartUpdateFunction(props) {
	const navigate = useNavigate();
	return <PartUpdate {...props} navigate={navigate} />;
}

