import React  from 'react';
import './App.css';
import ReportsLang from './ReportsLang.json';

import { useNavigate } from "react-router-dom";

class Reports extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
		};

		this.renderOneRow = this.renderOneRow.bind(this);		
		this.handleRowClick = this.handleRowClick.bind(this);				
	}
	
	handleRowClick(page) {
		if (page === "") {
			return;
		}
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderOneRow(title, value, page) {

		var gtString = ">";
		if (page === "") {
			gtString = "";
		}

		return (
				<div className="ViewRowContainer" onClick={() => this.handleRowClick(page)}>
					<div className="ViewRowTitle">{title}</div>
					<div className="ViewRowText">{value}</div>
					<div className="ViewRowGreater">{gtString}</div>
				</div>
		);
	}
	
	render() {
		
		var selectedCompanyName = this.props.selectedCompanyName;

		return (
			<div>

				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(ReportsLang, "reports")}</div>
				<div className="ViewTopText">{this.props.getLang(ReportsLang, "reportsInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(ReportsLang, "reports")}</div>
					<div style={{height: "20px"}}></div>
					{this.renderOneRow(this.props.getLang(ReportsLang, "tasks"), this.props.getLang(ReportsLang, "dailyReport"), "/reports/tasks/daily")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow(this.props.getLang(ReportsLang, "tasks"), this.props.getLang(ReportsLang, "monthlyReport"), "/reports/tasks/monthly")}
					<div style={{height: "10px"}}></div>
				</div>
				<div style={{height: "30px"}}></div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function ReportsFunction(props) {
	const navigate = useNavigate();
	return <Reports {...props} navigate={navigate} />;
}

