import React from 'react';
import './App.css';
import { FaPause } from "react-icons/fa"
import { MdFiberNew, MdDone } from "react-icons/md"
import { BsClockHistory } from "react-icons/bs"
import TaskLang from './TaskLang.json';

import { useNavigate } from "react-router-dom";

import TaskListToolbar from './TaskListToolbar.js';

class TaskList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			searchString: "",
			count: 10,
			taskJsonObj: null,
		};

		this.handleChangeSearchString = this.handleChangeSearchString.bind(this);
		this.handleSelectTask = this.handleSelectTask.bind(this);
		this.getTaskDataFromTaskNameStart = this.getTaskDataFromTaskNameStart.bind(this);		
		this.setMyTasksYN = this.setMyTasksYN.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);	
		this.renderOneTask = this.renderOneTask.bind(this);
		this.renderSearchResults = this.renderSearchResults.bind(this);
	}
	
	componentDidMount() {
		this.getTaskDataFromTaskNameStart(this.state.searchString, this.props.taskListIndex, this.state.count, this.props.myTasksYN);
	}
	
	handleChangeSearchString(event) {
		var searchString = event.target.value;
		this.setState({searchString: searchString});
		this.getTaskDataFromTaskNameStart(searchString, this.props.taskListIndex, this.state.count, this.props.myTasksYN);
	}

	handleSelectTask(taskID) {
		this.props.setSelectedTaskID(taskID);
  		this.props.navigate("/task");
	}

	handlePrev() {
		var index = this.props.taskListIndex - this.state.count;
		this.props.setTaskListIndex(index);
		this.getTaskDataFromTaskNameStart(this.state.searchString, index, this.state.count, this.props.myTasksYN);
	}

	handleNext() {
		var index = this.props.taskListIndex + this.state.count;
		this.props.setTaskListIndex(index);
		this.getTaskDataFromTaskNameStart(this.state.searchString, index, this.state.count, this.props.myTasksYN);
	}
	
	setMyTasksYN(myTasksYN) {
		this.props.setMyTasksYN(myTasksYN);
		this.setState({myTasksYN: myTasksYN});
		this.getTaskDataFromTaskNameStart(this.state.searchString, 0, this.state.count, myTasksYN);
	}
	
	async getTaskDataFromTaskNameStart(searchString, index, count, myTasksYN) {
		
		var userID2 = 0;		
		var userID = this.props.userID;
		var companyID = this.props.companyID;
		
		this.setState({taskJsonObj: null});

		if (myTasksYN) {
			userID2 = userID;
		} else {
			userID2 = 0;
		}

		var baseURLAPI = this.props.baseURLAPI;
		//var url = baseURLAPI + "/gettaskdatafromtasknumberstart?tasknumberstart=" + searchString;
		var url = baseURLAPI + "/gettasklist?userid=" + userID2 + "&companyid=" + companyID;
		url = url + "&index=" + index;
		url = url + "&count=" + count;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			taskJsonObj: jsonObj,
		});
	}
	
	renderOneTask(taskID, taskName, userFullName, notes, status, partID, partName, partPropertyName, partPropertyIntDiff, durationSec) {

		var showNewIconYN = false;
		var showPlayIconYN = false;
		var showPausedIconYN = false;
		var showFinishedIconYN = false;
		
		if (status === "new") {
			showNewIconYN = true;
		}		
		if (status === "started") {
			showPlayIconYN = true;
		}
		if (status === "paused") {
			showPausedIconYN = true;
		}
		if (status === "finished") {
			showFinishedIconYN = true;
		}

		var durationString = "";
		if (durationSec === null) {
			durationString = ""
		} else {
			durationString = this.props.getTimeStringFromSeconds(durationSec);
		}
		
		var notesText = notes.trim();
		if (notesText.length > 50) {
			notesText = notes.substring(0, 50) + "...";
		}
		
		var partPropertyString = "";
		if (partID > 0) {
			partPropertyString = partName + ", " + partPropertyName + " (" + partPropertyIntDiff + ")";
		}
		
		if (notesText !== "") {
			if (partPropertyString === "") {
				partPropertyString = notesText;
			} else {
				partPropertyString = partPropertyString + ", " + notesText;
			}
		}

		return (
			<tr key={taskID}>
				<td
					className="TaskListTaskNameItem"
					onClick={() => this.handleSelectTask(taskID)}
				>
					{taskName}
				</td>
				<td
					className="TaskListTaskExecutorItem"
					onClick={() => this.handleSelectTask(taskID)}
				>
					{userFullName}
				</td>
				<td
					className="TaskListTaskStatusItem"
					onClick={() => this.handleSelectTask(taskID)}
				>
					{showNewIconYN && (
						<MdFiberNew style={{ color: "blue", width: "20px", height: "20px" }} />
					)}
					{showPausedIconYN && (
						<FaPause style={{ color: "grey", width: "20px", height: "20px" }} />
					)}
					{showPlayIconYN && (
						<BsClockHistory style={{ color: "orange", width: "20px", height: "20px" }} />
					)}
					{showFinishedIconYN && (
						<MdDone style={{ color: "green", width: "20px", height: "20px" }} />
					)}
				</td>
				<td
					className="TaskListTaskPartItem"
					onClick={() => this.handleSelectTask(taskID)}
				>
					{partPropertyString}
				</td>
				<td
					className="TaskListTaskDurationItem"
					onClick={() => this.handleSelectTask(taskID)}
				>
					{durationString}
				</td>
			</tr>
		);
	}

	renderAllTasks() {

		var i;
		var taskJsonObj = this.state.taskJsonObj;
		var itemObj;
		var taskID;
		var taskName;
		var userFullName;
		var notes;
		var status;
		var durationSec;
		var partID;
		var partName;
		var partPropertyName;
		var partPropertyIntDiff;
		var jsxArray = [];

		for(i=0; i<taskJsonObj.length; i++) {
			itemObj = taskJsonObj[i];
			taskID = itemObj.taskID;
			taskName = itemObj.taskName;
			userFullName = itemObj.userFullName;
			notes = itemObj.notes;
			status = itemObj.status;
			durationSec = itemObj.durationSec;
			partID = itemObj.partID;
			partName = itemObj.partName;
			partPropertyName = itemObj.partPropertyName;
			partPropertyIntDiff = itemObj.partPropertyIntDiff;
			jsxArray.push(this.renderOneTask(taskID, taskName, userFullName, notes, status, partID, partName, partPropertyName, partPropertyIntDiff, durationSec));
		}

		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="TaskListTaskNameHeader">{this.props.getLang(TaskLang, "taskName")}</td>
						<td className="TaskListTaskExecutorHeader">{this.props.getLang(TaskLang, "executor")}</td>
						<td className="TaskListTaskStatusHeader">{this.props.getLang(TaskLang, "status")}</td>
						<td className="TaskListTaskPartHeader">{this.props.getLang(TaskLang, "partNotes")}</td>
						<td className="TaskListTaskDurationHeader">{this.props.getLang(TaskLang, "duration")}</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	renderSearchResults() {

		var taskJsonObj = this.state.taskJsonObj;		
		if (taskJsonObj === null) {
			return null;
		}

		var taskCount = taskJsonObj.length;
		if (taskCount === 0) {
			return (<div>No tasks found</div>);
		}

		var prevYN = false;
		var nextYN = false;
		
		if (this.props.taskListIndex > 0) {
			prevYN = true;
		}
		
		if (taskCount === this.state.count) {
			nextYN = true;
		}

		return (
			<div>
				{this.renderAllTasks()}
				<div style={{height:"30px"}}></div>
				<div className="ListContainer3">
					{prevYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handlePrev()}
					>
						&lt;&lt; Previous
					</div>)}
					{!prevYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						&lt;&lt; Previous
					</div>)}
					<div className="ListPrevNextLinkDisabled">|</div>
					{nextYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handleNext()}
					>
						Next &gt;&gt;
					</div>)}
					{!nextYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						Next &gt;&gt;
					</div>)}
				</div>
			</div>
		);
	}
	
	render() {

		return (
			<div>
				<TaskListToolbar
					baseURLAPI={this.props.baseURLAPI}
					productionAppUserType={this.props.productionAppUserType}
					myTasksYN={this.props.myTasksYN}
					setRecycleTaskID={this.props.setRecycleTaskID}
					setNewTaskPartAndProperty={this.props.setNewTaskPartAndProperty}
					setMyTasksYN={this.setMyTasksYN}
					setTaskListIndex={this.props.setTaskListIndex}
					getLang={this.props.getLang}
				/>
				<div style={{height:"20px"}}></div>
				{this.renderSearchResults()}
				<div style={{height:"30px"}}></div>
			</div>
		);
	}
}

export default function TaskListFunction(props) {
	const navigate = useNavigate();
	return <TaskList {...props} navigate={navigate} />;
}
