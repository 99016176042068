import React  from 'react';
import './App.css';
import './Part.css';
import PartLang from './PartLang.json';

class PartRightChartCustomTooltip extends React.Component {

	constructor(props) {

		super(props);

		this.renderOneLine = this.renderOneLine.bind(this);
		this.renderAllLines = this.renderAllLines.bind(this);		
	}
	
	renderOneLine(time, line) {
		var name = line.name;
		var color = line.color;
		var value = line.value;
		const divStyle = { color: color };
		return (<div style={divStyle}>{name}: {value}</div>);
	}
	
	renderAllLines(payload) {

		var i;
		var time;
		var line;
		var length = payload.length;
		var jsxArray = [];
		var startUnixTime = this.props.startUnixTime;
		var unixTime;
		var dateObj;
		var dateString;
		var timeString;
		
		time = payload[0].payload.time;
		unixTime = startUnixTime + time * 86400;
		dateObj = new Date(unixTime * 1000);
		dateString = dateObj.getDate() + "." + (dateObj.getMonth() + 1) + "." + dateObj.getFullYear() + ".";
		timeString = dateString;
		jsxArray.push((<div>{timeString}</div>));
		for(i=0; i<length; i++) {
			line = payload[i];
			jsxArray.push(this.renderOneLine(time, line));
		}

		return (<div>{jsxArray}</div>);
	}

	render() {

		var active = this.props.active;
		var payload = this.props.payload;
		
		if (!active) {
			return null;
		}

		if (payload === null) {
			return null;
		}
		
		return (
			<div className="PartRightChartCustomTooltip">
				{this.renderAllLines(payload)}
			</div>
		);
	}
}

export default PartRightChartCustomTooltip;

