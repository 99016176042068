import React  from 'react';
import './App.css';
import './Part.css';
import ReactTooltip from 'react-tooltip';
import { AiOutlineDownload } from "react-icons/ai"
import { MdAddTask } from "react-icons/md"
import { saveAs } from "file-saver";
import PartLang from './PartLang.json';

import { useNavigate } from "react-router-dom";

class PartRightProperties extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			partID: 0,
			partName: "",
			countInit: 0,
			count: 0,
			ownerUserID: 0,
			ownerFullName: "none",
			notes: "",
			propertyIDArray: [],
			propertyNameArray: [],
			propertyTypeArray: [],
			propertyValueArray: [],
			propertyValueInitArray: [],
			propertyCount: -1,
			fileIDArray: [],
			filenameArray: [],
			sizeBytesArray: [],
			googleStorageIDArray: [],
			fileCount: -1,
			logJsonObj: null,
			dataLoadedYN: false,
		};
		
		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.getOwnerFullName = this.getOwnerFullName.bind(this);
		this.serverGetPartPropertyList = this.serverGetPartPropertyList.bind(this);		
		this.serverGetPartFileList = this.serverGetPartFileList.bind(this);		
		this.serverInsertLogRecord = this.serverInsertLogRecord.bind(this);
		this.serverUpdatePartCount = this.serverUpdatePartCount.bind(this);
		this.serverUpdatePartNotes = this.serverUpdatePartNotes.bind(this);
		this.serverUpdatePartPropertyValue = this.serverUpdatePartPropertyValue.bind(this);
		this.serverRunPartPropertyJsCode = this.serverRunPartPropertyJsCode.bind(this);		
		this.serverGetLogList = this.serverGetLogList.bind(this);
		this.onCountChanged = this.onCountChanged.bind(this);
		this.onCountSave = this.onCountSave.bind(this);
		this.onNotesChanged = this.onNotesChanged.bind(this);
		this.onNotesSave = this.onNotesSave.bind(this);
		this.onPropertyChanged = this.onPropertyChanged.bind(this);
		this.onPropertySave = this.onPropertySave.bind(this);
		this.onClickOwner = this.onClickOwner.bind(this);
		this.renderOneProperty = this.renderOneProperty.bind(this);
		this.renderAllProperties = this.renderAllProperties.bind(this);
		this.renderLogLine = this.renderLogLine.bind(this);
		this.renderAllLogs = this.renderAllLogs.bind(this);
		this.getDisplayStringFromBytes = this.getDisplayStringFromBytes.bind(this);
		this.handleClickDownload = this.handleClickDownload.bind(this);
		this.renderOneFile = this.renderOneFile.bind(this);
		this.renderAllFiles = this.renderAllFiles.bind(this);
		this.formatDateString = this.formatDateString.bind(this);
		this.formatTimeString = this.formatTimeString.bind(this);
		this.handleCreateTask = this.handleCreateTask.bind(this);
		this.renderOwner = this.renderOwner.bind(this);
	}
	
	componentDidMount() {
		var selectedPartID = this.props.selectedPartID;
		this.setState({partID: selectedPartID});
		this.serverGetPartData(selectedPartID);
		this.serverGetPartPropertyList(selectedPartID);
		this.serverGetPartFileList(selectedPartID);
		this.serverGetLogList(selectedPartID);
	}
	
	componentDidUpdate(prevProps) {

		var selectedPartID = this.props.selectedPartID;
		var partID = this.state.partID;
		
		if (partID === selectedPartID) {
			return;
		}
		
		this.setState({
			partID: selectedPartID,
			ownerFullName: "none",
			dataLoadedYN: false,
		});

		this.serverGetPartData(selectedPartID);
		this.serverGetPartPropertyList(selectedPartID);
		this.serverGetPartFileList(selectedPartID);
		this.serverGetLogList(selectedPartID);
	}

	async serverGetPartData(partID) {

		if (partID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var partName = jsonObj.partName;
		var count = jsonObj.count;
		var ownerUserID = jsonObj.ownerUserID;
		var notes = jsonObj.notes;

		this.setState({
			partName: partName,
			countInit: count,
			count: count,
			ownerUserID: ownerUserID,
			notes: notes,
		});

		this.getOwnerFullName(ownerUserID);
		this.props.setOwnerUserID(ownerUserID);
	}

	async getOwnerFullName(ownerUserID) {

		if (ownerUserID === null) {
			return;
		}

		if (ownerUserID <= 0) {
			return;
		}

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdata?userid=" + ownerUserID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var email = jsonObj.email;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;

		var ownerFullName = firstName + " " + lastName;
		ownerFullName = ownerFullName.trim();

		if (ownerFullName === "") {
			ownerFullName = email;
		}

		this.setState({
			ownerFullName: ownerFullName,
		});
	}
		
	async serverGetPartPropertyList(partID) {

		if (partID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertylist?partid=" + partID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var i;
		var itemObj;
		var propertyID;
		var propertyName;
		var propertyType;
		var propertyValue;
		var propertyIDArray = [];
		var propertyNameArray = [];
		var propertyTypeArray = [];
		var propertyValueArray = [];
		var propertyValueInitArray = [];
		var propertyCount = jsonObj.length;
		
		for(i=0; i<propertyCount; i++) {

			itemObj = jsonObj[i];
			propertyID = itemObj.partPropertyID;
			propertyName = itemObj.propertyName;
			propertyType = itemObj.propertyType;
			
			propertyValue = "";
			if (propertyType === "int") {
				propertyValue = itemObj.intValue;
			}
			if (propertyType === "float") {
				propertyValue = itemObj.floatValue;
			}
			if (propertyType === "text") {
				propertyValue = itemObj.textValue;
			}
			
			propertyIDArray.push(propertyID);
			propertyNameArray.push(propertyName);
			propertyTypeArray.push(propertyType);
			propertyValueArray.push(propertyValue);
			propertyValueInitArray.push(propertyValue);
		}

		this.setState({
			propertyIDArray: propertyIDArray,
			propertyNameArray: propertyNameArray,
			propertyTypeArray: propertyTypeArray,
			propertyValueArray: propertyValueArray,
			propertyValueInitArray: propertyValueInitArray,
			propertyCount: propertyCount,
			dataLoadedYN: true,
		});
	}
	
	async serverGetPartFileList(partID) {

		if (partID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartfilelist?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var i;
		var itemObj;

		var fileID;
		var filename;
		var sizeBytes;
		var googleStorageID;
		var fileIDArray = [];
		var filenameArray = [];
		var sizeBytesArray = [];
		var googleStorageIDArray = [];
		var fileCount = jsonObj.length;
		
		for(i=0; i<fileCount; i++) {

			itemObj = jsonObj[i];
			fileID = itemObj.partFileID;
			filename = itemObj.filename;
			sizeBytes = itemObj.sizeBytes;
			googleStorageID = itemObj.googleStorageID;
			
			fileIDArray.push(fileID);
			filenameArray.push(filename);
			sizeBytesArray.push(sizeBytes);
			googleStorageIDArray.push(googleStorageID);
		}

		this.setState({
			fileIDArray: fileIDArray,
			filenameArray: filenameArray,
			sizeBytesArray: sizeBytesArray,
			googleStorageIDArray: googleStorageIDArray,
			fileCount: fileCount,
		});
	}
		
	async serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, projectID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertlogrecord?userid=" + userID;
		url = url + "&email=" + encodeURIComponent(email);
		url = url + "&fullname=" + encodeURIComponent(fullName);
		url = url + "&partid=" + partID;
		url = url + "&partname=" + encodeURIComponent(partName);
		url = url + "&propertyid=" + propertyID;
		url = url + "&propertyname=" + encodeURIComponent(propertyName);
		url = url + "&valueold=" + encodeURIComponent(valueOld);
		url = url + "&valuenew=" + encodeURIComponent(valueNew);
		url = url + "&projectid=" + projectID;

		const res = await fetch(url);
		await res.text();
	}
		
	async serverUpdatePartCount(partID, count, diff) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartcount?partid=" + partID + "&count=" + count;

		const res = await fetch(url);
		await res.text();

		this.serverRunPartPropertyJsCode(partID, 0, diff);
	}

	async serverUpdatePartNotes(partID, notes) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartnotes?partid=" + partID + "&notes=" + encodeURIComponent(notes);

		const res = await fetch(url);
		await res.text();
	}
		
	async serverUpdatePartPropertyValue(partID, partPropertyID, propertyType, propertyValue, diff) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartpropertyvalue?partpropertyid=" + partPropertyID;
		url = url + "&propertytype=" + propertyType;
		url = url + "&propertyvalue=" + encodeURIComponent(propertyValue);		

		const res = await fetch(url);
		await res.text();
		
		this.serverRunPartPropertyJsCode(partID, partPropertyID, diff);
	}
	
	async serverRunPartPropertyJsCode(partID, partPropertyID, diff) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/runpartpropertyjscode?partid=" + partID;
		url = url + "&partpropertyid=" + partPropertyID;
		url = url + "&diff=" + diff;
		
		const res = await fetch(url);
		await res.text();

		this.serverGetPartPropertyList(partID);
	}
	
	async serverGetLogList(partID) {

		var index = 0;
		var count = 10;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getloglistforpartid?partid=" + partID;
		url = url + "&sortby=timestampCreated&sortasc=0";
		url = url + "&index=" + index + "&count=" + count;

		const res = await fetch(url);
		const logJsonObj = await res.json();

		this.setState({
			logJsonObj: logJsonObj,
		});
	}
		
	onCountChanged(value) {
		this.setState({count: value});
	}
	
	onCountSave(value) {
		
		var count = parseInt(value);
		if (this.state.count === count) {
			return;
		}

		var userID = this.props.userID;
		var email = this.props.email;
		var firstName = this.props.firstName;
		var lastName = this.props.lastName;
		var partID = this.state.partID;
		var partName = this.state.partName;
		var propertyID = 0;
		var propertyName = "count";
		var valueOld = parseInt(this.state.countInit);
		var valueNew = count;
		var selectedProjectID = this.props.selectedProjectID;

		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		
		var diff = count - valueOld;
		
		this.serverUpdatePartCount(partID, count, diff)
		this.serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, selectedProjectID)

		this.setState({
			countInit: count,
			count: count
		});
	}
	
	onNotesChanged(value) {
		this.setState({notes: value});
	}
	
	onNotesSave(value) {
		
		var partID = this.state.partID;

		var notes = value.toString();
		if (notes.length > 1000) {
			notes = notes.substr(1, 1000);
		}

		if (notes === "") {
			notes = " ";
		}

		this.serverUpdatePartNotes(partID, notes)
		this.setState({notes: notes});
	}
	
	onPropertyChanged(propertyID, value) {

		var i;
		var propertyCount = this.state.propertyCount;
		var propertyID2;
		var propertyValue;
		var propertyValueArray = [];
		
		for(i=0; i<propertyCount; i++) {
			propertyID2 = this.state.propertyIDArray[i];
			propertyValue = this.state.propertyValueArray[i];
			if (propertyID === propertyID2) {
				propertyValue = value;
			}
			propertyValueArray.push(propertyValue);
		}

		this.setState({
			propertyValueArray: propertyValueArray,
		});
	}
	
	onPropertySave(propertyID, propertyName, propertyType, valueOld, valueNew) {

		var propertyValue = valueNew;
		if (propertyType === "int") {
			propertyValue = parseInt(valueNew);
		}
		if (propertyType === "float") {
			propertyValue = parseFloat(valueNew);
		}

		if (valueOld === propertyValue) {
			return;
		}

		var userID = this.props.userID;
		var email = this.props.email;
		var firstName = this.props.firstName;
		var lastName = this.props.lastName;
		var partID = this.state.partID;
		var partName = this.state.partName;
		var selectedProjectID = this.props.selectedProjectID;

		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		
		var diff = propertyValue - valueOld;

		this.serverUpdatePartPropertyValue(partID, propertyID, propertyType, propertyValue, diff);
		this.serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, selectedProjectID)
	}

	onClickOwner() {
		this.props.setPartRightPage("selectowner");
	}
	
	handleCreateTask(propertyID, propertyName) {
		var partID = this.state.partID;
		var partName = this.state.partName;
		this.props.setRecycleTaskID(0);
		this.props.setNewTaskPartAndProperty(partID, partName, propertyID, propertyName);
  		this.props.navigate("/tasks/new");
	}

	renderOneProperty(propertyID, propertyName, propertyType, propertyValueInit, propertyValue) {
		
		var inputType = "text";
		if (propertyType === "int") {
			inputType = "number";
		}
		if (propertyType === "float") {
			inputType = "number";
		}
		
		var dataTip = this.props.getLang(PartLang, "createTaskForProperty") + " \"" + propertyName + "\"";
		
		return (
			<div className="PartRightRowCountProperty" key={propertyID}>
				<div className="PartRightText">{propertyName}:</div>
				<input
					className="PartRightInput" 
					id="property" 
					type={inputType} 
					value={propertyValue}
					onChange={(event) => this.onPropertyChanged(propertyID, event.target.value)}
					onBlur={(event) => this.onPropertySave(propertyID, propertyName, propertyType, propertyValueInit, event.target.value)}
				/>
				<div 
					className="PartRightIcon"
					onClick={() => this.handleCreateTask(propertyID, propertyName)}
					data-tip={dataTip}
				>
					<MdAddTask style={{ color: "green", width: "25px", height: "25px" }} />
				</div>				
			</div>
		);
	}

	renderAllProperties() {

		var i;
		var propertyCount = this.state.propertyCount;
		var propertyID;
		var propertyName;
		var propertyType;
		var propertyValue;
		var propertyValueInit;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<propertyCount; i++) {
			propertyID = this.state.propertyIDArray[i];
			propertyName = this.state.propertyNameArray[i];
			propertyType = this.state.propertyTypeArray[i];
			propertyValue = this.state.propertyValueArray[i];
			propertyValueInit = this.state.propertyValueInitArray[i];
			jsxCode = this.renderOneProperty(propertyID, propertyName, propertyType, propertyValueInit, propertyValue);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	renderLogLine(logID, email, fullName, partName, propertyName, valueOld, valueNew, timestampCreated) {

		var dateString = this.formatDateString(timestampCreated);		
		var timeString = this.formatTimeString(timestampCreated);
		var valueString = valueOld + " --> " + valueNew;
		
		if (propertyName === "count") {
			propertyName = "Count";
		}

		var nameOrEmailString = fullName;
		if (nameOrEmailString === "") {
			nameOrEmailString = email;
		}
		if (nameOrEmailString === null) {
			nameOrEmailString = email;
		}

		return (
			<div key={logID}>
				<div className="ListRow">
					<div className="ChangeLogListDateString">{dateString}</div>
					<div className="ChangeLogListTimeString">{timeString}</div>
					<div className="ChangeLogListPropertyName">{propertyName}</div>
					<div className="ChangeLogListValueString">{valueString}</div>
					<div className="ChangeLogListEmail">{nameOrEmailString}</div>
				</div>
			</div>
		);
	}

	renderAllLogs() {

		var jsonObj = this.state.logJsonObj;

		if (jsonObj === null) {
			return null;
		}

		if (jsonObj.length === 0) {
			return null;
		}

		return (
			<div>
				<br/>
				<hr/>
				<div className="ListContainer2">
					{jsonObj.map((log) => (
						<div key={log.logID}>{this.renderLogLine(log.logID, log.email, log.fullName, log.partName, log.propertyName, log.valueOld, log.valueNew, log.timestampCreated)}</div>
					))}
					<p>&nbsp;</p>
				</div>
			</div>
		);
	}

	getDisplayStringFromBytes(sizeBytes) {
		
		var displayString = "";
		
		var GBytes = sizeBytes / (1024*1024*1024);
		if (GBytes > 1.0) {
			GBytes = Math.floor(GBytes);
			displayString = GBytes.toString() + " GB";
			return displayString;
		}

		var MBytes = sizeBytes / (1024*1024);
		if (MBytes > 1.0) {
			MBytes = Math.floor(MBytes);
			displayString = MBytes.toString() + " MB";
			return displayString;
		}

		var KBytes = sizeBytes / (1024);
		if (KBytes > 1.0) {
			KBytes = Math.floor(KBytes);
			displayString = KBytes.toString() + " KB";
			return displayString;
		}

		displayString = sizeBytes.toString() + " bytes";
		return displayString;
	}
	
	handleClickDownload(filename, googleStorageID) {
		var url = "https://storage.googleapis.com/plurato-production-files/" + googleStorageID;
		saveAs(url, filename);
	}
		
	renderOneFile(fileID, filename, sizeBytes, googleStorageID) {
		
		var sizeString = this.getDisplayStringFromBytes(sizeBytes);

		return (
			<div className="PartRightRow" key={fileID}>
				<div className="PartRightFilesLink1" onClick={() => this.handleClickDownload(filename, googleStorageID)}>
					{filename}
				</div>
				<div className="PartRightText">{sizeString}</div>
				<div className="PartRightFilesIcon">
					<div className="PartRightFilesLink2" onClick={() => this.handleClickDownload(filename, googleStorageID)}>
						<AiOutlineDownload style={{ width: "25px", height: "25px" }} />
					</div>
				</div>
			</div>
		);
	}
	
	renderAllFiles() {

		var i;
		var fileCount = this.state.fileCount;
		var fileID;
		var filename;
		var sizeBytes;
		var googleStorageID;
		var jsxCode;
		var jsxArray = [];
		
		if (fileCount === 0) {
			return null;
		}
		
		jsxCode = (<hr key={10}/>);
		jsxArray.push(jsxCode);
		
		for(i=0; i<fileCount; i++) {
			fileID = this.state.fileIDArray[i];
			filename = this.state.filenameArray[i];
			sizeBytes = this.state.sizeBytesArray[i];
			googleStorageID = this.state.googleStorageIDArray[i];
			jsxCode = this.renderOneFile(fileID, filename, sizeBytes, googleStorageID);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	formatDateString(timestamp) {
		var dateString = timestamp.substr(0, 10);
		return dateString;
	}

	formatTimeString(timestamp) {
		var timeString = timestamp.substr(11, 5);
		return timeString;
	}
	
	renderOwner() {

		var productionAppUserType = this.props.productionAppUserType;
		var userID = this.props.userID;
		var ownerUserID = this.state.ownerUserID;

		var ownerYN = false;
		if (productionAppUserType >= 100) {
			ownerYN = true;
		}
		if (userID === ownerUserID) {
			ownerYN = true;
		}

		if (ownerYN) {
			return (
				<div
					className="PartRightOwnerLink"
					onClick={(event) => this.onClickOwner()}
				>
					{this.state.ownerFullName}
				</div>
			);
		}

		if (!ownerYN) {
			return (
				<div className="PartRightOwnerText">
					{this.state.ownerFullName}
				</div>
			);
		}
	}

	render() {
		
		if (!this.state.dataLoadedYN) {
			return null;
		}

		if (this.props.selectedPartID <= 0) {
			return null;
		}
		
		var partName = this.state.partName;

		return (
			<div>
				<ReactTooltip
					type="info"
					delayShow={500}
				/>
				<div className="PartRightName">{partName}</div>
				<div className="PartRightContainer">
					<div className="PartRightRowCountProperty">
						<div className="PartRightText">{this.props.getLang(PartLang, "count")}:</div>
						<input
							className="PartRightInput" 
							id="count" 
							type="number" 
							value={this.state.count}
							onChange={(event) => this.onCountChanged(event.target.value)}
							onBlur={(event) => this.onCountSave(event.target.value)}
						/>
						<div 
							className="PartRightIcon"
							onClick={() => this.handleCreateTask(0, "Count")}
							data-tip={this.props.getLang(PartLang, "createTaskForCount")}
						>
							<MdAddTask style={{ color: "green", width: "25px", height: "25px" }} />
						</div>				
					</div>
					{this.renderAllProperties()}
					<div className="PartRightRow">
						<div className="PartRightText">{this.props.getLang(PartLang, "owner")}:</div>
						{this.renderOwner()}
					</div>
					<div className="PartRightRow">
						<div className="PartRightText">{this.props.getLang(PartLang, "notes")}:</div>
						<textarea
							className="PartRightInput" 
							id="notes" 
							value={this.state.notes}
							rows={10}
							onChange={(event) => this.onNotesChanged(event.target.value)}
							onBlur={(event) => this.onNotesSave(event.target.value)}
						/>
					</div>
				</div>
				{this.renderAllFiles()}
				{this.renderAllLogs()}
			</div>
		);
	}
}

export default function PartRightPropertiesFunction(props) {
	const navigate = useNavigate();
	return <PartRightProperties {...props} navigate={navigate} />;
}

