import React  from 'react';
import './App.css';
import './Part.css';
import PartLang from './PartLang.json';

import { useNavigate } from "react-router-dom";

class PropertyTriggerToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		
		if (page === "newblock") {
			this.props.createNewBlock();
			return;
		}
	}
	
	renderLink(linkTitle, linkValue) {

		var classNameString = "PropertyTriggerToolbarLink";
		var partRightPage = this.props.partRightPage;
		
		if (linkValue === partRightPage) {
			classNameString = "PropertyTriggerToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var partName = this.props.partName;
		var propertyName = this.props.propertyName;

		var productionAppUserType = this.props.productionAppUserType;

		var showOwnerToolbarYN = false;
		if (productionAppUserType >= 100) {
			showOwnerToolbarYN = true;
		}
		
		var newBlockString = this.props.getLang(PartLang, "newBlock");
		var editTriggerEventString = this.props.getLang(PartLang, "editTriggerEvent");

		return (
			<div className="PropertyTriggerToolbarContainer1">
				<div className="PropertyTriggerToolbarLeft">
					<div className="ChangeLogToolbarText">
						{partName} / {propertyName} - {editTriggerEventString}
					</div>
				</div>
				<div className="PropertyTriggerToolbarRight">
					<div className="PropertyTriggerToolbarContainer2">
						{this.renderLink(newBlockString, "newblock")} 
					</div>
				</div>
			</div>
		);
	}
}

export default function PropertyTriggerToolbarFunction(props) {
	const navigate = useNavigate();
	return <PropertyTriggerToolbar {...props} navigate={navigate} />;
}

