import React  from 'react';
import './App.css';
import './Part.css';
import { AiOutlineDownload } from "react-icons/ai"
import { AiOutlineDelete } from "react-icons/ai"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { saveAs } from "file-saver";
import PartLang from './PartLang.json';

import Dropzone from 'react-dropzone';

class PartRightFiles extends React.Component {

	constructor() {

		super();

		this.state = {
			partID: 0,
			partName: "",
			fileIDArray: [],
			filenameArray: [],
			sizeBytesArray: [],
			googleStorageIDArray: [],
			fileCount: -1,
			files: [],
			filesUploadedCount: 0,
			uploadInProgressYN: false,
			dataLoadedYN: false,
		};

		this.onDrop = (files) => {
			this.setState({files})
		};

		this.serverGetPartFileList = this.serverGetPartFileList.bind(this);
		this.serverDeletePartFileRecord = this.serverDeletePartFileRecord.bind(this);
		this.serverInsertLogRecord = this.serverInsertLogRecord.bind(this);
		this.handleClickDownload = this.handleClickDownload.bind(this);
		this.handleClickDelete = this.handleClickDelete.bind(this);
		this.getDisplayStringFromBytes = this.getDisplayStringFromBytes.bind(this);
		this.renderOneFile = this.renderOneFile.bind(this);
		this.renderAllFiles = this.renderAllFiles.bind(this);
		this.finishedFileUpload = this.finishedFileUpload.bind(this);
		this.uploadOneFile = this.uploadOneFile.bind(this);
		this.onClickUploadButton = this.onClickUploadButton.bind(this);
	}
	
	componentDidMount() {
		var selectedPartID = this.props.selectedPartID;
		this.setState({partID: selectedPartID});
		this.serverGetPartData(selectedPartID);
		this.serverGetPartFileList(selectedPartID);
		this.setState({files: []});
	}
	
	componentDidUpdate(prevProps) {

		var selectedPartID = this.props.selectedPartID;
		var partID = this.state.partID;
		
		if (partID === selectedPartID) {
			return;
		}
		
		this.setState({
			partID: selectedPartID,
			dataLoadedYN: false,
		});

		this.serverGetPartData(selectedPartID);
		this.serverGetPartFileList(selectedPartID);
		this.setState({files: []});
	}

	async serverGetPartData(partID) {

		if (partID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var partName = jsonObj.partName;

		this.setState({
			partName: partName,
		});
	}
		
	async serverGetPartFileList(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartfilelist?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var i;
		var itemObj;

		var fileID;
		var filename;
		var sizeBytes;
		var googleStorageID;
		var fileIDArray = [];
		var filenameArray = [];
		var sizeBytesArray = [];
		var googleStorageIDArray = [];
		var fileCount = jsonObj.length;
		
		for(i=0; i<fileCount; i++) {

			itemObj = jsonObj[i];
			fileID = itemObj.partFileID;
			filename = itemObj.filename;
			sizeBytes = itemObj.sizeBytes;
			googleStorageID = itemObj.googleStorageID;
			
			fileIDArray.push(fileID);
			filenameArray.push(filename);
			sizeBytesArray.push(sizeBytes);
			googleStorageIDArray.push(googleStorageID);
		}

		this.setState({
			fileIDArray: fileIDArray,
			filenameArray: filenameArray,
			sizeBytesArray: sizeBytesArray,
			googleStorageIDArray: googleStorageIDArray,
			fileCount: fileCount,
			dataLoadedYN: true,
		});
	}
	
	async serverDeletePartFileRecord(fileID, filename, googleStorageID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletepartfilerecord?partfileid=" + fileID;
		url = url + "&googlestorageid=" + encodeURIComponent(googleStorageID);
		
		const res = await fetch(url);
		await res.text();

		this.serverGetPartFileList(this.state.partID);

		var userID = this.props.userID;
		var email = this.props.email;
		var firstName = this.props.firstName;
		var lastName = this.props.lastName;
		var partID = this.state.partID;
		var partName = this.props.selectedPartName;
		var propertyID = 0;
		var propertyName = "delete file";
		var valueOld = filename;
		var valueNew = "";
		var selectedProjectID = this.props.selectedProjectID;

		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();

		this.serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, selectedProjectID)
	}
	
	async serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, projectID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertlogrecord?userid=" + userID;
		url = url + "&email=" + encodeURIComponent(email);
		url = url + "&fullname=" + encodeURIComponent(fullName);
		url = url + "&partid=" + partID;
		url = url + "&partname=" + encodeURIComponent(partName);
		url = url + "&propertyid=" + propertyID;
		url = url + "&propertyname=" + encodeURIComponent(propertyName);
		url = url + "&valueold=" + encodeURIComponent(valueOld);
		url = url + "&valuenew=" + encodeURIComponent(valueNew);
		url = url + "&projectid=" + projectID;

		const res = await fetch(url);
		await res.text();
	}
		
	handleClickDownload(filename, googleStorageID) {

		var url = "https://storage.googleapis.com/plurato-production-files/" + googleStorageID;
		//const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		//if (newWindow) newWindow.opener = null

		saveAs(url, filename);
	}
		
	handleClickDelete(fileID, filename, googleStorageID) {
		
		confirmAlert({
			title: 'Delete file',
			message: 'Are you sure you want to delete file ' + filename + ' ?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.serverDeletePartFileRecord(fileID, filename, googleStorageID)
				},
				{
					label: 'No',
				}
			]
		});
		
	}
		
	getDisplayStringFromBytes(sizeBytes) {
		
		var displayString = "";
		
		var GBytes = sizeBytes / (1024*1024*1024);
		if (GBytes > 1.0) {
			GBytes = Math.floor(GBytes);
			displayString = GBytes.toString() + " GB";
			return displayString;
		}

		var MBytes = sizeBytes / (1024*1024);
		if (MBytes > 1.0) {
			MBytes = Math.floor(MBytes);
			displayString = MBytes.toString() + " MB";
			return displayString;
		}

		var KBytes = sizeBytes / (1024);
		if (KBytes > 1.0) {
			KBytes = Math.floor(KBytes);
			displayString = KBytes.toString() + " KB";
			return displayString;
		}

		displayString = sizeBytes.toString() + " bytes";
		return displayString;
	}
	
	renderOneFile(fileID, filename, sizeBytes, googleStorageID) {
		
		var sizeString = this.getDisplayStringFromBytes(sizeBytes);

		return (
			<div className="PartRightRow" key={fileID}>
				<div className="PartRightFilesLink1" onClick={() => this.handleClickDownload(filename, googleStorageID)}>
					{filename}
				</div>
				<div className="PartRightText">{sizeString}</div>
				<div className="PartRightFilesIcon">
					<div className="PartRightFilesLink2" onClick={() => this.handleClickDelete(fileID, filename, googleStorageID)}>
						<AiOutlineDelete style={{ width: "25px", height: "25px" }} />
					</div>
				</div>
				<div className="PartRightFilesIcon">
					<div className="PartRightFilesLink2" onClick={() => this.handleClickDownload(filename, googleStorageID)}>
						<AiOutlineDownload style={{ width: "25px", height: "25px" }} />
					</div>
				</div>
			</div>
		);
	}
	
	renderAllFiles() {

		var i;
		var fileCount = this.state.fileCount;
		var fileID;
		var filename;
		var sizeBytes;
		var googleStorageID;
		var jsxCode;
		var jsxArray = [];
		
		if (fileCount === 0) {
			return null;
		}
		
		for(i=0; i<fileCount; i++) {
			fileID = this.state.fileIDArray[i];
			filename = this.state.filenameArray[i];
			sizeBytes = this.state.sizeBytesArray[i];
			googleStorageID = this.state.googleStorageIDArray[i];
			jsxCode = this.renderOneFile(fileID, filename, sizeBytes, googleStorageID);
			jsxArray.push(jsxCode);
		}
		
		jsxCode = (<hr key={10}/>);
		jsxArray.push(jsxCode);
		
		return jsxArray;
	}

	finishedFileUpload() {

		var filesUploadedCount = this.state.filesUploadedCount + 1;
		this.setState({filesUploadedCount: filesUploadedCount});

		if (filesUploadedCount >= this.state.files.length) {
			this.serverGetPartFileList(this.state.partID);
			this.setState({
				files: [],
				uploadInProgressYN: false,
			});
		}
	}

	async uploadOneFile(file) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var userID = this.props.userID;
		var selectedPartID = this.props.selectedPartID;
		var filename = file.name;
		var sizeBytes = file.size;

		var url = baseURLAPI + "/uploadfile?userid=" + userID;
		url = url + "&partid=" + selectedPartID;
		url = url + "&filename=" + encodeURIComponent(filename);
		url = url + "&sizebytes=" + sizeBytes;
		
		const formData = new FormData()
		formData.append('file', file)

		fetch(url, {
			method: 'POST',
			body: formData
		}).then(() => this.finishedFileUpload())

		var email = this.props.email;
		var firstName = this.props.firstName;
		var lastName = this.props.lastName;
		var partID = this.state.partID;
		var partName = this.props.selectedPartName;
		var propertyID = 0;
		var propertyName = "upload file";
		var valueOld = "";
		var valueNew = filename;
		var selectedProjectID = this.props.selectedProjectID;

		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		
		this.serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, selectedProjectID)
	}
	
	onClickUploadButton() {
		
		var i;
		var file;
		var files = this.state.files;

		this.setState({
			filesUploadedCount: 0,
			uploadInProgressYN: true,
		});
		
		for(i=0; i<files.length; i++) {
			file = files[i];
			this.uploadOneFile(file);
		}
	}
	
	render() {

		const files = this.state.files.map(file => (
		  <li key={file.name}>
			{file.name} - {file.size} bytes
		  </li>
		));
		
		var selectedPartID = this.props.selectedPartID;
		var partID = this.state.partID;
		var uploadInProgressYN = this.state.uploadInProgressYN;

		if (!this.state.dataLoadedYN) {
			return null;
		}

		if (selectedPartID <= 0) {
			return null;
		}
		
		var showUploadButtonYN = false;
		if (files.length > 0) {
			showUploadButtonYN = true;
		}

		if (uploadInProgressYN) {
			showUploadButtonYN = false;
		}

		var filesCount = this.state.files.length;
		var filesUploadedCount = this.state.filesUploadedCount;
		var partName = this.state.partName;

		return (
			<div>
				<div className="PartRightName">{partName}</div>
				<div className="PartRightFilesContainer1">
					<div style={{height: "10px"}}></div>
					{this.renderAllFiles()}
					<div style={{height: "15px"}}></div>
					<Dropzone onDrop={this.onDrop}>
					{({getRootProps, getInputProps}) => (
					  <section className="container">
						<div {...getRootProps({className: 'dropzone'})}>
						  <input {...getInputProps()} />
						  <div className="PartRightFilesDragContainer">{this.props.getLang(PartLang, "dragNDropMessage")}</div>
						</div>
						<div style={{height: "15px"}}></div>
						{showUploadButtonYN && (
							<aside>
							  <h4>{this.props.getLang(PartLang, "filesToUpload")}</h4>
							  <ul>{files}</ul>
							</aside>)}
					  </section>
					)}
					</Dropzone>
					{uploadInProgressYN && (
						<div>
							<div style={{height: "20px"}}></div>
							<div className="ViewContainerButtons">
								<div style={{width: "10px"}}></div>
								upload in progress: {filesUploadedCount} / {filesCount}
							</div>
						</div>)}
					{showUploadButtonYN && (
						<div>
							<div style={{height: "20px"}}></div>
							<div className="ViewContainerButtons">
								<div style={{width: "10px"}}></div>
								<button className="ViewSaveButton" onClick={this.onClickUploadButton}>{this.props.getLang(PartLang, "uploadFiles")}</button>
							</div>
						</div>)}
					<div style={{height: "50px"}}></div>
				</div>
			</div>
		);
	}
}

export default PartRightFiles;

