import React  from 'react';
import './App.css';
import './Part.css';
import PartLang from './PartLang.json';

import { useNavigate } from "react-router-dom";

class PartMainToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		
		if (page === "properties") {
			this.props.setPartRightPage("properties");
			return;
		}
		
		if (page === "files") {
			this.props.setPartRightPage("files");
			return;
		}
		
		if (page === "chart") {
			this.props.setPartRightPage("chart");
			return;
		}
		
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(linkTitle, linkValue) {

		var classNameString = "PartMainToolbarLink";
		var partRightPage = this.props.partRightPage;
		
		if (linkValue === partRightPage) {
			classNameString = "PartMainToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var selectedProjectID = this.props.selectedProjectID;
		var selectedProjectName = this.props.selectedProjectName;

		var productionAppUserType = this.props.productionAppUserType;

		var showOwnerToolbarYN = false;
		if (productionAppUserType >= 100) {
			showOwnerToolbarYN = true;
		}
		
		var userID = this.props.userID;
		var ownerUserID = this.props.ownerUserID;
		
		if (userID === ownerUserID) {
			showOwnerToolbarYN = true;
		}
		
		var propertiesString = this.props.getLang(PartLang, "properties");
		var filesString = this.props.getLang(PartLang, "files");
		var chartString = this.props.getLang(PartLang, "chart");
		var newPartString = this.props.getLang(PartLang, "newPart");
		var updatePartString = this.props.getLang(PartLang, "updatePart");
		var editPropertiesString = this.props.getLang(PartLang, "editProperties");

		return (
			<div className="PartMainToolbarContainer1">
				<div className="PartMainToolbarLeft">
					<div 
						className="ChangeLogToolbarLink"
						onClick={() => this.handleSelectLink("/selectproject")}
					>
						{selectedProjectName}
					</div>
				</div>
				<div className="PartMainToolbarRight">
					{!showOwnerToolbarYN && (
						<div className="PartMainToolbarContainer2">
							{this.renderLink(propertiesString, "properties")} 
							<div className="PartMainToolbarText">|</div>
							{this.renderLink(filesString, "files")} 
							<div className="PartMainToolbarText">|</div>
							{this.renderLink(chartString, "chart")} 
						</div>
					)}
					{showOwnerToolbarYN && (
						<div className="PartMainToolbarContainer2">
							{this.renderLink(propertiesString, "properties")} 
							<div className="PartMainToolbarText">|</div>
							{this.renderLink(filesString, "files")} 
							<div className="PartMainToolbarText">|</div>
							{this.renderLink(chartString, "chart")} 
							<div className="PartMainToolbarText">|</div>
							{this.renderLink(newPartString, "/setup/parts/new")} 
							<div className="PartMainToolbarText">|</div>
							{this.renderLink(updatePartString, "/setup/parts/update")} 
							<div className="PartMainToolbarText">|</div>
							{this.renderLink(editPropertiesString, "/setup/properties")}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default function PartMainToolbarFunction(props) {
	const navigate = useNavigate();
	return <PartMainToolbar {...props} navigate={navigate} />;
}

