import React  from 'react';
import './App.css';
import './Part.css';
import PartLang from './PartLang.json';

import PartMainToolbar from './PartMainToolbar.js';
import PartLeft from './PartLeft.js';
import PartRightProperties from './PartRightProperties.js';
import PartRightFiles from './PartRightFiles.js';
import PartRightChart from './PartRightChart.js';
import PartRightSelectOwner from './PartRightSelectOwner.js';

class PartMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			ownerUserID: 0,
			partRightPage: "properties",
		};
				
		this.setOwnerUserID = this.setOwnerUserID.bind(this);
		this.setPartRightPage = this.setPartRightPage.bind(this);
	}
	
	setOwnerUserID(ownerUserID) {
		this.setState({ownerUserID: ownerUserID});
	}
	
	setPartRightPage(page) {
		this.setState({partRightPage: page});
	}
	
	render() {
		
		var treeLoadCount = this.props.treeLoadCount;
		if (treeLoadCount > 0) {
			return null;
		}
		
		var showPropertiesYN = false;
		var showFilesYN = false;
		var showChartYN = false;
		var showSelectOwnerYN = false;
		var partRightPage = this.state.partRightPage;

		if (partRightPage === "properties") {
			showPropertiesYN = true;
		}
		
		if (partRightPage === "files") {
			showFilesYN = true;
		}
		
		if (partRightPage === "chart") {
			showChartYN = true;
		}
		
		if (partRightPage === "selectowner") {
			showSelectOwnerYN = true;
		}

		return (
			<div>
				<PartMainToolbar
					userID={this.props.userID}
					ownerUserID={this.state.ownerUserID}
					productionAppUserType={this.props.productionAppUserType}
					selectedProjectID={this.props.selectedProjectID}
					selectedProjectName={this.props.selectedProjectName}
					partRightPage={this.state.partRightPage}
					setPartRightPage={this.setPartRightPage}
					getLang={this.props.getLang}
				/>
				<div className="PartMainContainer">
					<div className="PartMainLeft">
						<PartLeft
							baseURLAPI={this.props.baseURLAPI}
							partTreeData={this.props.partTreeData}
							selectedPartID={this.props.selectedPartID}
							selectedProjectID={this.props.selectedProjectID}
							setSelectedPartIDAndName={this.props.setSelectedPartIDAndName}
							setOwnerUserID={this.setOwnerUserID}
							setPartRightPage={this.setPartRightPage}
							getLang={this.props.getLang}
						/>
					</div>
					<div className="PartMainRight">
						<div>
						{showPropertiesYN && (						
							<PartRightProperties
								baseURLAPI={this.props.baseURLAPI}
								managementBaseURLAPI={this.props.managementBaseURLAPI}
								productionAppUserType={this.props.productionAppUserType}
								userID={this.props.userID}
								email={this.props.email}
								firstName={this.props.firstName}
								lastName={this.props.lastName}
								selectedPartID={this.props.selectedPartID}
								selectedProjectID={this.props.selectedProjectID}
								setPartRightPage={this.setPartRightPage}
								setNewTaskPartAndProperty={this.props.setNewTaskPartAndProperty}
								setRecycleTaskID={this.props.setRecycleTaskID}
								setOwnerUserID={this.setOwnerUserID}
								getLang={this.props.getLang}
							/>
						)}
						{showFilesYN && (
							<PartRightFiles
								baseURLAPI={this.props.baseURLAPI}
								productionAppUserType={this.props.productionAppUserType}
								userID={this.props.userID}
								email={this.props.email}
								firstName={this.props.firstName}
								lastName={this.props.lastName}
								selectedPartID={this.props.selectedPartID}
								selectedPartName={this.props.selectedPartName}
								selectedProjectID={this.props.selectedProjectID}
								getLang={this.props.getLang}
							/>
						)}
						{showChartYN && (
							<PartRightChart
								baseURLAPI={this.props.baseURLAPI}
								productionAppUserType={this.props.productionAppUserType}
								userID={this.props.userID}
								email={this.props.email}
								selectedPartID={this.props.selectedPartID}
								selectedPartName={this.props.selectedPartName}
								selectedProjectID={this.props.selectedProjectID}
								getLang={this.props.getLang}
							/>
						)}
						{showSelectOwnerYN && (
							<PartRightSelectOwner
								baseURLAPI={this.props.baseURLAPI}
								managementBaseURLAPI={this.props.managementBaseURLAPI}
								productionAppUserType={this.props.productionAppUserType}
								userID={this.props.userID}
								email={this.props.email}
								firstName={this.props.firstName}
								lastName={this.props.lastName}
								companyID={this.props.companyID}
								selectedPartID={this.props.selectedPartID}
								selectedPartName={this.props.selectedPartName}
								selectedProjectID={this.props.selectedProjectID}
								setPartRightPage={this.setPartRightPage}
								showToast={this.props.showToast}
								getLang={this.props.getLang}
							/>
						)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PartMain;

