import React  from 'react';
import './App.css';
import './Task.css';

import TaskMainToolbar from './TaskMainToolbar.js';
import TaskInfo from './TaskInfo.js';
import TaskNewComment from './TaskNewComment.js';
import TaskEdit from './TaskEdit.js';

import { useNavigate } from "react-router-dom";

class TaskMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			selectedTab: "info",
			taskID: 0,
			taskName: "",
			executorUserID: 0,
			userFullName: "",
			createdUserID: 0,
			createdUserFullName: "",
			createdUnixTimeSec: 0,
			status: "",
			startedUnixTimeSec: 0,
			endedUnixTimeSec: 0,
			durationSec: 0,
			notes: "",
			partID: 0,
			partName: "",
			partPropertyID: 0,
			partPropertyName: "",			
			partPropertyIntDiff: 0,
			projectID: 0,
			dataLoadedYN: false,
		};

		this.serverGetTaskData = this.serverGetTaskData.bind(this);
		this.setSelectedTab = this.setSelectedTab.bind(this);
		this.setStatus = this.setStatus.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}
	
	componentDidMount() {
		var selectedTaskID = this.props.selectedTaskID;
		this.setState({taskID: selectedTaskID});
		this.serverGetTaskData(selectedTaskID);
	}
	
	componentDidUpdate(prevProps) {

		var taskID = this.state.taskID;
		var selectedTaskID = this.props.selectedTaskID;
		
		if (taskID === selectedTaskID) {
			return;
		}

		this.setState({
			taskID: selectedTaskID,
			dataLoadedYN: false,
		});
		this.serverGetTaskData(selectedTaskID);
	}

	async serverGetTaskData(taskID) {
		
		if (taskID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/gettaskdata?taskid=" + taskID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var taskName = jsonObj.taskName;
		var executorUserID = jsonObj.userID;
		var userFullName = jsonObj.userFullName;
		var createdUserID = jsonObj.createdUserID;
		var createdUserFullName = jsonObj.createdUserFullName;
		var createdUnixTimeSec = jsonObj.createdUnixTimeSec;
		var status = jsonObj.status;
		var startedUnixTimeSec = jsonObj.startedUnixTimeSec;
		var endedUnixTimeSec = jsonObj.endedUnixTimeSec;
		var durationSec = jsonObj.durationSec;
		var notes = jsonObj.notes;
		var projectID = jsonObj.projectID;
		var partID = jsonObj.partID;
		var partName = jsonObj.partName;
		var partPropertyID = jsonObj.partPropertyID;
		var partPropertyName = jsonObj.partPropertyName;
		var partPropertyIntDiff = jsonObj.partPropertyIntDiff;
		
		this.setState({
			taskName: taskName,
			executorUserID: executorUserID,
			userFullName: userFullName,
			createdUserID: createdUserID,
			createdUserFullName: createdUserFullName,
			createdUnixTimeSec: createdUnixTimeSec,
			status: status,
			startedUnixTimeSec: startedUnixTimeSec,
			endedUnixTimeSec: endedUnixTimeSec,
			durationSec: durationSec,
			notes: notes,
			projectID: projectID,
			partID: partID,
			partName: partName,
			partPropertyID: partPropertyID,
			partPropertyName: partPropertyName,			
			partPropertyIntDiff: partPropertyIntDiff,
			dataLoadedYN: true,
		});
	}
	
	setSelectedTab(tab) {
		this.setState({
			selectedTab: tab,
		});
	}
	
	setStatus(status) {
		this.setState({
			status: status,
		});
	}
	
	renderTab() {		

		if (!this.state.dataLoadedYN) {
			return null;
		}

		var selectedTab = this.state.selectedTab;
		
		if (selectedTab === "info") {
			return (
				<TaskInfo
					baseURLAPI={this.props.baseURLAPI}
					userID={this.props.userID}
					email={this.props.email}
					fullName={this.props.fullName}
					productionAppUserType={this.props.productionAppUserType}
					taskID={this.state.taskID}
					taskName={this.state.taskName}
					executorUserID={this.state.executorUserID}
					userFullName={this.state.userFullName}
					createdUserID={this.state.createdUserID}
					createdUserFullName={this.state.createdUserFullName}
					createdUnixTimeSec={this.state.createdUnixTimeSec}
					status={this.state.status}
					startedUnixTimeSec={this.state.startedUnixTimeSec}
					endedUnixTimeSec={this.state.endedUnixTimeSec}
					durationSec={this.state.durationSec}
					notes={this.state.notes}
					partID={this.state.partID}
					partName={this.state.partName}
					partPropertyID={this.state.partPropertyID}
					partPropertyName={this.state.partPropertyName}
					partPropertyIntDiff={this.state.partPropertyIntDiff}
					projectID={this.state.projectID}
					showToast={this.props.showToast}
					convertTextNewlineToBr={this.props.convertTextNewlineToBr}
					getDateStringFromUnixTimeSec={this.props.getDateStringFromUnixTimeSec}
					getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
					getTimeStringFromSeconds={this.props.getTimeStringFromSeconds}
					setRecycleTaskID={this.props.setRecycleTaskID}
					setSelectedPartIDAndName={this.props.setSelectedPartIDAndName}
					setStatus={this.setStatus}
					serverGetTaskData={this.serverGetTaskData}
					showToast={this.props.showToast}
					serverGetTaskData={this.serverGetTaskData}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "edit") {
			return (
				<TaskEdit
					baseURLAPI={this.props.baseURLAPI}
					managementBaseURLAPI={this.props.managementBaseURLAPI}
					productionAppUserType={this.props.productionAppUserType}
					taskID={this.state.taskID}
					taskName={this.state.taskName}
					executorUserID={this.state.executorUserID}
					userFullName={this.state.userFullName}
					createdUserID={this.state.createdUserID}
					createdUserFullName={this.state.createdUserFullName}
					createdUnixTimeSec={this.state.createdUnixTimeSec}
					status={this.state.status}
					startedUnixTimeSec={this.state.startedUnixTimeSec}
					endedUnixTimeSec={this.state.endedUnixTimeSec}
					durationSec={this.state.durationSec}
					notes={this.state.notes}
					partID={this.state.partID}
					partName={this.state.partName}
					projectID={this.state.projectID}
					setSelectedTab={this.setSelectedTab}
					serverGetTaskData={this.serverGetTaskData}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "newcomment") {
			return(
				<TaskNewComment
					baseURLAPI={this.props.baseURLAPI}
					taskID={this.state.taskID}
					taskName={this.state.taskName}
					userID={this.props.userID}
					fullName={this.props.fullName}
					setSelectedTab={this.setSelectedTab}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}		
	}

	render() {
		return (
			<div>
				<TaskMainToolbar
					productionAppUserType={this.props.productionAppUserType}
					userID={this.props.userID}
					taskID={this.state.taskID}
					taskName={this.state.taskName}
					executorUserID={this.state.executorUserID}
					createdUserID={this.state.createdUserID}
					selectedTab={this.state.selectedTab}
					setSelectedTab={this.setSelectedTab}
					getLang={this.props.getLang}
				/>
				{this.renderTab()}
			</div>
		);
	}
}

export default function TaskMainFunction(props) {
	const navigate = useNavigate();
	return <TaskMain {...props} navigate={navigate} />;
}

