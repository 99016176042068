import React  from 'react';
import './App.css';
import './Part.css';
import PartLang from './PartLang.json';
  
import { useNavigate } from "react-router-dom";

class PropertyCode extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			propertyName: "",
			jsCode: "",
		};

		this.onJsCodeChanged = this.onJsCodeChanged.bind(this);		
		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.serverGetPartPropertyData = this.serverGetPartPropertyData.bind(this);
		this.serverUpdatePartJsCodeCount = this.serverUpdatePartJsCodeCount.bind(this);		
		this.serverUpdatePartPropertyJsCode = this.serverUpdatePartPropertyJsCode.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		var partID = this.props.selectedPartID;
		var partPropertyID = this.props.selectedPartPropertyID;
		if (partPropertyID === 0) {
			this.serverGetPartData(partID);
		} else {
			this.serverGetPartPropertyData(partPropertyID);
		}
	}
	
	onJsCodeChanged(value) {
		this.setState({jsCode: value});
	}
	
	async serverGetPartData(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		
		var propertyName = jsonObj.propertyName;
		var jsCodeCount = jsonObj.jsCodeCount;

		this.setState({
			propertyName: "Count",
			jsCode: jsCodeCount,
		});
	}
		
	async serverGetPartPropertyData(partPropertyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertydata?partpropertyid=" + partPropertyID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		
		var propertyName = jsonObj.propertyName;
		var jsCode = jsonObj.jsCode;

		this.setState({
			propertyName: propertyName,
			jsCode: jsCode,
		});
	}
		
	async serverUpdatePartJsCodeCount(partID, jsCodeCount) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartjscodecount?partid=" + partID;
		url = url + "&jscodecount=" + encodeURIComponent(jsCodeCount);
		
		const res = await fetch(url);
		await res.text();
	}
		
	async serverUpdatePartPropertyJsCode(partPropertyID, jsCode) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartpropertyjscode?partpropertyid=" + partPropertyID;
		url = url + "&jscode=" + encodeURIComponent(jsCode);
		
		const res = await fetch(url);
		await res.text();
	}
		
	onClickSaveButton() {

		var partID = this.props.selectedPartID;
		var partPropertyID = this.props.selectedPartPropertyID;
		var jsCode = this.state.jsCode;

		if (partPropertyID === 0) {
			this.serverUpdatePartJsCodeCount(partID, jsCode);
		} else {
			this.serverUpdatePartPropertyJsCode(partPropertyID, jsCode);
		}

		this.props.showToast(this.props.getLang(PartLang, "propertyCodeSaved"));
		const navigate = this.props.navigate;
  		navigate("/setup/properties");
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/setup/properties");
	}
	
	render() {

		var propertyID = this.props.selectedPartPropertyID;
		var propertyName = this.state.propertyName;
		
		if (propertyName === "Count") {
			propertyName = this.props.getLang(PartLang, "count");
		}
		
		return (
			<div>
				<div className="ViewContainer1">
					<div style={{height: "30px"}}></div>
					<div className="ViewTopTitle">{this.props.getLang(PartLang, "editPropertyCode")}</div>
					<div className="ViewTopText">{this.props.getLang(PartLang, "editPropertyCodeInfoText")}</div>

					<div style={{height: "30px"}}></div>
					<div className="ViewBlockContainer">
						<div style={{height: "15px"}}></div>
						<div className="ViewBlockTitle">{this.props.getLang(PartLang, "propertyInfo")}</div>
						<div style={{height: "20px"}}></div>
						<div className="ViewContainer2">
							<fieldset className="PropertyNewFieldset">
								<legend className="PropertyNewPartLegend">{this.props.getLang(PartLang, "propertyName")}</legend> 
								<input
									ref={c => (this._input = c)}
									className="PropertyNewPartInput" 
									id="propertyName" 
									type="text" 
									disabled={true}
									defaultValue={propertyName}
								/>
							</fieldset>
						</div>
						<div style={{height: "20px"}}></div>
					</div>
				</div>
				<div style={{height: "30px"}}></div>
				<div className="PropertyCodeTopContainer">
					<fieldset className="PropertyNewFieldset">
						<legend className="PropertyNewPartLegend">{this.props.getLang(PartLang, "code")}</legend> 
						<div className="PropertyCodeContainer">
							<textarea
								className="PropertyCodeTextArea" 
								id="notes" 
								value={this.state.jsCode}
								rows={20}
								onChange={(event) => this.onJsCodeChanged(event.target.value)}
							/>
						</div>
					</fieldset>
					<div style={{height: "60px"}}></div>
					<div className="PropertyCodeContainerButtons">
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(PartLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(PartLang, "save")}</button>
					</div>
				<div style={{height: "100px"}}></div>
				</div>
			</div>
		);
	}
}

export default function PropertyCodeFunction(props) {
	const navigate = useNavigate();
	return <PropertyCode {...props} navigate={navigate} />;
}


