import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class ChangeLogToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
	}
	
	handleSelectLink(page) {		
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	render() {

		var selectedProjectID = this.props.selectedProjectID;
		var selectedProjectName = this.props.selectedProjectName;

		return (
			<div className="ChangeLogToolbarContainer1">
				<div className="ChangeLogToolbarLeft">
					<div 
						className="ChangeLogToolbarLink"
						onClick={() => this.handleSelectLink("/selectproject")}
					>
						{selectedProjectName}
					</div>
				</div>
				<div className="ChangeLogToolbarCenter">&nbsp;</div>
				<div className="ChangeLogToolbarRight">&nbsp;</div>
			</div>
		);
	}
}

export default function ChangeLogToolbarFunction(props) {
	const navigate = useNavigate();
	return <ChangeLogToolbar {...props} navigate={navigate} />;
}

