import React  from 'react';
import './App.css';
import './Part.css';
import PartLang from './PartLang.json';
  
import { useNavigate } from "react-router-dom";

class PropertyUpdate extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			propertyName: "",
			propertyType: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onPropertyNameChanged = this.onPropertyNameChanged.bind(this);		
		this.onPropertyTypeChanged = this.onPropertyTypeChanged.bind(this);		
		this.serverGetPartPropertyData = this.serverGetPartPropertyData.bind(this);
		this.serverUpdatePartProperty = this.serverUpdatePartProperty.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		this.serverGetPartPropertyData(this.props.selectedPartPropertyID);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onPropertyNameChanged(value) {
		this.setState({propertyName: value});
	}
	
	onPropertyTypeChanged(event) {
		var value = event.target.value;
		this.setState({propertyType: value});
	}
	
	async serverGetPartPropertyData(partPropertyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertydata?partpropertyid=" + partPropertyID;

		const res = await fetch(url);
		const jsonObj = await res.json();
		
		var propertyName = jsonObj.propertyName;
		var propertyType = jsonObj.propertyType;

		this.setState({propertyName: propertyName});
		this.setState({propertyType: propertyType});
	}
		
	async serverUpdatePartProperty(partPropertyID, propertyName, propertyType) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartpropertydata?partpropertyid=" + partPropertyID + "&propertyname=" + encodeURIComponent(propertyName) + "&propertytype=" + encodeURIComponent(propertyType);
		
		const res = await fetch(url);
		await res.text();

		const navigate = this.props.navigate;
  		navigate("/setup/properties");
	}
		
	onClickSaveButton() {

		var propertyName = this.state.propertyName;
		if (propertyName === "") {
			this.props.showToast(this.props.getLang(PartLang, "propertyNotEmpty"));
			return;
		}
		
		var propertyType = this.state.propertyType;

		this.props.showToast(this.props.getLang(PartLang, "propertyInfoUpdated"));
		this.serverUpdatePartProperty(this.props.selectedPartPropertyID, propertyName, propertyType);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/setup/properties");
	}
	
	render() {

		var propertyID = this.props.selectedPartPropertyID;
		var propertyName = this.state.propertyName;
		var propertyType = this.state.propertyType;
		
		if (propertyType === "") {
			return null;
		}
		
		var intCheckedYN = false;
		var floatCheckedYN = false;
		var textCheckedYN = false;
		
		if (propertyType === "int") {
			intCheckedYN = true;
		}
		if (propertyType === "float") {
			floatCheckedYN = true;
		}
		if (propertyType === "text") {
			textCheckedYN = true;
		}

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(PartLang, "updatePartProperty")}</div>
				<div className="ViewTopText">{this.props.getLang(PartLang, "updatePartPropertyInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(PartLang, "propertyInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<p></p>
						<fieldset className="PropertyNewFieldset">
							<legend className="PropertyNewPartLegend">{this.props.getLang(PartLang, "propertyID")}</legend> 
							<input
								className="PropertyNewPartInput" 
								id="propertyID" 
								type="text" 
								defaultValue={propertyID}
								disabled={true}
							/>
						</fieldset>
						<fieldset className="PropertyNewFieldset">
							<legend className="PropertyNewPartLegend">{this.props.getLang(PartLang, "propertyName")}</legend> 
							<input
								ref={c => (this._input = c)}
								className="PropertyNewPartInput" 
								id="propertyName" 
								type="text" 
								autoFocus={true}
								defaultValue={propertyName}
								onChange={(event) => this.onPropertyNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>
						<fieldset className="PropertyNewFieldset">
							<legend className="PropertyNewPartLegend">{this.props.getLang(PartLang, "propertyType")}</legend> 
							<div className="PropertyNewTypeContainer" onChange={this.onPropertyTypeChanged}>
								<input className="PropertyNewNameInput" type="radio" value="int" name="PropertyType" defaultChecked={intCheckedYN}/> int
								<input className="PropertyNewNameInput" type="radio" value="float" name="PropertyType" defaultChecked={floatCheckedYN}/> float
								<input className="PropertyNewNameInput" type="radio" value="text" name="PropertyType" defaultChecked={textCheckedYN}/> text							
							</div>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(PartLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(PartLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function PropertyUpdateFunction(props) {
	const navigate = useNavigate();
	return <PropertyUpdate {...props} navigate={navigate} />;
}


