import React  from 'react';
import Popup from 'reactjs-popup';
import './antd.css';
import 'reactjs-popup/dist/index.css';
import './PartPickerPopup.css';
import PartLang from './PartLang.json';
import { Tree } from 'antd';

class PartPickerPopup extends React.Component {

	constructor(props) {

		super(props);
		
		this.popUpClosed = this.popUpClosed.bind(this);
		this.onSelectPart = this.onSelectPart.bind(this);
		this.renderTree = this.renderTree.bind(this);
		this.renderPopup = this.renderPopup.bind(this);
	}
	
	componentDidMount() {
	}

	popUpClosed() {
		this.props.setShowPartPickerPopupYN(false);
	}
	
	onSelectPart(partIDArray, info) {
		console.log("onSelectPart");
		var length = partIDArray.length;
		if (length !== 1) {
			return;
		}
		var partID = partIDArray[0];
		var partName = info.node.title;
		console.log("partName = " + partName);
		this.props.setPartIDAndName(partID, partName);
		this.props.setShowPartPickerPopupYN(false);
	}

	renderTree() {

		var partPickerPartID = this.props.partPickerPartID;
		var partTreeData = this.props.partTreeData;
		var defaultSelectedKeys = [];
		
		if (partPickerPartID !== 0) {
			defaultSelectedKeys.push(partPickerPartID);
		}
		
		return (
			<Tree
				className="PartPickerPopupTree"				
				defaultExpandedKeys={defaultSelectedKeys}
				defaultSelectedKeys={defaultSelectedKeys}
				onSelect={this.onSelectPart}
				treeData={partTreeData}
			/>
		);
	}
	
	renderPopup() {
		
		return (
			<Popup
				className="part-picker-popup"
				open={true}
				closeOnDocumentClick={true}
				onClose={this.popUpClosed}
			>
				<div className="PartPickerPopupTopContainer">
					<div className="PartPickerPopupHeaderContainer">
						<div className="PartPickerPopupHeaderText">Select part</div>
					</div>
					<div style={{height: "20px"}}></div>
					<div>{this.renderTree()}</div>
					
					<div style={{height: "50px"}}></div>
				</div>
			</Popup>
		);
	}

	render() {
		
		var renderPopupYN = this.props.renderPopupYN;
		
		if (!renderPopupYN) {
			return null;
		}
		
		return (
			<div className={this.props.className}>
				{this.renderPopup()}
			</div>
		);
	}
}

export default PartPickerPopup;

