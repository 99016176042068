import React from 'react';
import './App.css';
import './Navbar.css';
import { AiOutlineDashboard, AiOutlineLogout, AiOutlineCheck, AiOutlineInfoCircle } from "react-icons/ai"
import { FaCogs, FaTasks, FaInfo } from "react-icons/fa"
import { HiOutlineDocumentReport } from "react-icons/hi"
import { RiNodeTree } from "react-icons/ri"
import { TbReportSearch } from "react-icons/tb"
import { BsCartPlus } from "react-icons/bs"
import { HiOutlineCog } from "react-icons/hi"
import { RiCheckboxMultipleFill } from "react-icons/ri"
import AppLang from './AppLang.json';

import { useNavigate } from "react-router-dom";

class Navbar extends React.Component {

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick(page) {

		const navigate = this.props.navigate;

		if (page === "/logout") {
			this.props.logoutClearVariables();
			navigate("/login");
		} else {
			navigate(page);
		}

		this.props.closeNavbar();
	}
	
	render() {

		var navbarOpenYN = this.props.navbarOpenYN;
		if (!navbarOpenYN) {
			return (<div></div>);
		}
		
		var katafinAppUserType = this.props.katafinAppUserType;
		
		if (katafinAppUserType >= 100) {
			//displayUserListYN = true;
		}

		// temp
		var displaySetupYN = false;
		/*if (katafinAppUserType === 1000) {
			displaySetupYN = true;
		}*/

		return (
			<div className="Navbar">
				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/changelog")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineDashboard style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "changeLog")}</div>					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/tasks")}
				>
					<div className="NavbarLinkColumn1">
						<RiCheckboxMultipleFill style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "tasks")}</div>					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/reports/tasks/daily")}
				>
					<div className="NavbarLinkColumn1">
						<FaTasks style={{ color: "white", width: "20px", height: "20px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "tasksDailyReport")}</div>					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/parts")}
				>
					<div className="NavbarLinkColumn1">
						<HiOutlineCog style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "parts")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/tree")}
				>
					<div className="NavbarLinkColumn1">
						<RiNodeTree style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "tree")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/milestones")}
				>
					<div className="NavbarLinkColumn1">
						<TbReportSearch style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "milestones")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/reports")}
				>
					<div className="NavbarLinkColumn1">
						<TbReportSearch style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "reports")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/machines")}
				>
					<div className="NavbarLinkColumn1">
						<TbReportSearch style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "machines")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/selectproject")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineCheck style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "selectProject")}</div>				  					  
				</div>

				{displaySetupYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/setup")}
				>
					<div className="NavbarLinkColumn1">
						<BsCartPlus style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Setup</div>				  					  
				</div>)}

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/about")}
				>
					<div className="NavbarLinkColumn1">
						<FaInfo style={{ color: "white", width: "25px", height: "20px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "about")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/logout")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineLogout style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Logout</div>				  					  
				</div>

			</div>
		);
	}
}

export default function NavbarFunction(props) {
	const navigate = useNavigate();
	return <Navbar {...props} navigate={navigate} />;
}

