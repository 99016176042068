import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class FancyTreePopup extends React.Component {

	constructor(props) {

		super(props);
		this.ref = React.createRef();

		this.state = {
			partID: 0,
			partName: "",
			count: 0,
			propertyIDArray: [],
			propertyNameArray: [],
			propertyTypeArray: [],
			propertyValueArray: [],
			propertyCount: -1,
		};

		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.serverGetPartPropertyList = this.serverGetPartPropertyList.bind(this);		
		this.onPartTitleClicked = this.onPartTitleClicked.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.renderOneProperty = this.renderOneProperty.bind(this);
		this.renderAllProperties = this.renderAllProperties.bind(this);
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside.bind(this), true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside.bind(this), true);
	};

	async serverGetPartData(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var partName = jsonObj.partName;
		var count = jsonObj.count;

		this.setState({
			partID: partID,
			partName: partName,
			count: count,
		});
	}
		
	async serverGetPartPropertyList(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertylist?partid=" + partID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var i;
		var itemObj;
		var propertyID;
		var propertyName;
		var propertyType;
		var propertyValue;
		var propertyIDArray = [];
		var propertyNameArray = [];
		var propertyTypeArray = [];
		var propertyValueArray = [];
		var propertyCount = jsonObj.length;
		
		for(i=0; i<propertyCount; i++) {

			itemObj = jsonObj[i];
			propertyID = itemObj.partPropertyID;
			propertyName = itemObj.propertyName;
			propertyType = itemObj.propertyType;
			
			propertyValue = "";
			if (propertyType === "int") {
				propertyValue = itemObj.intValue;
			}
			if (propertyType === "float") {
				propertyValue = itemObj.floatValue;
			}
			if (propertyType === "text") {
				propertyValue = itemObj.textValue;
			}
			
			propertyIDArray.push(propertyID);
			propertyNameArray.push(propertyName);
			propertyTypeArray.push(propertyType);
			propertyValueArray.push(propertyValue);
		}

		this.setState({
			propertyIDArray: propertyIDArray,
			propertyNameArray: propertyNameArray,
			propertyTypeArray: propertyTypeArray,
			propertyValueArray: propertyValueArray,
			propertyCount: propertyCount,
		});
	}

	onPartTitleClicked() {
		var partID = this.state.partID;
		var partName = this.state.partName;
		this.props.setSelectedPartIDAndName(partID, partName);
		const navigate = this.props.navigate;
  		navigate("/parts");
	}
	
	handleClickOutside(event) {
		if (this.ref.current && !this.ref.current.contains(event.target)) {
			this.props.closePopup();
		}
	};

	renderOneProperty(propertyID, propertyName, propertyType, propertyValue) {
		
		var inputType = "text";
		if (propertyType === "int") {
			inputType = "number";
		}
		if (propertyType === "float") {
			inputType = "number";
		}
		
		return (
			<div className="FancyTreePopupText" key={propertyID}>{propertyName}: {propertyValue}</div>
		);
	}

	renderAllProperties() {

		var i;
		var propertyCount = this.state.propertyCount;
		var propertyID;
		var propertyName;
		var propertyType;
		var propertyValue;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<propertyCount; i++) {
			propertyID = this.state.propertyIDArray[i];
			propertyName = this.state.propertyNameArray[i];
			propertyType = this.state.propertyTypeArray[i];
			propertyValue = this.state.propertyValueArray[i];
			jsxCode = this.renderOneProperty(propertyID, propertyName, propertyType, propertyValue);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	render() {
		
		var selectedPartID = this.props.selectedPartID;
		var partID = this.state.partID;
		var propertyCount = this.state.propertyCount;
		
		if (selectedPartID === 0) {
			return null;
		}
		
		if (partID !== selectedPartID) {
			this.serverGetPartData(selectedPartID);
			this.serverGetPartPropertyList(selectedPartID);
			return null;
		}
		
		if (propertyCount === -1) {
			return null;
		}

		var partName = this.state.partName;
		var count = this.state.count;

		return (
			<div ref={this.ref} className="FancyTreePopupContainer1">
				<div className="FancyTreePopupTitle" onClick={this.onPartTitleClicked}>{partName}</div>
				<div style={{height: "15px"}}></div>
				<div className="FancyTreePopupText">Count: {count}</div>
				<div style={{height: "15px"}}></div>
				{this.renderAllProperties()}
			</div>
		);
	}
}

export default function FancyTreePopupFunction(props) {
	const navigate = useNavigate();
	return <FancyTreePopup {...props} navigate={navigate} />;
}


/*
				<div className="FancyTreePopupArrow"></div>
*/