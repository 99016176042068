import React  from 'react';
import './App.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { BsTrash } from "react-icons/bs"

import ChangeLogToolbar from './ChangeLogToolbar.js';

import { useNavigate } from "react-router-dom";

class ChangeLog extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			jsonObj: null,
			logCount: 0,
			index: 0,
			count: 10,
		};
		
		this.dateDisplayString = "";

		this.serverGetLogList = this.serverGetLogList.bind(this);
		this.formatDateString = this.formatDateString.bind(this);
		this.formatTimeString = this.formatTimeString.bind(this);
		this.formatDateTimeString = this.formatDateTimeString.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handlePartSelected = this.handlePartSelected.bind(this);
		this.serverDeleteLogRecord = this.serverDeleteLogRecord.bind(this);
		this.deleteLogEntry = this.deleteLogEntry.bind(this);
		this.handleDeleteLog = this.handleDeleteLog.bind(this);
		this.renderLogLine = this.renderLogLine.bind(this);
	}
	
	componentDidMount() {
		this.serverGetLogList(0);
	}
	
	async serverGetLogList(index) {

		var selectedProjectID = this.props.selectedProjectID;
		if (selectedProjectID === 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getloglist?projectid=" + selectedProjectID;
		url = url + "&sortby=timestampCreated&sortasc=0";
		url = url + "&index=" + index + "&count=" + this.state.count;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		var logCount = jsonObj.length;

		this.setState({
			jsonObj: jsonObj,
			logCount: logCount,
		});
	}
		
	formatDateString(timestamp) {
		var dateString = timestamp.substr(0, 10);
		return dateString;
	}

	formatTimeString(timestamp) {
		var timeString = timestamp.substr(11, 5);
		return timeString;
	}

	formatDateTimeString(timestamp) {
		
		var timestampString;
		
		var dateString = timestamp.substr(0, 10);
		var timeString = timestamp.substr(11, 8);

		timestampString = dateString + " " + timeString;
		
		return timestampString;
	}
	
	handlePartSelected(partID, partName) {
		this.props.setSelectedPartIDAndName(partID, partName);
		var navigate = this.props.navigate;
		navigate("/parts");
	}
	
	async serverDeleteLogRecord(logID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletelogrecord?logid=" + logID;
		
		const res = await fetch(url);
		const textReponse = await res.text();

		this.serverGetLogList(this.state.index);
	}
		
	deleteLogEntry(logID) {
		this.serverDeleteLogRecord(logID);
		this.props.showToast("Log entry deleted");
	}

	handleDeleteLog(logID) {
		confirmAlert({
			title: 'Delete log entry',
			message: 'Are you sure you want to delete log entry?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.deleteLogEntry(logID)
				},
				{
					label: 'No',
				}
			]
		});
	}

	handlePrev() {
		var index = this.state.index - 10;
		this.setState({index: index});
		this.serverGetLogList(index);
	}

	handleNext() {
		var index = this.state.index + 10;
		this.setState({index: index});
		this.serverGetLogList(index);
	}
	
	renderLogLine(logID, email, fullName, partID, partName, propertyName, valueOld, valueNew, timestampCreated) {

		var showDateYN = false;
		var dateString = this.formatDateString(timestampCreated);
		if (this.dateDisplayString !== dateString) {
			this.dateDisplayString = dateString;
			showDateYN = true;
		}
		
		var timeString = this.formatTimeString(timestampCreated);
		var valueString = valueOld + " --> " + valueNew;
		
		if (propertyName === "count") {
			propertyName = "Count";
		}

		var nameOrEmailString = fullName;
		if (nameOrEmailString === "") {
			nameOrEmailString = email;
		}
		if (nameOrEmailString === null) {
			nameOrEmailString = email;
		}
		
		var showDeleteYN = false;
		if (this.props.productionAppUserType >= 100) {
			showDeleteYN = true;
		}

		return (
			<div key={logID}>
				{showDateYN && (
					<div className="ListRow">
						<div style={{height: "15px"}}></div>
						<div className="ChangeLogListDate">
							{dateString}
						</div>
					</div>
				)}
				<div className="ListRow">
					<div className="ChangeLogListTimeString">{timeString}</div>
					<div className="ChangeLogListPartName" onClick={() => this.handlePartSelected(partID, partName)}>
						{partName}
					</div>
					<div className="ChangeLogListPropertyName">{propertyName}</div>
					<div className="ChangeLogListValueString">{valueString}</div>
					<div className="ChangeLogListEmail">{nameOrEmailString}</div>
					{showDeleteYN && (
						<div 
							className="ChangeLogListDelete"
							onClick={() => this.handleDeleteLog(logID)}
						>
							<BsTrash style={{ color: "black", width: "15px", height: "15px" }} />
						</div>
					)}
				</div>
			</div>
		);
	}
	
	render() {
		
		var jsonObj = this.state.jsonObj;
		
		if (jsonObj === null) {
			return null;
		}
		
		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (jsonObj.length === 10) {
			nextYN = true;
		}
		
		this.dateDisplayString = "";

		return (
			<div>
				<ChangeLogToolbar 
					selectedProjectID={this.props.selectedProjectID}
					selectedProjectName={this.props.selectedProjectName}
				/>
				<div className="ListContainer1">
					<div className="ListContainer2">
						{jsonObj.map((log) => (
							<div key={log.logID}>{this.renderLogLine(log.logID, log.email, log.fullName, log.partID, log.partName, log.propertyName, log.valueOld, log.valueNew, log.timestampCreated)}</div>
						))}
						<p>&nbsp;</p>
					</div>
					<div className="ListContainer3">
						{prevYN && (<div 
							className="ListPrevNextLink"
							onClick={() => this.handlePrev()}
						>
							&lt;&lt; Previous
						</div>)}
						{!prevYN && (<div 
							className="ListPrevNextLinkDisabled"
						>
							&lt;&lt; Previous
						</div>)}
						<div className="ListPrevNextLinkDisabled">|</div>
						{nextYN && (<div 
							className="ListPrevNextLink"
							onClick={() => this.handleNext()}
						>
							Next &gt;&gt;
						</div>)}
						{!nextYN && (<div 
							className="ListPrevNextLinkDisabled"
						>
							Next &gt;&gt;
						</div>)}
					</div>
				</div>
			</div>
		);
	}
}

export default function ChangeLogFunction(props) {
	const navigate = useNavigate();
	return <ChangeLog {...props} navigate={navigate} />;
}


