import React  from 'react';
import './App.css';

import MilestoneMonthlyMain from "./MilestoneMonthlyMain.js"

class MilestoneMain extends React.Component {

	render() {
		
		var selectedProjectID = this.props.selectedProjectID;
		var selectedProjectName = this.props.selectedProjectName;
		if (selectedProjectID !== 1) {
			return (
				<div>
					<p>No milestones for project "{selectedProjectName}"</p>
				</div>
			);
		}
		
		return (
			<MilestoneMonthlyMain
				baseURLAPI={this.props.baseURLAPI}
				productionAppUserType={this.props.productionAppUserType}
				userID={this.props.userID}
				email={this.props.email}
				fullName={this.props.fullName}
				companyID={this.props.companyID}
				selectedProjectID={this.props.selectedProjectID}
				selectedProjectName={this.props.selectedProjectName}
				showToast={this.props.showToast}
				getMonthString={this.props.getMonthString}
				getMonthStringShort={this.props.getMonthStringShort}
				setMilestoneData={this.props.setMilestoneData}
				separateTextIntoArrayOfLines={this.props.separateTextIntoArrayOfLines}
				getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
				getFullNameForUserID={this.props.getFullNameForUserID}
				getLang={this.props.getLang}
			/>
		);
	}
}

export default MilestoneMain;

