import React  from 'react';
import './App.css';
import './Part.css';
import { AiOutlineDelete } from "react-icons/ai"
import { BsCodeSlash, BsChevronBarDown } from "react-icons/bs"
import { TiFlowChildren } from "react-icons/ti"
import { VscSymbolEvent } from "react-icons/vsc"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactTooltip from 'react-tooltip';
import PartLang from './PartLang.json';

import { useNavigate } from "react-router-dom";

import PropertyListToolbar from './PropertyListToolbar.js';

class PropertyList extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			redNodeBaseURL: "https://nodered.plurato.com",
			flowIDCount: "",
			triggerJSONCount: null,
			minJSONCount: null,
			partName: "",
			propertyListJsonObj: null,
			propertyCount: 0,
		};

		this.convertBufferToString = this.convertBufferToString.bind(this);
		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.serverGetPropertyList = this.serverGetPropertyList.bind(this);
		this.serverUpdatePartFlowIDCount = this.serverUpdatePartFlowIDCount.bind(this);
		this.serverUpdatePartPropertyFlowID = this.serverUpdatePartPropertyFlowID.bind(this);
		this.openNodeRedPage = this.openNodeRedPage.bind(this);
		this.generateRandomID = this.generateRandomID.bind(this);
		this.nodeRedCreateNewFlow = this.nodeRedCreateNewFlow.bind(this);
		this.handleSelectProperty = this.handleSelectProperty.bind(this);
		this.handleEditCode = this.handleEditCode.bind(this);
		this.handleShowFlow = this.handleShowFlow.bind(this);
		this.handleShowTrigger = this.handleShowTrigger.bind(this);
		this.handleShowMin = this.handleShowMin.bind(this);
		this.handleDeleteProperty = this.handleDeleteProperty.bind(this);
		this.serverDeletePartPropertyRecord = this.serverDeletePartPropertyRecord.bind(this);
		this.renderOneProperty = this.renderOneProperty.bind(this);		
		this.renderAllProperties = this.renderAllProperties.bind(this);		
	}
	
	componentDidMount() {
		var selectedPartID = this.props.selectedPartID;
		if (selectedPartID > 0) {
			this.serverGetPartData(selectedPartID);
			this.serverGetPropertyList(selectedPartID);
		}
	}
	
	convertBufferToString(buffer) {
		
		var i;
		var ch;
		var chCode;
		var length = buffer.length;
		var outputString = "";
		
		for(i=0; i<length; i++) {
			chCode = buffer[i];
			if (chCode === 10) {
				ch = "\\n";
			} else {
				ch = String.fromCharCode(chCode);
			}
			outputString = outputString + ch;
		}

		return outputString;
	}
	
	async serverGetPartData(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		var partName = jsonObj.partName;
		var flowIDCount = jsonObj.flowIDCount;
		var triggerJSONCount = jsonObj.triggerJSONCount;
		var minJSONCount = jsonObj.minJSONCount;
		
		this.setState({
			partName: partName,
			flowIDCount: flowIDCount,
			triggerJSONCount: triggerJSONCount,
			minJSONCount: minJSONCount,
		});
	}
		
	async serverGetPropertyList(partID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertylist?partid=" + partID;

		const res = await fetch(url);
		const propertyListJsonObj = await res.json();
		var propertyCount = propertyListJsonObj.length;

		this.setState({
			propertyListJsonObj: propertyListJsonObj,
			propertyCount: propertyCount,
		});
	}
	
	async serverUpdatePartFlowIDCount(partID, flowIDCount) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartflowidcount?partid=" + partID;
		url = url + "&flowidcount=" + encodeURIComponent(flowIDCount);

		const res = await fetch(url);
		await res.text();

		// make icon green - refresh
		this.serverGetPartData(partID);
	}
	
	async serverUpdatePartPropertyFlowID(partID, partPropertyID, flowID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartpropertyflowid?partpropertyid=" + partPropertyID;
		url = url + "&flowid=" + encodeURIComponent(flowID);

		const res = await fetch(url);
		await res.text();

		// make icon green - refresh
		this.serverGetPropertyList(partID);
	}
	
	openNodeRedPage(flowID) {
		var redNodeBaseURL = this.state.redNodeBaseURL;
		var nodeRedURL = redNodeBaseURL + "/#flow/" + flowID;
		window.open(nodeRedURL);
	}

	generateRandomID() {

		var dateObj = new Date();
		var startUnixTime = dateObj.getTime();
		
		var randomNumber = Math.floor(Math.random() * 10000000);
		var randomID = startUnixTime + "-" + randomNumber;

		return randomID;
	}

	async nodeRedCreateNewFlow(partID, partPropertyID, partPropertyName) {

		var projectID = this.props.selectedProjectID;
		var projectName = this.props.selectedProjectName;
		var partName = this.state.partName;
		
		var id1 = this.generateRandomID();
		var id2 = this.generateRandomID();
		var id3 = this.generateRandomID();
		var id4 = this.generateRandomID();
		var diffURL = "diff/" + partID + "/" + partPropertyID;

		var httpInName = "";
		if (partPropertyID === 0) {
			httpInName = "HTTP IN - " + partName + ", Count";
		} else {
			httpInName = "HTTP IN - " + partName + ", " + partPropertyName;
		}

		var nodes = [
			{
				"id": id1,
				"type": "http in",
				"name": httpInName,
				"url": diffURL,
				"method": "get",
				"upload": false,
				"swaggerDoc": "",
				"x": 280,
				"y": 180,
				"wires": [
					[
						id2,
						id3,
						id4
					]
				]
			},
			{
				"id": id2,
				"type": "http response",
				"name": "Reponse",
				"statusCode": "200",
				"headers": {},
				"x": 780,
				"y": 180,
				"wires": []
			},
			{
				"id": id3,
				"type": "property-change",
				"name": "property-change",
				"partID": "0",
				"partName": "",
				"propertyID": "0",
				"propertyName": "Count",
				"multiplier": "1",
				"projectID": projectID,
				"projectName": projectName,
				"x": 550,
				"y": 280,
				"wires": []
			},
			{
				"id": id4,
				"type": "send-email",
				"name": "send-email",
				"partID": "0",
				"partName": "",
				"propertyID": "0",
				"propertyName": "",
				"minValue": 0,
				"emailSubject": "Treba narucit xyz",
				"emailText": "Treba narucit xyz",
				"emailList": "",
				"projectID": projectID,
				"projectName": projectName,
				"x": 540,
				"y": 380,
				"wires": []
			}
		];
		
		var redNodeBaseURL = this.state.redNodeBaseURL;
		var url = redNodeBaseURL + "/flow";

		var labelText = "";
		if (partPropertyID === 0) {
			labelText = partName + ", Count";
		} else {
			labelText = partName + ", " + partPropertyName;
		}

		var jsonObj = {};
		jsonObj.label = labelText;
		jsonObj.nodes = nodes;	
		jsonObj.configs = [];	

		var bodyText = JSON.stringify(jsonObj);

		const requestOptions = {
			method: "POST",
			url: url,
			headers: {
				"Content-Type": "application/json",
			},
			body: bodyText,
		};

		fetch(url, requestOptions)
			.then(response => response.json())
			.then(data => {
				var flowID = data.id;
				if (partPropertyID === 0) {
					this.serverUpdatePartFlowIDCount(partID, flowID);
				} else {
					this.serverUpdatePartPropertyFlowID(partID, partPropertyID, flowID);
				}
				this.openNodeRedPage(flowID);
			}
		);
	}
	
	handleSelectProperty(partPropertyID) {
		this.props.setSelectedPartPropertyID(partPropertyID);
		const navigate = this.props.navigate;
  		navigate("/setup/properties/update");
	}
	
	handleEditCode(partPropertyID, propertyName) {
		this.props.setSelectedPartPropertyID(partPropertyID);
		const navigate = this.props.navigate;
  		navigate("/setup/properties/code");
	}
	
	handleShowFlow(partPropertyID, propertyName, flowIDCount, flowID) {

		var partID = this.props.selectedPartID;

		if (partPropertyID === 0) {
			if (flowIDCount === "") {
				this.nodeRedCreateNewFlow(partID, 0, "");
			} else {
				this.openNodeRedPage(flowIDCount);
			}
		} else {
			if (flowID === "") {
				this.nodeRedCreateNewFlow(partID, partPropertyID, propertyName);
			} else {
				this.openNodeRedPage(flowID);				
			}
		}
	}
	
	handleShowTrigger(partPropertyID, propertyName) {
		this.props.setSelectedPartPropertyID(partPropertyID);
		const navigate = this.props.navigate;
  		navigate("/setup/properties/trigger");
	}
	
	handleShowMin(partPropertyID, propertyName) {
		this.props.setSelectedPartPropertyID(partPropertyID);
		const navigate = this.props.navigate;
  		navigate("/setup/properties/min");
	}

	handleDeleteProperty(partPropertyID, propertyName) {

		confirmAlert({
			title: this.props.getLang(PartLang, "deleteProperty"),
			message: this.props.getLang(PartLang, "deletePropertyAreYouSure") + " \"" + propertyName + "\" ?",
			buttons: [
				{
					label: this.props.getLang(PartLang, "yes"),
					onClick: () => this.serverDeletePartPropertyRecord(partPropertyID, propertyName)
				},
				{
					label: this.props.getLang(PartLang, "no"),
				}
			]
		});
	}

	async serverDeletePartPropertyRecord(partPropertyID, propertyName) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletepartpropertyrecord?partpropertyid=" + partPropertyID;

		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(PartLang, "propertyDeleted") + ": \"" + propertyName + "\"");
		const navigate = this.props.navigate;
  		navigate("/parts");
	}
	
	renderOneProperty(itemObj) {

		var partPropertyID = itemObj.partPropertyID;
		var propertyName = itemObj.propertyName;
		var propertyType = itemObj.propertyType;
		var triggerJSON = itemObj.triggerJSON;
		var triggerJSONCount = this.state.triggerJSONCount;
		var minJSON = itemObj.minJSON;
		var minJSONCount = this.state.minJSONCount;
		
		var triggerColor = "black";
		var jsonText = "";
		if (partPropertyID === 0) {
			if (triggerJSONCount !== null) {
				jsonText = this.convertBufferToString(triggerJSONCount.data);
				if (jsonText !== "[]") {
					triggerColor = "green";
				}
			}
		} else {
			if (triggerJSON !== null) {
				jsonText = this.convertBufferToString(triggerJSON.data);
				if (jsonText !== "[]") {
					triggerColor = "green";
				}
			}
		}
		
		var minColor = "black";
		if (partPropertyID === 0) {
			if (minJSONCount !== null) {
				var jsonText = this.convertBufferToString(minJSONCount.data);
				if (jsonText !== "") {
					minColor = "green";
				}
			}
		} else {
			if (minJSON !== null) {
				var jsonText = this.convertBufferToString(minJSON.data);
				if (jsonText !== "") {
					minColor = "green";
				}
			}
		}		
		
		var productionAppUserType = this.props.productionAppUserType;

		var deletePropertyYN = false;
		if (productionAppUserType >= 100) {
			deletePropertyYN = true;
		}

		return (
			<div className="ListRow" key={partPropertyID}>
				{(partPropertyID === 0) && (<div className="ListTextLeft">
					{propertyName}
				</div>)}
				{(partPropertyID !== 0) && (<div
					className="ListLink"
					onClick={() => this.handleSelectProperty(partPropertyID)}
				>
					{propertyName}
				</div>)}
				<div className="ListTextLeft">
					{propertyType}
				</div>
				{/*<div 
					className="PropertyListIconLink" 
					onClick={() => this.handleEditCode(partPropertyID, propertyName)}
					data-tip="Edit JS code"
				>
						<BsCodeSlash style={{ width: "20px", height: "20px" }} />
				</div>
				<div 
					className="PropertyListIconLink" 
					onClick={() => this.handleShowFlow(partPropertyID, propertyName, flowIDCount, flowID)}
					data-tip="Edit node red flow"
				>
						<TiFlowChildren style={{ color: flowColor, width: "20px", height: "20px" }} />
				</div>*/}
				<div 
					className="PropertyListIconLink" 
					onClick={() => this.handleShowTrigger(partPropertyID, propertyName)}
					data-tip={this.props.getLang(PartLang, "editTriggerEvent")}
				>
						<VscSymbolEvent style={{ color: triggerColor, width: "20px", height: "20px" }} />
				</div>
				<div 
					className="PropertyListIconLink" 
					onClick={() => this.handleShowMin(partPropertyID, propertyName)}
					data-tip={this.props.getLang(PartLang, "editMinimumEvent")}
				>
						<BsChevronBarDown style={{ color: minColor, width: "20px", height: "20px" }} />
				</div>
				{(partPropertyID !== 0) && deletePropertyYN && (<div 
					className="PropertyListIconLink" 
					onClick={() => this.handleDeleteProperty(partPropertyID, propertyName)}
				>
						<AiOutlineDelete style={{ width: "20px", height: "20px" }} />
				</div>)}
			</div>
		);
	}

	renderAllProperties() {
		
		var propertyListJsonObj = this.state.propertyListJsonObj;
		var countString = this.props.getLang(PartLang, "count");
		var i;
		var jsxCode;
		var jsxArray = [];		
		
		var itemObj = {};							
		itemObj.partPropertyID = 0;
		itemObj.propertyName = countString;
		itemObj.propertyType = "int";
		jsxCode = this.renderOneProperty(itemObj);
		jsxArray.push(jsxCode);
		
		for(i=0; i<propertyListJsonObj.length; i++) {
			itemObj = propertyListJsonObj[i];
			jsxCode = this.renderOneProperty(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	render() {
		
		var propertyListJsonObj = this.state.propertyListJsonObj;		
		if (propertyListJsonObj === null) {
			return null;
		}
		
		return (
			<div>
				<ReactTooltip
					type="info"
					delayShow={500}
				/>
				<PropertyListToolbar
					selectedPartName={this.props.selectedPartName}
					getLang={this.props.getLang}
				/>
				<div className="ListContainer1">
					<div className="ListContainer2">
						<div className="ListRow">
							<div className="ListHeader">&nbsp;</div>
						</div>
						<div className="ListRow">
							<div className="ListHeader">{this.props.getLang(PartLang, "propertyName")}</div>
							<div className="ListHeader">{this.props.getLang(PartLang, "propertyType")}</div>
						</div>
						{this.renderAllProperties()}
						<p>&nbsp;</p>
					</div>
				</div>
			</div>
		);
	}
}

export default function PropertyListFunction(props) {
	const navigate = useNavigate();
	return <PropertyList {...props} navigate={navigate} />;
}


