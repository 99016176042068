import React from 'react';
import './App.css';
import './Machine.css';
import MachineLang from './MachineLang.json';
import moment from 'moment';

import { useNavigate } from "react-router-dom";

class MachineList extends React.Component {

	constructor(props) {

		super(props);
		
		this.interval1 = null;
		
		this.state = {
			machineListJsonObj: null,
		};

		this.timerFunction = this.timerFunction.bind(this);
		this.handleSelectMachine = this.handleSelectMachine.bind(this);
		this.serverGetMachineList = this.serverGetMachineList.bind(this);
		this.getDateTimeStringFromUnixTimeSecSpecial = this.getDateTimeStringFromUnixTimeSecSpecial.bind(this);
		this.renderOneMachine = this.renderOneMachine.bind(this);
		this.renderAllMachines = this.renderAllMachines.bind(this);
	}
	
	componentDidMount() {
		this.serverGetMachineList();
		this.interval1 = setInterval(this.timerFunction, 1000);
	}
	
	componentWillUnmount() {
		if (this.interval1 !== null) {
			clearInterval(this.interval1);
			this.interval1 = null;
		}
	}
	
	timerFunction() {
		this.serverGetMachineList();
	}

	handleSelectMachine(machineID) {
		//this.props.setSelectedMachineID(machineID);
  		//this.props.navigate("/machine/view");
	}

	async serverGetMachineList() {
		
		//this.setState({machineListJsonObj: null});

		var companyID = this.props.companyID;		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getmachinelist?companyid=" + companyID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			machineListJsonObj: jsonObj,
		});
	}
	
	getDateTimeStringFromUnixTimeSecSpecial(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var currentDateObj = new Date();
		var dateObj = new Date(unixTimeSec * 1000);
		
		var year1 = currentDateObj.getFullYear();
		var year2 = dateObj.getFullYear();
		
		var dateTimeString;
		if (year1 === year2) {
			dateTimeString = moment(dateObj).format('DD/MM HH:mm:ss');
		} else {
			dateTimeString = moment(dateObj).format('DD.MM.YYYY HH:mm:ss');
		}

		return dateTimeString;
	}

	renderOneMachine(itemObj) {
		
		var machineID = itemObj.machineID;
		var machineName = itemObj.machineName;
		var lastUpdateUnixTimeSec = itemObj.lastUpdateUnixTimeSec;
		var lastUpdateCurrent = itemObj.lastUpdateCurrent;
		
		var lastUpdateString = this.getDateTimeStringFromUnixTimeSecSpecial(lastUpdateUnixTimeSec);

		return (
			<tr key={machineID} onClick={() => this.handleSelectMachine(machineID)}>
				<td className="MachineListMachineNameItem">
					{machineName}
				</td>
				<td className="MachineListLastDateTimeItem">
					{lastUpdateString}
				</td>
				<td className="MachineListLastCurrentItem">
					{lastUpdateCurrent}
				</td>
			</tr>
		);
	}

	renderAllMachines() {

		var i;
		var machineListJsonObj = this.state.machineListJsonObj;
		var itemObj;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<machineListJsonObj.length; i++) {
			itemObj = machineListJsonObj[i];
			jsxCode = this.renderOneMachine(itemObj);
			jsxArray.push(jsxCode);
		}

		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="MachineListMachineNameHeader">{this.props.getLang(MachineLang, "machineName")}</td>
						<td className="MachineListLastDateTimeHeader">{this.props.getLang(MachineLang, "lastDateTime")}</td>
						<td className="MachineListLastCurrentHeader">{this.props.getLang(MachineLang, "lastCurrent")}</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	render() {

		var machineListJsonObj = this.state.machineListJsonObj;		
		if (machineListJsonObj === null) {
			return null;
		}

		var machineCount = machineListJsonObj.length;
		if (machineCount === 0) {
			return (<div>No machines found</div>);
		}

		return (
			<div>
				<div style={{height:"20px"}}></div>
				<div>
					{this.renderAllMachines()}
				</div>
				<div style={{height:"30px"}}></div>
			</div>
		);
	}
}

export default function MachineListFunction(props) {
	const navigate = useNavigate();
	return <MachineList {...props} navigate={navigate} />;
}
