import React  from 'react';
import './App.css';
import './Task.css';
import TaskLang from './TaskLang.json';
import { useNavigate } from "react-router-dom";

class TaskListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(linkValue) {
		
		this.props.setTaskListIndex(0);

		if (linkValue === "mytasks") {
			this.props.setMyTasksYN(true);
		}

		if (linkValue === "alltasks") {
			this.props.setMyTasksYN(false);
		}

		if (linkValue === "/tasks/new") {
			this.props.setRecycleTaskID(0);
			this.props.setNewTaskPartAndProperty(0, "", 0, "");
			this.props.navigate(linkValue);
		}
	}
	
	renderLink(linkTitle, linkValue, selectedYN) {
		
		var classNameString = "TaskListToolbarLink";
		if (selectedYN) {
			classNameString = "TaskMainToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {
		
		var myTasksString = this.props.getLang(TaskLang, "myTasks");
		var allTasksString = this.props.getLang(TaskLang, "allTasks");
		var newTaskString = this.props.getLang(TaskLang, "newTask");
		
		return (
			<div className="TaskListToolbarContainer1">
				<div className="TaskListToolbarLeft"></div>
				<div className="TaskListToolbarRight">
					<div className="TaskListToolbarContainer2">
						{this.renderLink(myTasksString, "mytasks", this.props.myTasksYN)}
						<div className="TaskMainToolbarText">|</div>
						{this.renderLink(allTasksString, "alltasks", !this.props.myTasksYN)}
						<div className="TaskMainToolbarText">|</div>
						{this.renderLink(newTaskString, "/tasks/new", false)} 
					</div>
				</div>
			</div>
		);
	}
}

export default function TaskListToolbarFunction(props) {
	const navigate = useNavigate();
	return <TaskListToolbar {...props} navigate={navigate} />;
}

