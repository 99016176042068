import React  from 'react';
import './App.css';
import './PropertyMin.css';
import PartLang from './PartLang.json';
import ReactTooltip from 'react-tooltip';

import { useNavigate } from "react-router-dom";

class PropertyMin extends React.Component {
	
	constructor(props) {

		super(props);
		
		this.state = {
			partName: "",
			propertyName: "Count",
			minValue: "0",
			subject: "",
			emailText: "",
			emailList: "",
		};

		this.convertBufferToString = this.convertBufferToString.bind(this);
		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.serverGetPartPropertyData = this.serverGetPartPropertyData.bind(this);
		this.serverUpdatePartMinJSONCount = this.serverUpdatePartMinJSONCount.bind(this);
		this.serverUpdatePartPropertyMinJSON = this.serverUpdatePartPropertyMinJSON.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.onMinimumChanged = this.onMinimumChanged.bind(this);
		this.onSubjectChanged = this.onSubjectChanged.bind(this);
		this.onEmailTextChanged = this.onEmailTextChanged.bind(this);
		this.onEmailListChanged = this.onEmailListChanged.bind(this);
	}
	
	componentDidMount() {
		var partID = this.props.selectedPartID;
		var partPropertyID = this.props.selectedPartPropertyID;
		this.serverGetPartData(partID, partPropertyID);
		if (partPropertyID !== 0) {
			this.serverGetPartPropertyData(partPropertyID);
		}
	}

	convertBufferToString(buffer) {
		
		var i;
		var ch;
		var chCode;
		var length = buffer.length;
		var outputString = "";
		
		for(i=0; i<length; i++) {
			chCode = buffer[i];
			if (chCode === 10) {
				ch = "\\n";
			} else {
				ch = String.fromCharCode(chCode);
			}
			outputString = outputString + ch;
		}

		return outputString;
	}
	
	async serverGetPartData(partID, propertyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var partName = jsonObj.partName;
		this.setState({
			partName: partName,
		});

		if (propertyID !== 0) {
			return;
		}

		var minJSONCount = jsonObj.minJSONCount;
		if (minJSONCount !== null) {
			var jsonText = this.convertBufferToString(minJSONCount.data);
			if (jsonText !== "") {
				var jsonObj2 = JSON.parse(jsonText);
				var minValue = jsonObj2.minValue;
				var subject = jsonObj2.subject;
				var emailText = jsonObj2.emailText;
				var emailList = jsonObj2.emailList;
				this.setState({
					minValue: minValue,
					subject: subject,
					emailText: emailText,
					emailList: emailList,
				});
			}
		}
	}
		
	async serverGetPartPropertyData(partPropertyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertydata?partpropertyid=" + partPropertyID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		
		var propertyName = jsonObj.propertyName;
		var minJSON = jsonObj.minJSON;

		if (minJSON !== null) {
			var jsonText = this.convertBufferToString(minJSON.data);
			if (jsonText !== "") {
				var jsonObj2 = JSON.parse(jsonText);
				var minValue = jsonObj2.minValue;
				var subject = jsonObj2.subject;
				var emailText = jsonObj2.emailText;
				var emailList = jsonObj2.emailList;
				this.setState({
					minValue: minValue,
					subject: subject,
					emailText: emailText,
					emailList: emailList,
				});
			}
		}
		
		this.setState({
			propertyName: propertyName,
		});
	}

	async serverUpdatePartMinJSONCount(partID, minJSONCount) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartminjsoncount?partid=" + partID;
		url = url + "&minjsoncount=" + encodeURIComponent(minJSONCount);
		
		const res = await fetch(url);
		await res.text();

		const navigate = this.props.navigate;
		navigate("/setup/properties");
	}
		
	async serverUpdatePartPropertyMinJSON(partPropertyID, minJSON) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartpropertyminjson?partpropertyid=" + partPropertyID;
		url = url + "&minjson=" + encodeURIComponent(minJSON);
		
		const res = await fetch(url);
		await res.text();

		const navigate = this.props.navigate;
		navigate("/setup/properties");
	}
		
	onClickSaveButton() {

		var partID = this.props.selectedPartID;
		var partPropertyID = this.props.selectedPartPropertyID;
		var minValue = this.state.minValue;
		var subject = this.state.subject;
		var emailText = this.state.emailText;
		var emailList = this.state.emailList;
		
		minValue = minValue.toString();
		
		var minJSONText = "";

		if ((minValue.trim() === "") && (subject.trim() === "") && (emailText.trim() === "") && (emailList.trim() === "")) {
			minJSONText = "";
		} else {
			minValue = parseFloat(minValue);
			var jsonObj = {};
			jsonObj.minValue = minValue;
			jsonObj.subject = subject;
			jsonObj.emailText = emailText;
			jsonObj.emailList = emailList;
			minJSONText = JSON.stringify(jsonObj);
		}

		if (partPropertyID === 0) {
			this.serverUpdatePartMinJSONCount(partID, minJSONText);
		} else {
			this.serverUpdatePartPropertyMinJSON(partPropertyID, minJSONText);
		}

		var partName = this.state.partName;
		var propertyName = this.state.propertyName;
		if (propertyName === "Count") {
			propertyName = this.props.getLang(PartLang, "count");
		}

		var toastMessage = this.props.getLang(PartLang, "propertyMinSaved") + ": \"" + partName + " / " + propertyName + "\"";
		this.props.showToast(toastMessage);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/setup/properties");
	}
	
	onMinimumChanged(value) {
		this.setState({minValue: value});
	}

	onSubjectChanged(value) {
		this.setState({subject: value});
	}

	onEmailTextChanged(value) {
		this.setState({emailText: value});
	}

	onEmailListChanged(value) {
		this.setState({emailList: value});
	}
		
	render() {

		var partName = this.state.partName;
		var propertyName = this.state.propertyName;
		
		if (propertyName === "Count") {
			propertyName = this.props.getLang(PartLang, "count");
		}

		var partPropertyString = partName + " / " + propertyName;

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(PartLang, "editPropertyMin")}</div>
				<div className="ViewTopText">{this.props.getLang(PartLang, "editPropertyMinInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(PartLang, "propertyMin")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<p></p>

						<fieldset className="PropertyMinFieldset">
							<legend className="PropertyMinPartLegend">{this.props.getLang(PartLang, "partSlashProperty")}</legend> 
							<input
								className="PropertyMinPartInput" 
								id="partPropertyName" 
								type="text" 
								value={partPropertyString}
								disabled={true}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>

						<fieldset className="PropertyMinFieldset">
							<legend className="PropertyMinPartLegend">{this.props.getLang(PartLang, "minimum")}</legend> 
							<input
								className="PropertyMinPartInput" 
								id="minimum" 
								type="number"
								value={this.state.minValue}
								autoFocus={true}
								onChange={(event) => this.onMinimumChanged(event.target.value)}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>

						<fieldset className="PropertyMinFieldset">
							<legend className="PropertyMinPartLegend">{this.props.getLang(PartLang, "subject")}</legend> 
							<input
								className="PropertyMinPartInput" 
								id="subject" 
								type="text" 
								value={this.state.subject}
								onChange={(event) => this.onSubjectChanged(event.target.value)}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>

						<fieldset className="PropertyMinFieldset">
							<legend className="PropertyMinPartLegend">{this.props.getLang(PartLang, "emailText")}</legend> 
							<textarea
								className="PropertyMinPartInput" 
								id="emailText" 
								value={this.state.emailText}
								rows={10}
								onChange={(event) => this.onEmailTextChanged(event.target.value)}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>

						<fieldset className="PropertyMinFieldset">
							<legend className="PropertyMinPartLegend">{this.props.getLang(PartLang, "emailList")}</legend> 
							<textarea
								className="PropertyMinPartInput" 
								id="emailList" 
								value={this.state.emailList}
								rows={10}
								onChange={(event) => this.onEmailListChanged(event.target.value)}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>

					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(PartLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(PartLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function PropertyMinFunction(props) {
	const navigate = useNavigate();
	return <PropertyMin {...props} navigate={navigate} />;
}


