import * as React from 'react';
import './App.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import moment from 'moment';
import {storeEmailPassword} from './LoginLocalStorage.js';
import AppLang from './AppLang.json';

import Topbar from './common/Topbar.js';
import Login from './Login.js';
import FancyTree from './FancyTree.js';
import PartMain from './PartMain.js';
import PartNew from './PartNew.js';
import PartUpdate from './PartUpdate.js';
import PropertyList from './PropertyList.js';
import PropertyNew from './PropertyNew.js';
import PropertyUpdate from './PropertyUpdate.js';
import PropertyCode from './PropertyCode.js';
import PropertyTrigger from './PropertyTrigger.js';
import PropertyMin from './PropertyMin.js';
import ChangeLog from './ChangeLog.js';
import SelectProject from './SelectProject.js';
import Reports from './Reports.js';
import TaskList from './TaskList.js';
import TaskMain from './TaskMain.js';
import TaskNew from './TaskNew.js';
import MilestoneMain from './MilestoneMain.js';
import MilestoneCellEdit from './MilestoneCellEdit.js';
import ReportTaskDaily from './ReportTaskDaily.js';
import ReportTaskMonthly from './ReportTaskMonthly.js';
import MachineList from './MachineList.js';
import About from './About.js';

class App extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			//baseURLServer: "http://localhost:3000",
			baseURLServer: "https://production.plurato.com",
			baseURLAPI: "https://productionapi.plurato.com",
			managementBaseURLAPI: "https://managementapi.plurato.com",
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			fullName: "",
			phone: "",
			language: "en",
			companyID: 0,
			managementAppUserType: 0,
			productionAppUserType: 0,
			locationID: 0,
			createdTimestamp: "",
			selectedCompanyID: 0,
			selectedCompanyName: "",
			selectedPartID: 0,
			selectedPartName: "",
			selectedPartPropertyID: 0,
			selectedProjectID: 0,
			selectedProjectName: "",
			partTreeData: null,
			partTreeDataFancy: null,
			treeLoadCount: 0,
			selectedTaskID: 0,
			recycleTaskID: 0,
			newTaskPartID: 0,
			newTaskPartName: "",
			newTaskPropertyID: 0,
			newTaskPropertyName: "",
			myTasksYN: true,
			taskListIndex: 0,
			milestoneID: 0,
			milestoneRowID: 0,
			milestoneRowName: "",
			milestoneYear: 0,
			milestoneMonth: 0,
			userListJsonObj: null,
			firstLoadYN: true,
		}

		this.serverGetUserDataFromSessionID = this.serverGetUserDataFromSessionID.bind(this);
		this.getSessionIDFromURLString = this.getSessionIDFromURLString.bind(this);
		this.showToast = this.showToast.bind(this);
		this.logoutClearVariables = this.logoutClearVariables.bind(this);
		this.serverLogout = this.serverLogout.bind(this);
		this.serverGetUserData = this.serverGetUserData.bind(this);
		this.updateSettingsSelectedProjectID = this.updateSettingsSelectedProjectID.bind(this);
		this.serverGetSettingsData = this.serverGetSettingsData.bind(this);
		this.serverGetProjectData = this.serverGetProjectData.bind(this);
		this.serverGetPartsFromParentIDReturnResult = this.serverGetPartsFromParentIDReturnResult.bind(this);
		this.serverGetPartsFromParentID = this.serverGetPartsFromParentID.bind(this);
		this.serverGetPartsTreeData = this.serverGetPartsTreeData.bind(this);
		this.setSessionID = this.setSessionID.bind(this);
		this.setUserID = this.setUserID.bind(this);
		this.setSelectedPartIDAndName = this.setSelectedPartIDAndName.bind(this);
		this.setSelectedPartPropertyID = this.setSelectedPartPropertyID.bind(this);
		this.setSelectedProjectIDAndName = this.setSelectedProjectIDAndName.bind(this);
		this.setSelectedTaskID = this.setSelectedTaskID.bind(this);
		this.setRecycleTaskID = this.setRecycleTaskID.bind(this);
		this.setMyTasksYN = this.setMyTasksYN.bind(this);
		this.setTaskListIndex = this.setTaskListIndex.bind(this);		
		this.setNewTaskPartAndProperty = this.setNewTaskPartAndProperty.bind(this);
		this.setMilestoneData = this.setMilestoneData.bind(this);		
		this.separateTextIntoArrayOfLines = this.separateTextIntoArrayOfLines.bind(this);
		this.convertTextNewlineToBr = this.convertTextNewlineToBr.bind(this);
		this.getDateStringFromUnixTimeSec = this.getDateStringFromUnixTimeSec.bind(this);
		this.getTimeStringFromUnixTimeSec = this.getTimeStringFromUnixTimeSec.bind(this);
		this.getDateTimeStringFromUnixTimeSec = this.getDateTimeStringFromUnixTimeSec.bind(this);
		this.getTimeStringFromSeconds = this.getTimeStringFromSeconds.bind(this);
		this.getMonthString = this.getMonthString.bind(this);
		this.getMonthStringShort = this.getMonthStringShort.bind(this);
		this.getFullNameForUserID = this.getFullNameForUserID.bind(this);
		this.serverGetUserList = this.serverGetUserList.bind(this);
		this.setLanguage = this.setLanguage.bind(this);
		this.getLang = this.getLang.bind(this);
		this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
	}
	
	componentDidMount() {

		document.title = "Plurato Production"

		var windowLocation = window.location;
		var sessionID = this.getSessionIDFromURLString(windowLocation.toString());

		if (sessionID === "") {
			this.setState({firstLoadYN: false});
			return;
		}
		
		this.serverGetUserDataFromSessionID(sessionID);
		this.setState({
			sessionID: sessionID,
		});
	}

	async serverGetUserDataFromSessionID(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdatafromsessionid?sid=" + sessionID;

		try {
			const res = await fetch(url);
			const jsonObj = await res.json();

			var userID = jsonObj.userID;
			var email = jsonObj.email;
			var firstName = jsonObj.firstName;
			var lastName = jsonObj.lastName;
			var phone = jsonObj.phone;
			var language = jsonObj.language;
			var managementAppUserType = jsonObj.managementAppUserType;
			var productionAppUserType = jsonObj.productionAppUserType;
			var companyID = jsonObj.companyID;
			var createdTimestamp = jsonObj.createdTimestamp;
			
			var fullName = firstName + " " + lastName;
			fullName = fullName.trim();
			if (fullName === "") {
				fullName = email;
			}
			
			this.setState({
				userID: userID,
				email: email,
				firstName: firstName,
				lastName: lastName,
				fullName: fullName,
				phone: phone,
				language: language,
				managementAppUserType: managementAppUserType,
				productionAppUserType: productionAppUserType,
				companyID: companyID,
				createdTimestamp: createdTimestamp,
				firstLoadYN: false,
			});

			this.serverGetSettingsData(userID);
			this.serverGetUserList(companyID);

		} catch (e) {
			this.setState({firstLoadYN: false});
		}
	}
	
	getSessionIDFromURLString(urlString) {

		var i;
		var index;
		var length;
		var foundYN;
		var sessionID = "";
		
		length = urlString.length;
		foundYN = false;
		index = 0;
		
		// find sid=
		for(i=0; (i<length) && (!foundYN); i++) {
			if (urlString.substr(i, 4) === "sid=") {
				foundYN = true;
				index = i+4;
			}
		}
		
		if (!foundYN) {
			return "";
		}
		
		sessionID = urlString.substr(index, length-index);
		return sessionID;
	}

	showToast(messageText) {
		toast(messageText, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}
	
	logoutClearVariables() {

		var sessionID = this.state.sessionID;
		this.serverLogout(sessionID);

		this.setState({
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			fullName: "",
			phone: "",
			managementAppUserType: 0,
			productionAppUserType: 0,
			companyID: 0,
			createdTimestamp: "",
			selectedCompanyID: 0,
			selectedCompanyName: "",
			selectedPartID: 0,
			selectedPartName: "",
			selectedPartPropertyID: 0,
			selectedProjectID: 0,
			selectedProjectName: "",
		});

		storeEmailPassword("", "");
	}

	async serverLogout(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/logout?sid=" + sessionID;

		const res = await fetch(url);
		await res.text();
	}

	async serverGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var sessionID = this.state.sessionID;
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdata?sid=" + sessionID + "&userid=" + userID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var email = jsonObj.email;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var phone = jsonObj.phone;
		var language = jsonObj.language;
		var managementAppUserType = jsonObj.managementAppUserType;
		var productionAppUserType = jsonObj.productionAppUserType;
		var companyID = jsonObj.companyID;
		var createdTimestamp = jsonObj.createdTimestamp;

		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		if (fullName === "") {
			fullName = email;
		}
		
		this.setState({
			email: email,
			firstName: firstName,
			lastName: lastName,
			fullName: fullName,
			phone: phone,
			language: language,
			managementAppUserType: managementAppUserType,
			productionAppUserType: productionAppUserType,
			companyID: companyID,
			createdTimestamp: createdTimestamp,
		});
		
		this.serverGetUserList(companyID);
		this.serverGetSettingsData(userID);
	}
	
	async updateSettingsSelectedProjectID(userID, selectedProjectID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/updatesettingsselectedprojectid?userid=" + userID + "&selectedprojectid=" + selectedProjectID;

		var res = await fetch(url);
		await res.text();
	}
	
	async serverGetSettingsData(userID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getsettingsdata?userid=" + userID;

		var res = await fetch(url);
		var responseText = await res.text();
		var selectedProjectID = 0;

		if (responseText === "") {
			selectedProjectID = 1;
			this.updateSettingsSelectedProjectID(userID, selectedProjectID);
		} else {
			var jsonObj = JSON.parse(responseText);
			selectedProjectID = jsonObj.selectedProjectID;
		}
		
		this.setState({
			selectedProjectID: selectedProjectID,
		});
		
		this.serverGetProjectData(selectedProjectID);
		this.serverGetPartsTreeData(selectedProjectID);
	}
	
	async serverGetProjectData(projectID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getprojectdata?projectid=" + projectID;

		var res = await fetch(url);
		const jsonObj = await res.json();

		var projectName = jsonObj.projectName;

		this.setState({
			selectedProjectName: projectName,
		});
	}
	
	serverGetPartsFromParentIDReturnResult(jsonObj, parentID, projectID, partTreeData, partTreeDataFancy) {

		var i;
		var length = jsonObj.length;
		var itemObj;
		var dataItemObj;
		var dataItemFancyObj;
		var attributesObj;
		
		for(i=0; i<length; i++) {
		
			itemObj = jsonObj[i];

			var partID = itemObj.partID;
			var partName = itemObj.partName;
			
			dataItemObj = {};
			dataItemObj.title = partName;
			dataItemObj.key = partID;
			dataItemObj.children = [];
			
			attributesObj = {};
			//attributesObj.Count = 0;

			dataItemFancyObj = {};
			dataItemFancyObj.name = partName;
			dataItemFancyObj.partID = partID;
			dataItemFancyObj.attributes = attributesObj;
			dataItemFancyObj.children = [];

			this.serverGetPartsFromParentID(partID, projectID, dataItemObj.children, dataItemFancyObj.children, this.serverGetPartsFromParentIDReturnResult);
			
			partTreeData.push(dataItemObj);	
			partTreeDataFancy.push(dataItemFancyObj);
		}

		var treeLoadCount = this.state.treeLoadCount;
		treeLoadCount = treeLoadCount - 1;
		this.setState({treeLoadCount: treeLoadCount});
	}

	async serverGetPartsFromParentID(parentID, projectID, partTreeData, partTreeDataFancy, serverGetPartsFromParentIDReturnResult) {

		var treeLoadCount = this.state.treeLoadCount;
		treeLoadCount = treeLoadCount + 1;
		this.setState({treeLoadCount: treeLoadCount});

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getpartlist?parentid=" + parentID + "&projectid=" + projectID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		this.serverGetPartsFromParentIDReturnResult(jsonObj, parentID, projectID, partTreeData, partTreeDataFancy);
	}
	
	serverGetPartsTreeData(selectedProjectID) {

		var partTreeData = [];
		var partTreeDataFancy = {};

		partTreeDataFancy.name = "Top"
		partTreeDataFancy.partID = 0;
		partTreeDataFancy.children = [];

		this.serverGetPartsFromParentID(0, selectedProjectID, partTreeData, partTreeDataFancy.children, this.serverGetPartsFromParentIDReturnResult);
		this.setState({
			partTreeData: partTreeData,
			partTreeDataFancy: partTreeDataFancy,
		});
	}
	
	setSessionID(sessionID) {
		this.setState({sessionID: sessionID});
	}
	
	setUserID(userID) {
		this.setState({userID: userID});
	}
	
	setSelectedPartIDAndName(selectedPartID, selectedPartName) {
		this.setState({
			selectedPartID: selectedPartID,
			selectedPartName: selectedPartName,
		});
	}
	
	setSelectedPartPropertyID(selectedPartPropertyID) {
		this.setState({selectedPartPropertyID: selectedPartPropertyID});
	}
	
	setSelectedProjectIDAndName(selectedProjectID, selectedProjectName) {
		this.setState({
			selectedProjectID: selectedProjectID,
			selectedProjectName: selectedProjectName,
		});
		this.setSelectedPartIDAndName(0, "");
		var userID = this.state.userID;
		this.updateSettingsSelectedProjectID(userID, selectedProjectID);
	}
	
	setSelectedTaskID(selectedTaskID) {
		this.setState({selectedTaskID: selectedTaskID});
	}
	
	setRecycleTaskID(recycleTaskID) {
		this.setState({recycleTaskID: recycleTaskID});
	}
	
	setMyTasksYN(myTasksYN) {
		this.setState({myTasksYN: myTasksYN});		
	}
	
	setTaskListIndex(taskListIndex) {
		this.setState({taskListIndex: taskListIndex});		
	}
	
	setNewTaskPartAndProperty(newTaskPartID, newTaskPartName, newTaskPropertyID, newTaskPropertyName) {
		this.setState({
			newTaskPartID: newTaskPartID,
			newTaskPartName: newTaskPartName,
			newTaskPropertyID: newTaskPropertyID,
			newTaskPropertyName: newTaskPropertyName,
		});
	}
	
	setMilestoneData(milestoneID, milestoneRowID, rowName, year, month) {
		this.setState({
			milestoneID: milestoneID,
			milestoneRowID: milestoneRowID,
			milestoneRowName: rowName,
			milestoneYear: year,
			milestoneMonth: month,
		});
	}

	separateTextIntoArrayOfLines(inputText) {

		var lineArray = [];

		if (inputText === null) {
			inputText = "";
		}

		if (inputText === "") {
			return lineArray;
		}

		var i;
		var ch;
		var len = inputText.length;
		var oneLineText = "";
		
		for(i=0; i<len; i++) {
			ch = inputText[i];
			if (ch === "\n") {
				lineArray.push(oneLineText);
				oneLineText = "";
			} else {
				oneLineText = oneLineText + ch;
			}
		}
		
		if (oneLineText !== "") {
			lineArray.push(oneLineText);
		}

		return lineArray;
	}

	convertTextNewlineToBr(inputText, variable, className) {
		
		if (inputText === null) {
			inputText = "";
		}
		
		var lineArray = this.separateTextIntoArrayOfLines(inputText);
		var jsxArray = [];
		
		var i;
		var len = lineArray.length;
		var oneLineText;
		var key;
		
		for(i=0; i<len; i++) {
			key = variable + "-" + i;
			oneLineText = lineArray[i];
			jsxArray.push(<div key={key} className={className}>{oneLineText}</div>);
		}
		
		return (<div>{jsxArray}</div>);
	}

	getDateStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var dateString = moment(dateObj).format('DD.MM.YYYY');
		return dateString;
	}

	getTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var timeString = moment(dateObj).format('HH:mm');
		return timeString;
	}

	getDateTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var currentDateObj = new Date();
		var dateObj = new Date(unixTimeSec * 1000);
		
		var year1 = currentDateObj.getFullYear();
		var year2 = dateObj.getFullYear();
		
		var dateTimeString;
		if (year1 === year2) {
			dateTimeString = moment(dateObj).format('DD/MM HH:mm');
		} else {
			dateTimeString = moment(dateObj).format('DD.MM.YYYY HH:mm');
		}

		return dateTimeString;
	}

	getTimeStringFromSeconds(seconds) {
		
		if (seconds === null) {
			return "";
		}

		var secondsLeft = 0;
		var hours = 0;
		var minutes = 0;
		var timeString = "";
		
		secondsLeft = seconds;
		
		hours = Math.floor(secondsLeft / 3600);
		secondsLeft = secondsLeft - hours * 3600;
		
		minutes = Math.floor(secondsLeft / 60);
		secondsLeft = secondsLeft - minutes * 60;
		
		if (hours > 0) {
			timeString = hours + "h ";
		}
		
		if (minutes > 0) {
			timeString = timeString + minutes + "m ";
		}
		
		if ((hours === 0) && (minutes === 0)) {
			timeString = timeString + secondsLeft + "s";
		}

		return timeString;
	}
	
	getMonthString(month) {
		var monthNames = ["january", "february", "march", "april", "mayFull", "june", "july", "august", "september", "october", "november", "december"];
		var monthString = monthNames[month-1];
		var monthStringLanguage = this.getLang(AppLang, monthString);
		return monthStringLanguage;
	}
	
	getMonthStringShort(month) {
		var monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
		var monthString = monthNames[month-1];
		var monthStringLanguage = this.getLang(AppLang, monthString);
		return monthStringLanguage;
	}
	
	async serverGetUserList(companyID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserlist?companyid=" + companyID;
		url = url + "&sortby=email&sortasc=1&index=0&count=1000";

		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			userListJsonObj: jsonObj,
		});		
	}
	
	getFullNameForUserID(userID) {
		
		var userListJsonObj = this.state.userListJsonObj;
		if (userListJsonObj === null) {
			return "";
		}
		
		var i;
		var itemObj;
		var userID2;
		var firstName;
		var lastName;
		var email;
		var fullName;
		
		for(i=0; i<userListJsonObj.length; i++) {
			itemObj = userListJsonObj[i];
			userID2 = itemObj.userID;
			if (userID === userID2) {
				firstName = itemObj.firstName;
				lastName = itemObj.lastName;
				email = itemObj.email;
				fullName = firstName + " " + lastName;
				fullName = fullName.trim();
				if (fullName === "") {
					fullName = email;
				}
				return fullName;
			}
		}
		
		return "";
	}

	getLang(lang, id) {
		var language = this.state.language;
		var id2 = id + "." + language;
		var i;
		var id3;
		var length = lang.length;
		var itemObj;
		var keys;
		var returnString;
		for(i=0; i<length; i++) {
			itemObj = lang[i];
			var keys = Object.keys(itemObj);
			id3 = keys[0];
			if (id2 === id3) {
				returnString = itemObj[id2];
				return returnString;
			}
		}
		return "";
	}
	
	setLanguage(language) {
		this.setState({language: language});
	}
	
	checkIfLoggedIn() {
				
		var pathname = window.location.pathname;
		
		if (pathname === "/") {
			return;
		}

		var userID = this.state.userID;
		if (userID !== 0) {
			return;
		}
		
		var url = "/";
		window.location.href = url;
	}
	
	render() {

		var pathname = document.location.pathname;
		if (pathname === "/directreports/tasks/daily") {
			return (
				<div className="App">
					<ReportTaskDaily
						baseURLAPI={this.state.baseURLAPI}
						managementBaseURLAPI={this.state.managementBaseURLAPI}
						productionAppUserType={0}
						userID={0}
						companyID={0}
						userListJsonObj={this.state.userListJsonObj}
						showToast={this.showToast}
						getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
						getTimeStringFromUnixTimeSec={this.getTimeStringFromUnixTimeSec}
						getTimeStringFromSeconds={this.getTimeStringFromSeconds}
						setSelectedTaskID={null}
						serverGetUserList={this.serverGetUserList}
						directLinkYN={true}
						getLang={this.getLang}
					/>
				</div>
			);
		}

		this.checkIfLoggedIn();
		
		var firstLoadYN = this.state.firstLoadYN;
		if (firstLoadYN) {
			return null;
		}

		var loggedInYN;
		var userID = this.state.userID;
		
		if (userID === 0) {
			loggedInYN = false;
		} else {
			loggedInYN = true;
		}
				
		if (loggedInYN) {
			var selectedProjectID = this.state.selectedProjectID;
			if (selectedProjectID === 0) {
				return null;
			}
		}

		return (
		<Router>
			<div className="App">
				<ToastContainer />
				<Topbar 
					managementBaseURLAPI={this.state.managementBaseURLAPI}
					userID={this.state.userID}
					email={this.state.email}
					firstName={this.state.firstName}
					lastName={this.state.lastName}
					sessionID={this.state.sessionID}
					appName="PRODUCTION"
					bkColor1="#4c4c4c"
					bkColor2="#f0f0f0"
					productionAppUserType={this.state.productionAppUserType}
					logoutClearVariables={this.logoutClearVariables}
					getLang={this.getLang}
					setLanguage={this.setLanguage}
				/>
        		<Routes>
          			<Route 
						path="/"
						element={
							<div>
							{!loggedInYN && (
								<Login
									baseURLAPI={this.state.baseURLAPI}
									managementBaseURLAPI={this.state.managementBaseURLAPI}
									setSessionID={this.setSessionID}
									setUserID={this.setUserID}
									serverGetUserData={this.serverGetUserData}
									getLang={this.getLang}
								/>
							)}
							{loggedInYN && (
								<ChangeLog
									baseURLAPI={this.state.baseURLAPI}
									productionAppUserType={this.state.productionAppUserType}
									selectedProjectID={this.state.selectedProjectID}
									selectedProjectName={this.state.selectedProjectName}
									showToast={this.showToast}
									setSelectedPartIDAndName={this.setSelectedPartIDAndName}
									getLang={this.getLang}
								/>
							)}
							</div>
						}
					/>
          			<Route 
						path="/login"
						element={
							<Login
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								setSessionID={this.setSessionID}
								setUserID={this.setUserID}
								serverGetUserData={this.serverGetUserData}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/changelog"
						element={
							<ChangeLog
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedProjectID={this.state.selectedProjectID}
								selectedProjectName={this.state.selectedProjectName}
								showToast={this.showToast}
								setSelectedPartIDAndName={this.setSelectedPartIDAndName}
								getLang={this.getLang}
							/>
						}
					/>					
         			<Route 
						path="/parts"
						element={
							<PartMain
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								userID={this.state.userID}
								email={this.state.email}
								firstName={this.state.firstName}
								lastName={this.state.lastName}
								companyID={this.state.companyID}
								productionAppUserType={this.state.productionAppUserType}
								partTreeData={this.state.partTreeData}
								treeLoadCount={this.state.treeLoadCount}
								selectedPartID={this.state.selectedPartID}
								selectedPartName={this.state.selectedPartName}
								selectedProjectID={this.state.selectedProjectID}
								selectedProjectName={this.state.selectedProjectName}
								setSelectedPartIDAndName={this.setSelectedPartIDAndName}
								setNewTaskPartAndProperty={this.setNewTaskPartAndProperty}
								setRecycleTaskID={this.setRecycleTaskID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/setup/parts/new"
						element={
							<PartNew
								baseURLAPI={this.state.baseURLAPI}
								userID={this.state.userID}
								productionAppUserType={this.state.productionAppUserType}
								selectedPartID={this.state.selectedPartID}
								selectedPartName={this.state.selectedPartName}
								selectedProjectID={this.state.selectedProjectID}
								showToast={this.showToast}
								serverGetPartsTreeData={this.serverGetPartsTreeData}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/setup/parts/update"
						element={
							<PartUpdate
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedPartID={this.state.selectedPartID}
								selectedPartName={this.state.selectedPartName}
								selectedProjectID={this.state.selectedProjectID}
								showToast={this.showToast}
								serverGetPartsTreeData={this.serverGetPartsTreeData}
								setSelectedPartIDAndName={this.setSelectedPartIDAndName}	
								getLang={this.getLang}
							/>
						}
					/>					
         			<Route 
						path="/setup/properties"
						element={
							<PropertyList
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedPartID={this.state.selectedPartID}
								selectedPartName={this.state.selectedPartName}
								selectedProjectID={this.state.selectedProjectID}
								selectedProjectName={this.state.selectedProjectName}
								setSelectedPartPropertyID={this.setSelectedPartPropertyID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/setup/properties/new"
						element={
							<PropertyNew
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedPartID={this.state.selectedPartID}
								selectedPartName={this.state.selectedPartName}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>					
         			<Route 
						path="/setup/properties/update"
						element={
							<PropertyUpdate
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedPartID={this.state.selectedPartID}
								selectedPartName={this.state.selectedPartName}
								selectedPartPropertyID={this.state.selectedPartPropertyID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/setup/properties/code"
						element={
							<PropertyCode
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedPartID={this.state.selectedPartID}
								selectedPartName={this.state.selectedPartName}
								selectedPartPropertyID={this.state.selectedPartPropertyID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/setup/properties/trigger"
						element={
							<PropertyTrigger
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedPartID={this.state.selectedPartID}
								selectedPartPropertyID={this.state.selectedPartPropertyID}
								partTreeData={this.state.partTreeData}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/setup/properties/min"
						element={
							<PropertyMin
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedPartID={this.state.selectedPartID}
								selectedPartPropertyID={this.state.selectedPartPropertyID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/tree"
						element={
							<FancyTree
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedProjectID={this.state.selectedProjectID}
								selectedProjectName={this.state.selectedProjectName}
								partTreeDataFancy={this.state.partTreeDataFancy}
								setSelectedPartIDAndName={this.setSelectedPartIDAndName}	
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/selectproject"
						element={
							<SelectProject
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								companyID={this.state.companyID}
								setSelectedProjectIDAndName={this.setSelectedProjectIDAndName}
								serverGetPartsTreeData={this.serverGetPartsTreeData}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/milestones"
						element={
							<MilestoneMain
								baseURLAPI={this.state.baseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								userID={this.state.userID}
								email={this.state.email}
								fullName={this.state.fullName}
								companyID={this.state.companyID}
								selectedProjectID={this.state.selectedProjectID}
								selectedProjectName={this.state.selectedProjectName}
								showToast={this.showToast}
								getMonthString={this.getMonthString}
								getMonthStringShort={this.getMonthStringShort}
								setMilestoneData={this.setMilestoneData}
								separateTextIntoArrayOfLines={this.separateTextIntoArrayOfLines}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getFullNameForUserID={this.getFullNameForUserID}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/milestones/edit"
						element={
							<MilestoneCellEdit
								baseURLAPI={this.state.baseURLAPI}
								userID={this.state.userID}
								email={this.state.email}
								fullName={this.state.fullName}
								companyID={this.state.companyID}
								selectedProjectID={this.state.selectedProjectID}
								selectedProjectName={this.state.selectedProjectName}
								milestoneID={this.state.milestoneID}
								milestoneRowID={this.state.milestoneRowID}
								milestoneRowName={this.state.milestoneRowName}
								milestoneYear={this.state.milestoneYear}
								milestoneMonth={this.state.milestoneMonth}
								showToast={this.showToast}
								getMonthString={this.getMonthString}
								getMonthStringShort={this.getMonthStringShort}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/reports"
						element={
							<Reports
								baseURLAPI={this.state.baseURLAPI}
								selectedProjectID={this.state.selectedProjectID}
								selectedProjectName={this.state.selectedProjectName}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/tasks"
						element={
							<TaskList
								baseURLAPI={this.state.baseURLAPI}
								userID={this.state.userID}
								companyID={this.state.companyID}
								productionAppUserType={this.state.productionAppUserType}
								selectedProjectID={this.state.selectedProjectID}
								selectedProjectName={this.state.selectedProjectName}
								setSelectedTaskID={this.setSelectedTaskID}
								myTasksYN={this.state.myTasksYN}
								taskListIndex={this.state.taskListIndex}
								showToast={this.showToast}
								getTimeStringFromSeconds={this.getTimeStringFromSeconds}
								setRecycleTaskID={this.setRecycleTaskID}
								setMyTasksYN={this.setMyTasksYN}
								setTaskListIndex={this.setTaskListIndex}
								setNewTaskPartAndProperty={this.setNewTaskPartAndProperty}
								getLang={this.getLang}
							/>
						}
					/>										
          			<Route 
						path="/task"
						element={
							<TaskMain
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								selectedTaskID={this.state.selectedTaskID}
								userID={this.state.userID}
								email={this.state.email}
								fullName={this.state.fullName}
								showToast={this.showToast}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								getDateStringFromUnixTimeSec={this.getDateStringFromUnixTimeSec}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getTimeStringFromSeconds={this.getTimeStringFromSeconds}
								setRecycleTaskID={this.setRecycleTaskID}
								setSelectedPartIDAndName={this.setSelectedPartIDAndName}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/tasks/new"
						element={
							<TaskNew
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								userID={this.state.userID}
								fullName={this.state.fullName}
								selectedProjectID={this.state.selectedProjectID}
								companyID={this.state.companyID}
								recycleTaskID={this.state.recycleTaskID}
								newTaskPartID={this.state.newTaskPartID}
								newTaskPartName={this.state.newTaskPartName}
								newTaskPropertyID={this.state.newTaskPropertyID}
								newTaskPropertyName={this.state.newTaskPropertyName}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/reports/tasks/daily"
						element={
							<ReportTaskDaily
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								userID={this.state.userID}
								companyID={this.state.companyID}
								userListJsonObj={this.state.userListJsonObj}
								showToast={this.showToast}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getTimeStringFromUnixTimeSec={this.getTimeStringFromUnixTimeSec}
								getTimeStringFromSeconds={this.getTimeStringFromSeconds}
								setSelectedTaskID={this.setSelectedTaskID}
								directLinkYN={false}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/reports/tasks/monthly"
						element={
							<ReportTaskMonthly
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								productionAppUserType={this.state.productionAppUserType}
								userID={this.state.userID}
								fullName={this.state.fullName}
								companyID={this.state.companyID}
								userListJsonObj={this.state.userListJsonObj}
								showToast={this.showToast}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getTimeStringFromSeconds={this.getTimeStringFromSeconds}
								setSelectedTaskID={this.setSelectedTaskID}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/machines"
						element={
							<MachineList
								baseURLAPI={this.state.baseURLAPI}
								companyID={this.state.companyID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
          			/>					
          			<Route 
						path="/about"
						element={
							<About
								baseURLAPI={this.state.baseURLAPI}
								getLang={this.getLang}
							/>
						}
          			/>

        		</Routes>
			</div>
    		</Router>
		);
	}
}

export default App;

