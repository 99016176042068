import React  from 'react';
import './App.css';
import TaskLang from './TaskLang.json';

import { useNavigate } from "react-router-dom";

import UserPicker from './UserPicker.js';

class TaskNew extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			taskName: "",
			myTaskYN: true,
			sendEmailExecutorYN: false,
			sendEmailSelfYN: false,
			executorUserID: 0,
			userFullName: "",
			notes: "",
			partID: 0,
			partName: "",
			partPropertyID: 0,
			partPropertyName: "",
			dataLoadedYN: false,
		};

		this.serverGetTaskData = this.serverGetTaskData.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onTaskNameChanged = this.onTaskNameChanged.bind(this);
		this.onMyTaskYNCheckboxClick = this.onMyTaskYNCheckboxClick.bind(this);
		this.onSendEmailExecutorYNCheckboxClick = this.onSendEmailExecutorYNCheckboxClick.bind(this);
		this.onSendEmailSelfYNCheckboxClick = this.onSendEmailSelfYNCheckboxClick.bind(this);
		this.onExecutorUserIDChanged = this.onExecutorUserIDChanged.bind(this);
		this.onNotesChanged = this.onNotesChanged.bind(this);
		this.serverInsertTaskRecord = this.serverInsertTaskRecord.bind(this);
		this.serverSendEmail = this.serverSendEmail.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		var recycleTaskID = this.props.recycleTaskID;
		if (recycleTaskID > 0) {
			this.serverGetTaskData(recycleTaskID);
		} else {
			this.setState({dataLoadedYN: true});
		}
	}
		
	async serverGetTaskData(taskID) {

		if (taskID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/gettaskdata?taskid=" + taskID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var taskName = jsonObj.taskName;
		var partID = jsonObj.partID;
		var partName = jsonObj.partName;
		var partPropertyID = jsonObj.partPropertyID;
		var partPropertyName = jsonObj.partPropertyName;
		
		this.setState({
			taskName: taskName,
			partID: partID,
			partName: partName,
			partPropertyID: partPropertyID,
			partPropertyName: partPropertyName,
			dataLoadedYN: true,
		});
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onTaskNameChanged(value) {
		var taskName = value.trim();
		this.setState({taskName: taskName});
	}
	
	onMyTaskYNCheckboxClick(event) {
		var target = event.target;
		var value = target.checked;
		this.setState({myTaskYN: value});
	}
	
	onSendEmailExecutorYNCheckboxClick(event) {
		var target = event.target;
		var value = target.checked;
		this.setState({sendEmailExecutorYN: value});
	}
	
	onSendEmailSelfYNCheckboxClick(event) {
		var target = event.target;
		var value = target.checked;
		this.setState({sendEmailSelfYN: value});
	}
	
	onExecutorUserIDChanged(userID, userName) {
		this.setState({
			executorUserID: userID,
			userFullName: userName,
		});
	}

	onNotesChanged(value) {
		this.setState({notes: value});
	}
	
	async serverInsertTaskRecord(taskName, userID, userFullName, createdUserID, createdUserFullName, notes, partID, partName, partPropertyID, partPropertyName, projectID, companyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/inserttaskrecord?taskname=" + encodeURIComponent(taskName);
		url = url + "&userid=" + userID;
		url = url + "&userfullname=" + encodeURIComponent(userFullName);
		url = url + "&createduserid=" + createdUserID;
		url = url + "&createduserfullname=" + encodeURIComponent(createdUserFullName);
		url = url + "&notes=" + encodeURIComponent(notes);
		url = url + "&partid=" + partID;
		url = url + "&partname=" + encodeURIComponent(partName);
		url = url + "&partpropertyid=" + partPropertyID;
		url = url + "&partpropertyname=" + encodeURIComponent(partPropertyName);
		url = url + "&projectid=" + projectID;
		url = url + "&companyid=" + companyID;
		
		const res = await fetch(url);
		const responseText = await res.text();

		const navigate = this.props.navigate;
  		navigate("/tasks");
	}
	
	async serverSendEmail(userID, taskName, executorFullName, createdUserFullName, notes, partName, propertyName) {

		var subject;
		var emailText;

		subject = this.props.getLang(TaskLang, "newTask") + ": \"" + taskName + "\""; 

		emailText = "";
		emailText = emailText + "Task name: " + taskName + "\n";
		emailText = emailText + "Executor: " + executorFullName + "\n";
		emailText = emailText + "Created by: " + createdUserFullName + "\n";
		if (partName !== "") {
			emailText = emailText + "Part / property: " + partName + " / " + propertyName + "\n";
		}
		emailText = emailText + "\n";
		emailText = emailText + "Notes:" + "\n";
		emailText = emailText + notes + "\n";

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/sendemail?userid=" + userID;
		url = url + "&subject=" + encodeURIComponent(subject);
		url = url + "&emailtext=" + encodeURIComponent(emailText);
		
		const res = await fetch(url);
		await res.text();
	}

	async onClickSaveButton() {
		
		var executorUserID = this.state.executorUserID;
		var taskName = this.state.taskName;
		if (taskName === "") {
			taskName = 0;
		}
		
		if (taskName === 0) {
			this.props.showToast(this.props.getLang(TaskLang, "taskNameEmpty"));
			return;
		}
		
		if (!this.state.myTaskYN) {
			if (executorUserID === 0) {
				this.props.showToast(this.props.getLang(TaskLang, "executorNotSelected"));
				return;
			}
		}

		var userFullName = this.state.userFullName;
		var createdUserID = this.props.userID;
		var createdUserFullName = this.props.fullName;
		var notes = this.state.notes;
		var projectID = this.props.selectedProjectID;
		var companyID = this.props.companyID;
		var partID = this.props.newTaskPartID;
		var partName = this.props.newTaskPartName;
		var propertyID = this.props.newTaskPropertyID;
		var propertyName = this.props.newTaskPropertyName;
		var sendEmailExecutorYN = this.state.sendEmailExecutorYN;
		var sendEmailSelfYN = this.state.sendEmailSelfYN;

		var recycleTaskID = this.props.recycleTaskID;
		if (recycleTaskID > 0) {
			partID = this.state.partID;
			partName = this.state.partName;
			propertyID = this.state.partPropertyID;
			propertyName = this.state.partPropertyName;
		}

		if (this.state.myTaskYN) {
			executorUserID = this.props.userID;
			userFullName = this.props.fullName;
		}
		
		this.serverInsertTaskRecord(taskName, executorUserID, userFullName, createdUserID, createdUserFullName, notes, partID, partName, propertyID, propertyName, projectID, companyID);
		if (sendEmailExecutorYN) {
			this.serverSendEmail(executorUserID, taskName, userFullName, createdUserFullName, notes, partName, propertyName);
		}
		if (sendEmailSelfYN) {
			this.serverSendEmail(createdUserID, taskName, userFullName, createdUserFullName, notes, partName, propertyName);
		}
		
		this.props.showToast(this.props.getLang(TaskLang, "newTaskCreated") + ": " + taskName);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/tasks");
	}
	
	renderPartProperty(newTaskPartID, newTaskPartName, newTaskPropertyID, newTaskPropertyName) {
		
		if (newTaskPartID === 0) {
			return null;
		}
		
		return (
			<div>
				<fieldset className="ViewItemFieldset">
					<legend className="ViewItemLegend">{this.props.getLang(TaskLang, "partProperty")}</legend> 
					<div className="ViewItemInput">{newTaskPartName} / {newTaskPropertyName}</div>
				</fieldset>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
	
	render() {

		if (!this.state.dataLoadedYN) {
			return null;
		}
		
		var taskName = this.state.taskName;
		var partID = this.props.newTaskPartID;
		var partName = this.props.newTaskPartName;
		var propertyID = this.props.newTaskPropertyID;
		var propertyName = this.props.newTaskPropertyName;
		
		var recycleTaskID = this.props.recycleTaskID;
		if (recycleTaskID > 0) {
			partID = this.state.partID;
			partName = this.state.partName;
			propertyID = this.state.partPropertyID;
			propertyName = this.state.partPropertyName;
		}
		
		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(TaskLang, "newTask")}</div>
				<div className="ViewTopText">{this.props.getLang(TaskLang, "newTaskInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(TaskLang, "taskInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "20px"}}></div>

						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(TaskLang, "taskName")}</legend> 
							<input
								className="ViewItemInput" 
								id="taskName" 
								type="Name" 
								autoFocus={true}
								defaultValue={taskName}
								onChange={(event) => this.onTaskNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>

						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(TaskLang, "executor")}</legend> 
							<input 
								type="checkbox" 
								className="ViewItemCheckbox" 
								id="taskForSelf"
								name="myTaskYN" 
								checked={this.state.myTaskYN}
								onChange={(event) => this.onMyTaskYNCheckboxClick(event)}
							/>
							<label htmlFor="taskForSelf" className="ViewItemCheckboxLabel">{this.props.getLang(TaskLang, "createTaskForSelf")}</label>
							{(!this.state.myTaskYN) && (
							<div>
								<div style={{height: "5px"}}></div>
								<UserPicker
									className="ViewItemUser" 
									managementBaseURLAPI={this.props.managementBaseURLAPI}
									companyID={this.props.companyID}
									userID={this.state.executorUserID}
									userName={this.state.userFullName}
									onChange={this.onExecutorUserIDChanged}
								/>
								<div style={{height: "5px"}}></div>
							</div>)}
						</fieldset>
						<div style={{height: "20px"}}></div>						

						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">E-mail</legend> 
							{(!this.state.myTaskYN) && (
							<div>
								<div style={{height: "5px"}}></div>
								<input 
									type="checkbox" 
									className="ViewItemCheckbox" 
									id="sendEmailSelf"
									name="sendEmailSelf" 
									checked={this.state.sendEmailSelfYN}
									onChange={(event) => this.onSendEmailSelfYNCheckboxClick(event)}
								/>
								<label htmlFor="sendEmailSelf" className="ViewItemCheckboxLabel">{this.props.getLang(TaskLang, "sendEmailToExecutor")}</label>
								<div style={{height: "5px"}}></div>
							</div>)}
							<input 
								type="checkbox" 
								className="ViewItemCheckbox" 
								id="sendEmailExecutor"
								name="sendEmailExecutor" 
								checked={this.state.sendEmailExecutorYN}
								onChange={(event) => this.onSendEmailExecutorYNCheckboxClick(event)}
							/>
							<label htmlFor="sendEmailExecutor" className="ViewItemCheckboxLabel">{this.props.getLang(TaskLang, "sendEmailToSelf")}</label>
							<div style={{height: "5px"}}></div>
						</fieldset>
						<div style={{height: "20px"}}></div>						

						{this.renderPartProperty(partID, partName, propertyID, propertyName)}

						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(TaskLang, "notes")}</legend> 
							<textarea
								className="ViewItemInput" 
								id="notes" 
								defaultValue={this.state.notes}
								rows={5}
								onChange={(event) => this.onNotesChanged(event.target.value)}
						/>
						</fieldset>

					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(TaskLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(TaskLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function TaskNewFunction(props) {
	const navigate = useNavigate();
	return <TaskNew {...props} navigate={navigate} />;
}

