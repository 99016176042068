import React  from 'react';
import './App.css';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaPlay, FaPause } from "react-icons/fa"
import { MdDone } from "react-icons/md"
import { BsRecycle, BsTrash } from "react-icons/bs"
import { TbEdit } from "react-icons/tb"
import TaskLang from './TaskLang.json';

import TaskInfoEnterProperty from './TaskInfoEnterProperty.js';
import TaskInfoEnterNotes from './TaskInfoEnterNotes.js';
import TaskInfoEditSchedule from './TaskInfoEditSchedule.js';

import { useNavigate } from "react-router-dom";

class TaskInfo extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			taskScheduleJsonObj: null,
			taskCommentJsonObj: null,
			index: 0,
			count: 10,
			showTaskInfoEnterPropertyYN: false,
			showTaskInfoEnterNotesYN: false,
			showTaskInfoEditScheduleYN: false,
			taskScheduleID: 0,
		};

		this.serverGetTaskScheduleList = this.serverGetTaskScheduleList.bind(this);
		this.serverUpdateTaskStatus = this.serverUpdateTaskStatus.bind(this);		
		this.serverCalcTaskDurationSec = this.serverCalcTaskDurationSec.bind(this);		
		this.serverInsertTaskScheduleRecord = this.serverInsertTaskScheduleRecord.bind(this);		
		this.serverCalcTaskScheduleDurationSec = this.serverCalcTaskScheduleDurationSec.bind(this);
		this.serverDeleteTaskRecord = this.serverDeleteTaskRecord.bind(this);
		this.pauseTask = this.pauseTask.bind(this);
		this.deleteTask = this.deleteTask.bind(this);
		this.serverGetTaskCommentList = this.serverGetTaskCommentList.bind(this);
		this.handlePlayPauseTask = this.handlePlayPauseTask.bind(this);
		this.handleEndTask = this.handleEndTask.bind(this);
		this.handleRecycleTask = this.handleRecycleTask.bind(this);
		this.handleDeleteTask = this.handleDeleteTask.bind(this);
		this.handleSelectedIcon = this.handleSelectedIcon.bind(this);
		this.handlePartLink = this.handlePartLink.bind(this);		
		this.setShowTaskInfoEnterPropertyYN = this.setShowTaskInfoEnterPropertyYN.bind(this);
		this.setShowTaskInfoEnterNotesYN = this.setShowTaskInfoEnterNotesYN.bind(this);
		this.setShowTaskInfoEditScheduleYN = this.setShowTaskInfoEditScheduleYN.bind(this);
		this.renderIcons = this.renderIcons.bind(this);
		this.renderOneComment = this.renderOneComment.bind(this);
		this.renderPrevNext = this.renderPrevNext.bind(this);
		this.renderAllComments = this.renderAllComments.bind(this);
		this.renderOneSchedule = this.renderOneSchedule.bind(this);
		this.renderAllSchedules = this.renderAllSchedules.bind(this);
		this.handleEditSchedule = this.handleEditSchedule.bind(this);
	}

	componentDidMount() {
		this.serverGetTaskScheduleList(this.props.taskID);
		//this.serverGetTaskCommentList(this.props.taskID, this.state.index, this.state.count);
	}
	
	componentDidUpdate() {
		ReactTooltip.rebuild();
	}
	
	async serverGetTaskScheduleList(taskID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/gettaskschedulelist?taskid=" + taskID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		
		this.setState({
			taskScheduleJsonObj: jsonObj,
		});
	}
	
	async serverUpdateTaskStatus(taskID, status) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatetaskstatus?taskid=" + taskID;
		url = url + "&status=" + encodeURIComponent(status)
		
		const res = await fetch(url);
		const textReponse = await res.text();
		this.props.serverGetTaskData(taskID);
	}
	
	async serverCalcTaskDurationSec(taskID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/calctaskdurationsec?taskid=" + taskID;

		const res = await fetch(url);
		const textReponse = await res.text();
		
		this.props.serverGetTaskData(taskID);
	}
		
	async serverInsertTaskScheduleRecord(taskID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/inserttaskschedulerecord?taskid=" + taskID;
		
		const res = await fetch(url);
		const textReponse = await res.text();

		this.serverGetTaskScheduleList(taskID);
	}
	
	async serverCalcTaskScheduleDurationSec(taskScheduleID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/calctaskscheduledurationsec?taskscheduleid=" + taskScheduleID;
		
		const res = await fetch(url);
		const textReponse = await res.text();
		
		var taskID = this.props.taskID;
		this.serverCalcTaskDurationSec(taskID);
		this.serverGetTaskScheduleList(taskID);
	}
	
	async serverDeleteTaskRecord(taskID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletetaskrecord?taskid=" + taskID;
		
		const res = await fetch(url);
		const textReponse = await res.text();		

		this.props.navigate("/tasks");
	}
		
	pauseTask() {
		
		var taskID = this.props.taskID;
		
		var taskScheduleJsonObj = this.state.taskScheduleJsonObj;
		if (taskScheduleJsonObj === null) {
			return;
		}
		
		var scheduleCount = taskScheduleJsonObj.length;
		var itemObj = taskScheduleJsonObj[scheduleCount - 1];
		var taskScheduleID = itemObj.taskScheduleID;
		this.serverCalcTaskScheduleDurationSec(taskScheduleID);
	}

	deleteTask(taskID, taskName) {
		this.serverDeleteTaskRecord(taskID);
		this.props.showToast(this.props.getLang(TaskLang, "task") + " \"" + taskName + "\" " + this.props.getLang(TaskLang, "deleted"));
	}
		
	async serverGetTaskCommentList(taskID, index, count) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/gettaskcommentlist?taskid=" + taskID;
		url = url + "&sortby=taskCommentID&sortasc=0";
		url = url + "&index=" + index + "&count=" + this.state.count;

		const res = await fetch(url);
		const jsonObj = await res.json();
		
		this.setState({
			taskCommentJsonObj: jsonObj,
		});
	}
	
	handlePlayPauseTask() {

		var taskID = this.props.taskID;
		var status = this.props.status;
		var statusNew = "";
		
		if (status === "new") {
			statusNew = "started";
			this.serverInsertTaskScheduleRecord(taskID);
		}

		if (status === "paused") {
			statusNew = "started";
			this.serverInsertTaskScheduleRecord(taskID);
		}

		if (status === "started") {
			statusNew = "paused";
			this.pauseTask();
		}

		this.props.setStatus(statusNew);
		this.serverUpdateTaskStatus(taskID, statusNew);
	}
		
	handleEndTask() {
		var taskID = this.props.taskID;
		var partID = this.props.partID;
		this.pauseTask();
		var statusNew = "finished";
		this.props.setStatus(statusNew);
		this.serverUpdateTaskStatus(taskID, statusNew);
		this.props.showToast(this.props.getLang(TaskLang, "taskFinished"));
		if (partID > 0) {
			this.setState({showTaskInfoEnterPropertyYN: true});
		} else {
			this.setState({showTaskInfoEnterNotesYN: true});			
		}
	}
	
	handleRecycleTask() {
		var taskID = this.props.taskID;
		this.props.setRecycleTaskID(taskID);
		this.props.navigate("/tasks/new");
	}
	
	handleDeleteTask() {

		var taskID = this.props.taskID;
		var taskName = this.props.taskName;

		confirmAlert({
			title: this.props.getLang(TaskLang, "deleteTask"),
			message: this.props.getLang(TaskLang, "deleteTaskInfoText") + " \"" + taskName + "\" ?",
			buttons: [
				{
					label: this.props.getLang(TaskLang, "yes"),
					onClick: () => this.deleteTask(taskID, taskName)
				},
				{
					label: this.props.getLang(TaskLang, "no"),
				}
			]
		});
	}

	handleSelectedIcon(iconIndex) {
		
		if (iconIndex === 100) {
			this.handlePlayPauseTask();
		}
		
		if (iconIndex === 101) {
			this.handleEndTask();
		}		

		if (iconIndex === 200) {
			this.handleRecycleTask();
		}

		if (iconIndex === 201) {
			this.handleDeleteTask();
		}
	}

	handlePartLink(partID, partName) {
		this.props.setSelectedPartIDAndName(partID, partName);
		this.props.navigate("/parts");
	}
	
	setShowTaskInfoEnterPropertyYN(showTaskInfoEnterPropertyYN) {
		this.setState({
			showTaskInfoEnterPropertyYN: showTaskInfoEnterPropertyYN,
		});
	}
	
	setShowTaskInfoEnterNotesYN(showTaskInfoEnterNotesYN) {
		this.setState({
			showTaskInfoEnterNotesYN: showTaskInfoEnterNotesYN,
		});
	}
	
	setShowTaskInfoEditScheduleYN(showTaskInfoEditScheduleYN) {
		this.setState({
			showTaskInfoEditScheduleYN: showTaskInfoEditScheduleYN,
		});
	}
	
	renderIcons() {

		var status = this.props.status;
		var showPlayIconYN = false;
		var showPauseIconYN = false;
		var showFinishedIconYN = false;
		var dataTipPlayPause = "";
		
		if (status === "started") {
			showPauseIconYN = true;
			dataTipPlayPause = this.props.getLang(TaskLang, "pauseTask");
		} else {
			showPlayIconYN = true;
			dataTipPlayPause = this.props.getLang(TaskLang, "startTask");
			dataTipPlayPause = "";
		}

		if (status !== "new") {
			showFinishedIconYN = true;
		}

		var finishedYN = false;
		if (status === "finished") {
			finishedYN = true;
		}
		
		var productionAppUserType = this.props.productionAppUserType;
		var executorUserID = this.props.executorUserID;
		var createdUserID = this.props.createdUserID;
		var userID = this.props.userID;		
		var showEditYN = false;
		var showDeleteYN = false;
		
		if (productionAppUserType >= 100) {
			showEditYN = true;
			showDeleteYN = true;
		}
		
		if (userID === executorUserID) {
			showEditYN = true;
		}
		
		if (userID === createdUserID) {
			showEditYN = true;
		}
		
		if (finishedYN) {
			showEditYN = false;
		}
		
		return (
			<div>
				<div style={{height: "20px"}}></div>
				{showEditYN && (
					<div className="TaskInfoIconContainer">
						<div 
							className="TaskInfoLeftDiv"
							onClick={() => this.handleSelectedIcon(100)}
							data-tip={dataTipPlayPause}
						>
							{(showPlayIconYN) && (
								<FaPlay style={{ color: "black", width: "25px", height: "25px" }} />
							)}
							{(showPauseIconYN) && (
								<FaPause style={{ color: "black", width: "25px", height: "25px" }} />
							)}
						</div>
						{showFinishedIconYN && (<div 
							className="TaskInfoRightDiv"
							onClick={() => this.handleSelectedIcon(101)}
							data-tip={this.props.getLang(TaskLang, "finishTask")}
						>
							<MdDone style={{ color: "green", width: "25px", height: "25px" }} />
						</div>)}
					</div>
				)}
				{!showEditYN && (
					<div className="TaskInfoIconContainer">
						<div 
							className="TaskInfoLeftDiv"
							onClick={() => this.handleSelectedIcon(200)}
							data-tip={this.props.getLang(TaskLang, "recycleTask")}
						>
							<BsRecycle style={{ color: "green", width: "25px", height: "25px" }} />
						</div>
						{showDeleteYN && (
							<div 
								className="TaskInfoRightDiv"
								onClick={() => this.handleSelectedIcon(201)}
								data-tip={this.props.getLang(TaskLang, "deleteTask")}
							>
								<BsTrash style={{ color: "black", width: "25px", height: "25px" }} />
							</div>
						)}
					</div>					
				)}
			</div>
		);
	}
	
	renderOneComment(taskCommentID, commentText, userName, createdUnixTimeSec, editedUnixTimeSec) {
		
		var commentJSX = this.props.convertTextNewlineToBr(commentText, "comment" + taskCommentID, "TaskInfoItem");
		var dateTimeString = this.props.getDateTimeStringFromUnixTimeSec(createdUnixTimeSec);

		return (
			<div key={taskCommentID}>
				<hr/>
				<div className="TaskInfoCommentUsername">{userName}</div>
				<div className="TaskInfoCommentText">{commentJSX}</div>
				<div className="TaskInfoCommentDateTime">{dateTimeString}</div>
			</div>
		);
	}
	
	renderPrevNext(prevYN, nextYN) {
		
		if ((!prevYN) && (!nextYN)) {
			return null;
		}
		
		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
			</div>
		);
	}
		
	renderAllComments() {
		
		var commentCount = 0;
		var taskCommentJsonObj = this.state.taskCommentJsonObj;

		if (taskCommentJsonObj !== null) {
			commentCount = taskCommentJsonObj.length;
		}
		if (commentCount === 0) {
			return null;
		}

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (commentCount === this.state.count) {
			nextYN = true;
		}

		var i;
		var itemObj;
		var taskCommentID;
		var userName;
		var commentText;
		var createdUnixTimeSec;
		var editedUnixTimeSec;
		var jsxArray = [];

		for(i=0; i<taskCommentJsonObj.length; i++) {
			itemObj = taskCommentJsonObj[i];
			taskCommentID = itemObj.taskCommentID;
			commentText = itemObj.commentText;
			userName = itemObj.userName;
			createdUnixTimeSec = itemObj.createdUnixTimeSec;
			editedUnixTimeSec = itemObj.editedUnixTimeSec;
			jsxArray.push(this.renderOneComment(taskCommentID, commentText, userName, createdUnixTimeSec, editedUnixTimeSec));
		}

		return (
			<div>
				<div className="">{jsxArray}</div>
				<div style={{height:"30px"}}></div>
				{this.renderPrevNext(prevYN, nextYN)}
			</div>
		);
	}
	
	handleEditSchedule(taskScheduleID) {
		this.setState({
			showTaskInfoEditScheduleYN: true,
			taskScheduleID: taskScheduleID,
		});
	}
	
	renderOneSchedule(itemObj) {
		
		var userID = this.props.userID;
		var createdUserID = this.props.createdUserID;
		var executorUserID = this.props.executorUserID;
		var productionAppUserType = this.props.productionAppUserType;
		var showEditYN = false;
		
		if (createdUserID === userID) {
			showEditYN = true;
		}

		if (executorUserID === userID) {
			showEditYN = true;
		}
		
		if (productionAppUserType >= 100) {
			showEditYN = true;
		}

		var taskScheduleID = itemObj.taskScheduleID;
		var startUnixTimeSec = itemObj.startUnixTimeSec;
		var durationSec = itemObj.durationSec;
		var editedUserID = itemObj.editedUserID;
		var editedUserFullName = itemObj.editedUserFullName;
		var editedUnixTimeSec = itemObj.editedUnixTimeSec;

		var dateTimeString = this.props.getDateTimeStringFromUnixTimeSec(startUnixTimeSec);
		
		var durationString = "";
		if (durationSec === null) {
			durationString = "in progress..."
		} else {
			durationString = this.props.getTimeStringFromSeconds(durationSec);
		}

		if (durationSec === null) {
			showEditYN = false;
		}
		
		var dateTimeClassName = "TaskInfoScheduleDateTime";
		var durationClassName = "TaskInfoScheduleDuration";
		var dataTip = "";
		var editedDateTimeString = "";
		if (editedUserID > 0) {
			dateTimeClassName = "TaskInfoScheduleDateTimeEdited";
			durationClassName = "TaskInfoScheduleDurationEdited";
			editedDateTimeString = this.props.getDateTimeStringFromUnixTimeSec(editedUnixTimeSec);
			dataTip = this.props.getLang(TaskLang, "editedBy") + " " + editedUserFullName + " - " + editedDateTimeString;
		}
		//
				
		return (
			<div key={taskScheduleID} className="TaskInfoScheduleContainer">
				<div className={dateTimeClassName} data-tip={dataTip}>{dateTimeString}</div>
				<div className="TaskInfoScheduleSeparator">-</div>
				<div className={durationClassName}>{durationString}</div>
				{showEditYN && (
					<div 
						className="TaskInfoScheduleEditIcon"
						onClick={() => this.handleEditSchedule(taskScheduleID)}
						data-tip={this.props.getLang(TaskLang, "editSchedule")}
					>
						<TbEdit style={{ color: "blue", width: "18px", height: "18px" }} />
					</div>
				)}
			</div>
		);
	}
	
	renderAllSchedules() {
		
		var scheduleCount = 0;
		var taskScheduleJsonObj = this.state.taskScheduleJsonObj;

		if (taskScheduleJsonObj !== null) {
			scheduleCount = taskScheduleJsonObj.length;
		}
		if (scheduleCount === 0) {
			return null;
		}

		var i;
		var itemObj;
		var jsxArray = [];

		for(i=0; i<taskScheduleJsonObj.length; i++) {
			itemObj = taskScheduleJsonObj[i];
			jsxArray.push(this.renderOneSchedule(itemObj));
		}

		return (
			<div>
				<div className="TaskInfoScheduleTitle">{this.props.getLang(TaskLang, "schedule")}:</div>
				<div className="">{jsxArray}</div>
				<div style={{height:"30px"}}></div>
			</div>
		);
	}
	
	render() {		

		var taskName = this.props.taskName;
		var userFullName = this.props.userFullName;
		var createdUserFullName = this.props.createdUserFullName;
		var createdUnixTimeSec = this.props.createdUnixTimeSec;
		var status = this.props.status;
		var startedUnixTimeSec = this.props.startedUnixTimeSec;
		var endedUnixTimeSec = this.props.endedUnixTimeSec;
		var durationSec = this.props.durationSec;
		var notes = this.props.notes;
		var partID = this.props.partID;
		var partName = this.props.partName;
		var partPropertyName = this.props.partPropertyName;
		var partPropertyIntDiff = this.props.partPropertyIntDiff;
		
		var createdDateString = this.props.getDateTimeStringFromUnixTimeSec(createdUnixTimeSec);
		var startedDateString = this.props.getDateTimeStringFromUnixTimeSec(startedUnixTimeSec);
		var endedDateString = this.props.getDateTimeStringFromUnixTimeSec(endedUnixTimeSec);
		var notesJSX = this.props.convertTextNewlineToBr(notes, "notes", "TaskInfoItem");
		
		var durationString = this.props.getTimeStringFromSeconds(durationSec);
		
		var showPartPropertyYN = false;
		if (partID > 0) {
			showPartPropertyYN = true;
		}

		var showDurationYN = false;
		if (durationString !== "") {
			showDurationYN = true;
		}
		
		var showStartedYN = false;
		if (startedDateString !== "") {
			showStartedYN = true;
		}
		
		var showFinishedYN = false;
		if (endedDateString !== "") {
			showFinishedYN = true;
		}		
		
		var showTaskInfoEnterPropertyYN = this.state.showTaskInfoEnterPropertyYN;
		var showTaskInfoEnterNotesYN = this.state.showTaskInfoEnterNotesYN;
		var showTaskInfoEditScheduleYN = this.state.showTaskInfoEditScheduleYN;
		
		var taskScheduleID = this.state.taskScheduleID;
		
		var statusText = this.props.getLang(TaskLang, status);
		
		return (
			<div>
				<ReactTooltip
					type="info"
					delayShow={500}
				/>
				<TaskInfoEnterProperty
					renderPopupYN={showTaskInfoEnterPropertyYN}
					baseURLAPI={this.props.baseURLAPI}
					userID={this.props.userID}
					email={this.props.email}
					fullName={this.props.fullName}
					projectID={this.props.projectID}
					taskID={this.props.taskID}
					taskName={this.props.taskName}
					partID={this.props.partID}
					partPropertyID={this.props.partPropertyID}
					showToast={this.props.showToast}
					setShowTaskInfoEnterPropertyYN={this.setShowTaskInfoEnterPropertyYN}
					serverGetTaskData={this.props.serverGetTaskData}
					getLang={this.props.getLang}
				/>
				<TaskInfoEnterNotes
					renderPopupYN={showTaskInfoEnterNotesYN}
					baseURLAPI={this.props.baseURLAPI}
					userID={this.props.userID}
					fullName={this.props.fullName}
					projectID={this.props.projectID}
					taskID={this.props.taskID}
					taskName={this.props.taskName}
					notes={this.props.notes}
					showToast={this.props.showToast}
					setShowTaskInfoEnterNotesYN={this.setShowTaskInfoEnterNotesYN}
					serverGetTaskData={this.props.serverGetTaskData}
					getLang={this.props.getLang}
				/>
				<TaskInfoEditSchedule
					renderPopupYN={showTaskInfoEditScheduleYN}
					baseURLAPI={this.props.baseURLAPI}
					userID={this.props.userID}
					fullName={this.props.fullName}
					projectID={this.props.projectID}
					taskID={this.props.taskID}
					taskName={this.props.taskName}
					taskScheduleID={this.state.taskScheduleID}
					showToast={this.props.showToast}
					setShowTaskInfoEditScheduleYN={this.setShowTaskInfoEditScheduleYN}
					serverCalcTaskDurationSec={this.serverCalcTaskDurationSec}
					serverGetTaskScheduleList={this.serverGetTaskScheduleList}
					getLang={this.props.getLang}
				/>
				<p><b className="TaskInfoTitle">{taskName}</b></p>
				<p className="TaskInfoItem">{this.props.getLang(TaskLang, "executor")}: {userFullName}</p>
				<p className="TaskInfoItem">{this.props.getLang(TaskLang, "createdBy")}: {createdUserFullName}, {createdDateString}</p>
				
				{showPartPropertyYN && (
					<p className="TaskInfoLink" onClick={() => this.handlePartLink(partID, partName)}>
						{this.props.getLang(TaskLang, "partProperty")}: {partName}, {partPropertyName} - diff: {partPropertyIntDiff}
					</p>
				)}
				
				<p className="TaskInfoItem">{this.props.getLang(TaskLang, "status")}: {statusText}</p>
				{showDurationYN && (<p className="TaskInfoItem">{this.props.getLang(TaskLang, "duration")}: {durationString}</p>)}
				{showStartedYN && (<p className="TaskInfoItem">{this.props.getLang(TaskLang, "started")}: {startedDateString}</p>)}
				{showFinishedYN && (<p className="TaskInfoItem">{this.props.getLang(TaskLang, "finished")}: {endedDateString}</p>)}

				<div style={{height: "10px"}}></div>
				<div className="TaskInfoItem">{notesJSX}</div>
				{this.renderIcons()}
				<div style={{height: "30px"}}></div>
				{this.renderAllSchedules()}
				{this.renderAllComments()}
			</div>
		);
	}
}

export default function TaskInfoFunction(props) {
	const navigate = useNavigate();
	return <TaskInfo {...props} navigate={navigate} />;
}

