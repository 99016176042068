import React  from 'react';
import './App.css';
import './Milestone.css';
import ReactTooltip from 'react-tooltip';
import MilestoneLang from './MilestoneLang.json';

import { useNavigate } from "react-router-dom";

var milestoneTitleArray = ["Daska", "Baterija", "Kolumba", "Daljinski", "Ambalaza"];

class MilestoneMonthlyMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			milestoneID: 0,
			year: 0,
			milestoneRowListJsonObj: null,
			rowDataJsonObjArray: null,
		}

		this.serverGetMilestoneRowList = this.serverGetMilestoneRowList.bind(this);
		this.serverGetMilestoneItemDataForYear = this.serverGetMilestoneItemDataForYear.bind(this);		
		this.getDataForCell = this.getDataForCell.bind(this);
		this.handleCellClick = this.handleCellClick.bind(this);
		this.renderOneHeaderCell = this.renderOneHeaderCell.bind(this);
		this.renderHeader = this.renderHeader.bind(this);
		this.renderOneDataCell = this.renderOneDataCell.bind(this);
		this.renderOneDataRow = this.renderOneDataRow.bind(this);
		this.renderAllRows = this.renderAllRows.bind(this);
		this.convertTextNewlineToBrForTooltip = this.convertTextNewlineToBrForTooltip.bind(this);		
		this.renderSailfinTarget = this.renderSailfinTarget.bind(this);				
	}

	componentDidMount() {
		var milestoneID = 1;
		var dateObj = new Date();
		var year = dateObj.getFullYear();
		this.setState({
			milestoneID: milestoneID,
			year: year,
		});
		this.serverGetMilestoneRowList(milestoneID, year);
	}
	
	async serverGetMilestoneRowList(milestoneID, year) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getmilestonerowlist?milestoneid=" + milestoneID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			milestoneRowListJsonObj: jsonObj,
		});
		
		var i;
		var itemObj;
		var milestoneRowID;
		var rowDataJsonObj;
		var itemObj2;
		var rowDataJsonObjArray = [];

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			milestoneRowID = itemObj.milestoneRowID;
			rowDataJsonObj = await this.serverGetMilestoneItemDataForYear(milestoneRowID, year);
			itemObj2 = {};
			itemObj2.milestoneRowID = milestoneRowID;
			itemObj2.rowDataJsonObj = rowDataJsonObj;
			rowDataJsonObjArray.push(itemObj2);
		}

		this.setState({
			rowDataJsonObjArray: rowDataJsonObjArray,
		});		
	}
	
	async serverGetMilestoneItemDataForYear(milestoneRowID, year) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getmilestoneitemdataforyear?milestonerowid=" + milestoneRowID;
		url = url + "&year=" + year;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		return jsonObj;
	}
	
	getDataForCell(milestoneRowID, month) {

		var rowDataJsonObjArray = this.state.rowDataJsonObjArray;
		if (rowDataJsonObjArray === null) {
			return null;
		}

		var i;
		var itemObj;
		var milestoneRowID2;
		var rowDataJsonObj = null;
		var foundYN = false;

		for(i=0; (i<rowDataJsonObjArray.length) && (!foundYN); i++) {
			itemObj = rowDataJsonObjArray[i];
			milestoneRowID2 = itemObj.milestoneRowID;
			if (milestoneRowID === milestoneRowID2) {
				rowDataJsonObj = itemObj.rowDataJsonObj;
				foundYN = true;
			}
		}
		
		if (rowDataJsonObj === null) {
			return null;
		}
		
		var month2;

		for(i=0; i<rowDataJsonObj.length; i++) {
			itemObj = rowDataJsonObj[i];
			month2 = itemObj.month;
			if (month === month2) {
				return itemObj;
			}			
		}
		
		return null;
	}
	
	handleCellClick(milestoneRowID, rowName, rowIndex, month, ownerUserID) {

		var productionAppUserType = this.props.productionAppUserType;
		var userID = this.props.userID;
		var milestoneID = this.state.milestoneID;
		var editAllowedYN = false;

		if (productionAppUserType >= 100) {
			editAllowedYN = true;
		}
		
		if (userID === ownerUserID) {
			editAllowedYN = true;
		}
		
		if (!editAllowedYN) {
			return;
		}
		
		var year = this.state.year;
		this.props.setMilestoneData(milestoneID, milestoneRowID, rowName, year, month);
		this.props.navigate("/milestones/edit");
	}
	
	renderOneHeaderCell(month) {
		
		var monthString = this.props.getMonthStringShort(month); //getMonthString
		var key = month;

		return (
			<td key={key} className="MilestoneMonthlyMainHeader">{monthString}</td>
		);
	}
	
	renderHeader() {
		
		var i;
		var jsxItem;
		var jsxArray = [];

		for(i=1; i<=12; i++) {
			jsxItem = this.renderOneHeaderCell(i);
			jsxArray.push(jsxItem);
		}

		return (
			<tr>
				<td className="MilestoneMonthlyMainMilestoneHeader"></td>
				{jsxArray}
			</tr>
		);

	}
	
	convertTextNewlineToBrForTooltip(inputText) {
		
		if (inputText === null) {
			inputText = "";
		}
		
		var lineArray = this.props.separateTextIntoArrayOfLines(inputText);
		
		var i;
		var len = lineArray.length;
		var oneLineText;
		var outputText = "";
		
		for(i=0; i<len; i++) {
			oneLineText = lineArray[i];
			outputText = outputText + oneLineText + "<br/>";
		}
		
		return outputText;
	}

	renderOneDataCell(milestoneRowID, rowName, rowIndex, month, ownerUserID, ownerFullname) {

		var intValue = 0;
		var notes = "";
		var editedFullName = "";
		var editedUnixTimeSec = null;
		var tipTextHtml = "";
		var dateTimeString = "";

		var itemObj = this.getDataForCell(milestoneRowID, month);

		if (itemObj === null) {
			
			tipTextHtml = "<div class='MilestoneMonthlyMainTooltip'>";
			tipTextHtml = tipTextHtml + this.props.getLang(MilestoneLang, "owner") + ": <b>" + ownerFullname + "</b>";
			tipTextHtml = tipTextHtml + "</div>";
				
		} else {
			
			intValue = itemObj.intValue;			
			notes = itemObj.notes;
			editedFullName = itemObj.editedFullName;
			editedUnixTimeSec = itemObj.editedUnixTimeSec;
			notes = this.convertTextNewlineToBrForTooltip(notes);
			
			dateTimeString = this.props.getDateTimeStringFromUnixTimeSec(editedUnixTimeSec);
			
			tipTextHtml = "<div class='MilestoneMonthlyMainTooltip'>";
			tipTextHtml = tipTextHtml + "<b>" + editedFullName + ":</b><br/><br/>";
			tipTextHtml = tipTextHtml + notes + "<br/><br/>";
			tipTextHtml = tipTextHtml + dateTimeString + "<br/>";
			tipTextHtml = tipTextHtml + "</div>";
		}

		var cellString = intValue.toString();
		var key = rowIndex + "-" + month;

		return (
			<td 
				key={key}
				className="MilestoneMonthlyMainCell"
				onClick={() => this.handleCellClick(milestoneRowID, rowName, rowIndex, month, ownerUserID)}
				data-tip={tipTextHtml} 
			>
				{cellString}
			</td>
		);
	}
	
	renderOneDataRow(rowIndex) {

		var i;
		var jsxItem;
		var jsxArray = [];

		var milestoneRowListJsonObj = this.state.milestoneRowListJsonObj;
		var itemObj = milestoneRowListJsonObj[rowIndex];
		var milestoneRowID = itemObj.milestoneRowID;
		var rowName = itemObj.rowName;
		var ownerUserID = itemObj.ownerUserID;

		var ownerFullName = this.props.getFullNameForUserID(ownerUserID);

		for(i=1; i<=12; i++) {
			jsxItem = this.renderOneDataCell(milestoneRowID, rowName, rowIndex, i, ownerUserID, ownerFullName);
			jsxArray.push(jsxItem);
		}

		return (
			<tr key={rowIndex}>
				<td className="MilestoneMonthlyMainMilestoneItem">{rowName}</td>
				{jsxArray}
			</tr>
		);
	}

	renderAllRows() {
		
		var milestoneRowListJsonObj = this.state.milestoneRowListJsonObj;

		var i;
		var jsxItem;
		var jsxArray = [];

		var length = milestoneRowListJsonObj.length;

		for(i=0; i<length; i++) {
			jsxItem = this.renderOneDataRow(i);
			jsxArray.push(jsxItem);
		}

		return jsxArray;
	}
	
	renderSailfinTarget() {
		return (
			<div><b>{this.props.getLang(MilestoneLang, "monthlyTarget")}:</b> TBD</div>
		);
	}
	
	render() {
		
		var year = this.state.year;
		
		var milestoneRowListJsonObj = this.state.milestoneRowListJsonObj;
		if (milestoneRowListJsonObj === null) {
			return null;
		}

		return (
			<div className="MilestoneMonthlyMainContainer1">
				<ReactTooltip
					type="info"
					//multiline={true}
					html={true}
					delayShow={50}
				/>
				<div style={{height: "20px"}}></div>
				<div className="MilestoneMonthlyMainTitle">{this.props.getLang(MilestoneLang, "monthlyMilestones")} {year}</div>
				<div style={{height: "10px"}}></div>
				<p></p>
				<table>
					<tbody>
						{this.renderHeader()}
						{this.renderAllRows()}
					</tbody>
				</table>
				<div style={{height: "100px"}}></div>
				{this.renderSailfinTarget()}
			</div>
		);
	}
}

export default function MilestoneMonthlyMainFunction(props) {
	const navigate = useNavigate();
	return <MilestoneMonthlyMain {...props} navigate={navigate} />;
}

