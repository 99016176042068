import React  from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './TaskInfoEnterProperty.css';
import TaskLang from './TaskLang.json';

class TaskInfoEnterProperty extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			partName: "",
			count: 0,
			propertyName: "",
			propertyType: "",
			propertyValue: 0,
			value: 0,
		};

		this.serverGetPartData = this.serverGetPartData.bind(this);
		this.serverGetPartPropertyData = this.serverGetPartPropertyData.bind(this);
		this.serverUpdatePartCount = this.serverUpdatePartCount.bind(this);
		this.serverUpdatePartPropertyValue = this.serverUpdatePartPropertyValue.bind(this);
		this.serverRunPartPropertyJsCode = this.serverRunPartPropertyJsCode.bind(this);
		this.serverUpdateTaskPartPropertyIntDiff = this.serverUpdateTaskPartPropertyIntDiff.bind(this);
		this.serverInsertLogRecord = this.serverInsertLogRecord.bind(this);
		this.popUpClosed = this.popUpClosed.bind(this);
		this.onValueChanged = this.onValueChanged.bind(this);
		this.onSaveButton = this.onSaveButton.bind(this);
		this.renderPopup = this.renderPopup.bind(this);
	}
	
	componentDidMount() {
		var partID = this.props.partID;
		var partPropertyID = this.props.partPropertyID;
		this.serverGetPartData(partID);
		this.serverGetPartPropertyData(partPropertyID);
	}

	async serverGetPartData(partID) {
		
		if (partID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartdata?partid=" + partID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var partName = jsonObj.partName;
		var count = jsonObj.count;

		this.setState({
			partName: partName,
			count: count,
		});
	}
			
	async serverGetPartPropertyData(partPropertyID) {

		if (partPropertyID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpartpropertydata?partpropertyid=" + partPropertyID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var propertyName = jsonObj.propertyName;
		var propertyType = jsonObj.propertyType;
		var propertyValue = 0;
		
		if (propertyType === "int") {
			propertyValue = jsonObj.intValue;
		}
		if (propertyType === "float") {
			propertyValue = jsonObj.floatValue;
		}
		if (propertyType === "text") {
			propertyValue = jsonObj.textValue;
		}

		this.setState({
			propertyName: propertyName,
			propertyType: propertyType,
			propertyValue: propertyValue,
		});
		
	}
		
	async serverUpdatePartCount(partID, count, diff) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartcount?partid=" + partID + "&count=" + count;

		const res = await fetch(url);
		await res.text();

		this.serverRunPartPropertyJsCode(partID, 0, diff);
	}

	async serverUpdatePartPropertyValue(partID, partPropertyID, propertyType, propertyValue, diff) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatepartpropertyvalue?partpropertyid=" + partPropertyID;
		url = url + "&propertytype=" + propertyType;
		url = url + "&propertyvalue=" + encodeURIComponent(propertyValue);		

		const res = await fetch(url);
		await res.text();

		this.serverRunPartPropertyJsCode(partID, partPropertyID, diff);
	}
	
	async serverRunPartPropertyJsCode(partID, partPropertyID, diff) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/runpartpropertyjscode?partid=" + partID;
		url = url + "&partpropertyid=" + partPropertyID;
		url = url + "&diff=" + diff;
		
		const res = await fetch(url);
		await res.text();

		this.serverGetPartPropertyList(partID);
	}
	
	async serverUpdateTaskPartPropertyIntDiff(taskID, partPropertyIntDiff) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatetaskpartpropertyintdiff?taskid=" + taskID;
		url = url + "&partpropertyintdiff=" + partPropertyIntDiff;
		
		const res = await fetch(url);
		await res.text();
		this.props.serverGetTaskData(taskID);
	}
	
	async serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, projectID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertlogrecord?userid=" + userID;
		url = url + "&email=" + encodeURIComponent(email);
		url = url + "&fullname=" + encodeURIComponent(fullName);
		url = url + "&partid=" + partID;
		url = url + "&partname=" + encodeURIComponent(partName);
		url = url + "&propertyid=" + propertyID;
		url = url + "&propertyname=" + encodeURIComponent(propertyName);
		url = url + "&valueold=" + encodeURIComponent(valueOld);
		url = url + "&valuenew=" + encodeURIComponent(valueNew);
		url = url + "&projectid=" + projectID;

		const res = await fetch(url);
		await res.text();
	}
		
	popUpClosed() {
		this.props.setShowTaskInfoEnterPropertyYN(false);
	}
	
	onValueChanged(value) {
		this.setState({value: value});
	}
	
	onSaveButton() {

		var value = parseInt(this.state.value);

		var taskID = this.props.taskID;
		var userID = this.props.userID;
		var email = this.props.email;
		var fullName = this.props.fullName;
		var partID = this.props.partID;
		var partName = this.state.partName;
		var count = this.state.count;
		var propertyID = this.props.partPropertyID;
		var propertyName = this.state.propertyName;
		var propertyType = this.state.propertyType;
		var propertyValue = this.state.propertyValue;
		
		var valueOld = "";
		var valueNew = 0;
		if (propertyID === 0) {
			valueOld = count;
			propertyName = this.props.getLang(TaskLang, "count");
			valueNew = parseInt(valueOld) + value;
			this.serverUpdatePartCount(partID, valueNew, value);
		} else {
			valueOld = propertyValue;
			valueNew = parseInt(valueOld) + value;
			this.serverUpdatePartPropertyValue(partID, propertyID, propertyType, valueNew, value);
		}
		
		var partPropertyIntDiff = value;
		
		var projectID = this.props.projectID;

		this.serverInsertLogRecord(userID, email, fullName, partID, partName, propertyID, propertyName, valueOld, valueNew, projectID);
		this.serverUpdateTaskPartPropertyIntDiff(taskID, partPropertyIntDiff);
		this.props.setShowTaskInfoEnterPropertyYN(false);
	}
	
	renderPopup() {
		
		var taskName = this.props.taskName;
		var propertyID = this.props.partPropertyID;
		var partName = this.state.partName;
		var propertyName = this.state.propertyName;
		var propertyType = this.state.propertyType;
		var propertyValue = this.state.propertyValue;
		
		if (propertyID === 0) {
			propertyName = this.props.getLang(TaskLang, "count");
			propertyType = "int";
			propertyValue = this.state.count;
		}

		var inputType = "text";
		if (propertyType === "int") {
			inputType = "number";
		}
		if (propertyType === "float") {
			inputType = "number";
		}

		return (
			<Popup
				className="enter-property-popup"
				open={true}
				closeOnDocumentClick={true}
				onClose={this.popUpClosed}
			>
				<div className="TaskInfoEnterPropertyTopContainer">
					<div className="TaskInfoEnterPropertyHeaderContainer">
						<div className="TaskInfoEnterPropertyHeaderText">{taskName}</div>
					</div>
					<div style={{height: "20px"}}></div>
					<div className="TaskInfoEnterPropertyPartName">{partName}</div>
					<div style={{height: "20px"}}></div>
					<div className="TaskInfoEnterPropertyContainer">
						<div className="TaskInfoEnterPropertyText">{propertyName}:</div>
						<input
							className="TaskInfoEnterPropertyInput" 
							id="property" 
							type={inputType} 
							defaultValue={0}
							onChange={(event) => this.onValueChanged(event.target.value)}
						/>
					</div>
					
					<div style={{height: "60px"}}></div>
					<div className="TaskInfoEnterPropertyContainerButtons">
						<button className="ViewSaveButton" onClick={this.onSaveButton}>{this.props.getLang(TaskLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>
			</Popup>
		);
	}

	render() {
		
		var renderPopupYN = this.props.renderPopupYN;
		
		if (!renderPopupYN) {
			return null;
		}
		
		return (
			<div className={this.props.className}>
				{this.renderPopup()}
			</div>
		);
	}
}

export default TaskInfoEnterProperty;

